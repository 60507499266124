import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd'

import Toon from '../../components/toon';
import DatacronIcon from './icon';
import { ItemTypes } from './ItemTypes.js';
import classNames from 'classnames';
import DatacronStat from './stat.js';

const DatacronItem = (props) => {
  const ref = useRef(null)
  const [showTip, toggleShowTip] = useState(false)
  const [tipArrow, setTipArrow] = useState(3)
  const [tipText, setTipText] = useState('')
  const { unit, l3, l6, l9, index, moveItem, id, onHide, onComplete, hide, complete, activeTab, stats } = props

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveItem(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: { id, index },  
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    })
  })

  drag(drop(ref))

  const onToggleTip = (text, toggle, level) => {
    toggleShowTip(toggle)
    setTipText(text)
    setTipArrow(level)
  }

  return (
    <div className={classNames('sw-dc-item', isDragging && 'sw-dc-item--active', hide && 'sw-dc-item--hide', complete && 'sw-dc-item--complete')}>
      <div className='row'>
        <div className='col col-lg-auto'>
          <div className='d-flex' ref={ref} data-handler-id={handlerId}>
            <div className='sw-dc-item__drag'>
              <svg width='24' height='24' fill='currentColor' viewBox='0 0 16 16'>
                <path d='M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0' />
              </svg>
            </div>
            <div className='sw-dc-item__unit'>
              <Toon image={unit} />
              <div className='sw-dc-item__stats'>
                <DatacronStat value={stats[0]} onToggleTip={(text, toggle) => onToggleTip(text, toggle, 3)} />
                <DatacronStat value={stats[1]} onToggleTip={(text, toggle) => onToggleTip(text, toggle, 3)} />
              </div>
            </div>
          </div>
        </div>
        <div className='col-4 col-md-auto pl-0 order-md-6'>
          <div className='sw-dc-item__check'>
            <button className={classNames('btn btn-dark px-2 py-1', 'btn-info')} onClick={() => onHide(id)}>
              <svg width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
                <path d='m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z' />
                <path d='M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z' />
              </svg>
            </button>
          </div>
        </div>
        <div className='col-4 col-md pr-0'>
          <div className='sw-dc-item__bonus sw-dc-item--l3'>
            {l3 && <DatacronIcon onClick={() => onComplete(id, 'l3')} id={l3.id} complete={l3.complete} level={3} important={l3.important} activeTab={activeTab} onToggleTip={(text, toggle) => onToggleTip(text, toggle, 3)} />}
          </div>
        </div>
        <div className='col-4 col-md px-2'>
          <div className='sw-dc-item__bonus sw-dc-item--l6'>
            {l6 && <DatacronIcon onClick={() => onComplete(id, 'l6')} id={l6.id} complete={l6.complete} level={6} important={l6.important} activeTab={activeTab} onToggleTip={(text, toggle) => onToggleTip(text, toggle, 6)} />}
          </div>
        </div>
        <div className='col-4 col-md pl-0'>
          <div className='sw-dc-item__bonus sw-dc-item--l9'>
            {l9 && <DatacronIcon onClick={() => onComplete(id, 'l9')} id={l9.id} complete={l9.complete} level={9} important={l9.important} activeTab={activeTab} onToggleTip={(text, toggle) => onToggleTip(text, toggle, 9)} />}
          </div>
        </div>
      </div>
      {showTip &&
        <div className={classNames('sw-dc-item__tooltip tooltip fade bs-tooltip-bottom show', `sw-dc-item__tooltip--${tipArrow}`)}>
          <div className={classNames('arrow', `sw-dc-item__arrow sw-dc-item__arrow--${tipArrow}`)} />
          <div className='tooltip-inner'>{tipText}</div>
        </div>
      }
    </div>
  );
}

export default DatacronItem;