const sector0 = [
  {
    title: 'Challenge Path',
    text: 'Defeat 250 enemies on the golden Challenge Path',
    keycards: 15,
    teams: [
      {
        toons: [
          { image: 'cup', name: 'Corruscant Underworld Police' }
        ]
      },
    ]
  },
  {
    title: 'Captain Rex',
    text: 'Win 15 Battles with Captain Rex surviving',
    keycards: 15,
    teams: [
      {
        videoThumb: '',
        toons: [
          { image: 'dash', name: 'Dash'  },
          { image: 'captain-rex', name: 'Captain Rex', main: true },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
          { image: 'jedi-cal', name: 'Jedi Cal' },
        ]
      },
      {
        videoThumb: '',
        toons: [
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx'  },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals'  },
          { image: 'captain-rex', name: 'Captain Rex', main: true },
        ]
      },
      {
        videoThumb: '',
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'jedi-cal', name: 'Jedi Cal' },
          { image: 'captain-rex', name: 'Captain Rex', main: true },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
        ]
      },
    ]
  },
  {
    title: 'Jedi Knight Cal',
    text: 'Defeat 35 enemies with Jedi Knight Cal',
    keycards: 15,
    teams: [      
      {
        videoThumb: 'CgX6JpCIIdQ?t=391&si=Cfic_Id6GcZVjEWr',
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'jedi-cal', name: 'Jedi Cal', main: true },
          { image: 'shaak-ti', name: 'Shaak Ti' },
          { image: 'jolee', name: 'Jolee' },
          { image: 'gmy', name: 'Master Yoda'},
        ]
      },
      {
        videoThumb: 'CgX6JpCIIdQ?t=354&si=sSoUG2KuNXFFkABa',
        toons: [
          { image: 'jkl', name: 'Jedi Luke'},
          { image: 'jedi-cal', name: 'Jedi Cal', main: true },
          { image: 'gideon', name: 'Gideon' },
          { image: 'bb-echo', name: 'BB Echo' },
        ]
      },
    ]
  },
  {
    title: 'Master & Padawan',
    text: 'Win 15 battles with Master QGJ and Padawan Obi-Wan surviving',
    keycards: 15,
    teams: [
      {
        videoThumb: '',
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'jedi-cal', name: 'Jedi Cal' },
          { image: 'master-qgj', name: 'Master QGJ', main: true },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan', main: true },
          { image: 'captain-rex', name: 'Captain Rex'},
        ]
      },
      {
        videoThumb: '',
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'jedi-cal', name: 'Jedi Cal' },
          { image: 'master-qgj', name: 'Master QGJ', main: true },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan', main: true },
          { image: 'captain-rex', name: 'Captain Rex'},
        ]
      },
      {
        videoThumb: '',
        toons: [
          { image: 'jmk', name: 'Master Kenobi' },
          { image: 'cat', name: 'CAT' },
          { image: 'master-qgj', name: 'Master QGJ', main: true },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan', main: true },
          { image: 'gk', name: 'General Kenobu'},
        ]
      },
      {
        videoThumb: 'skxEXfuDRPk?t=484&si=5FJFkd1B_VorC3yF',
        toons: [
          { image: 'jkl', name: 'Jedi Luke'  },
          { image: 'jolee', name: 'Jolee' },
          { image: 'master-qgj', name: 'Master QGJ', main: true },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan', main: true },
          { image: 'shaak-ti', name: 'Shaak Ti' },
        ]
      },
      {
        videoThumb: '',
        toons: [
          { image: 'dash', name: 'Dash'  },
          { image: 'captain-rex', name: 'Captain Rex' },
          { image: 'master-qgj', name: 'Master QGJ', main: true },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan', main: true },
          { image: 'jedi-cal', name: 'Jedi Cal' },
        ]
      },
    ]
  },
  {
    title: 'Gungans',
    text: 'Win 15 battles with at least 4 Gungans in your squad',
    keycards: 1,
    dataDisk: 'Overprepared I',
    teams: [
      {
        videoThumb: 'skxEXfuDRPk?t=557&si=gaaMdR2WMOwzMokn',
        toons: [
          { image: 'dash', name: 'Dash' },
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx' },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
      {
        videoThumb: 'WO73F8CRelM?t=394&si=ZBidmofNIQ4lTYok',
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx' },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
      {
        videoThumb: '',
        toons: [
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx' },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
          { image: 'jar-jar', name: 'Jar Jar' },
        ]
      },
    ]
  },
  {
    title: 'Dark Gideon',
    text: 'Defeat 35 enemies with Dark Trooper Moff Gideon',
    keycards: 1,
    dataDisk: 'Booming Voice',
    teams: [
      {
        videoThumb: 'CgX6JpCIIdQ?t=327&si=nIbqYnY9_Vb6QbGP',
        toons: [
          { image: 'jkl', name: 'Jedi Luke'},
          { image: 'dt-gideon', name: 'Dark Gideon', main: true },
          { image: 'gideon', name: 'Gideon' },
          { image: 'bb-echo', name: 'BB Echo' },
        ]
      },
      {
        videoThumb: 'CgX6JpCIIdQ?t=476&si=jiA6gd3hma_b_akX',
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon', main: true },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'stormtrooper', name: 'Stormtrooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'gideon', name: 'Gideon' }
        ]
      }
    ]
  },
  {
    title: `Overprepared I`,
    text: 'Complete a battle with Overprepared I active',
    keycards: 15,
    teams: [
      {
        toons: [
          { image: 'cup', name: 'Corruscant Underworld Police' }
        ]
      },
    ]
  },
  {
    title: 'Booming Voice',
    text: 'Attempt to call allies to assist with Booming Voice 60 times',
    keycards: 15,
    teams: [
      {
        toons: [
          { image: 'cup', name: 'Corruscant Underworld Police' }
        ]
      },
    ]
  }
];

export {
  sector0
}