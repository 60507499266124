const day3 = [
  {
    title: `Sector 2 - Battles`,
    teams: [
      {
        id: 302,
        name: '2x',
        videoThumb: '-9XDYTjLfq4?t=71&si=dE6hBZ9ndUHmusiv',
        feats: [
          { name: 'Rebel Fighters', qty: 5 },
        ],
        toons: [
          { image: 'mothma', name: 'Mothma' },
          { image: 'kyle', name: 'Kyle' },
          { image: 'biggs', name: 'Biggs' },
          { image: 'pao', name: 'Pao'},
          { image: 'hoth-scout', name: 'Hoth Scout' }
        ]
      },
      {
        id: 303,
        name: '2x',
        videoThumb: '-9XDYTjLfq4?t=174&si=zgeYJp4jEM-repfw',
        feats: [
          { name: 'Rebel Fighters', qty: 5 },
        ],
        global: [
          { name: 'Captain Rex' },
          { name: 'Master & Padawan' },
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'wat', name: 'Wat' },
          { image: 'captain-rex', name: 'Captain Rex', main: true },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
        ]
      },
      {
        id: 304,
        name: '2x',
        videoThumb: '-9XDYTjLfq4?si=lO1rXhWNpM23QngU&t=220',
        feats: [
          { name: 'Imperial Troopers', qty: 5 },
        ],
        toons: [
          { image: 'veers', name: 'Veers' },
          { image: 'gideon', name: 'Gideon' },
          { image: 'starck', name: 'Starck' },
          { image: 'piett', name: 'Piett' },
          { image: 'range', name: 'Range Trooper' },
        ]
      },
    ]
  },
  {
    title: `Sector 2 - Final Boss`,
    boss: 'bossk',
    teams: [
      {
        id: 305,
        name: 'Saw',
        videoThumb: 'otpaqFu5xSo?t=425&si=LLbZme1IL9m65xwJ',
        feats: [
          { name: 'Rebel Fighters', qty: 5 },
        ],
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [          
          { image: 'mothma', name: 'Mothma' },
          { image: 'saw', name: 'Saw', main: true },
          { image: 'biggs', name: 'Biggs' },
          { image: 'pao', name: 'Pao'},
          { image: 'captain-rex', name: 'Captain Rex' }
        ]
      },
      {
        id: 306,
        name: 'Empire',
        videoThumb: 'otpaqFu5xSo?t=515&si=0ug4ZDOHpf8VBYDN',
        feats: [
          { name: 'Imperial Troopers', qty: 5 },
        ],
        toons: [
          { image: 'veers', name: 'Veers' },
          { image: 'gideon', name: 'Gideon' },
          { image: 'starck', name: 'Starck' },
          { image: 'piett', name: 'Piett' },
          { image: 'range', name: 'Range Trooper' },
        ]
      }
    ]
  },
  {
    title: 'Sector 2 - Progress',
    progress: [
      { name: 'Potency Down', complete: true },
      { name: 'Rebel Fighters', current: 35, target: 35 },
      { name: 'Imperial Troopers', current: 20, target: 35 },
      { name: 'Nightsisters', current: 4, target: 10 },
    ]
  },


  
  {
    title: `Sector 3 - Battles`,
    teams: [
      {
        id: 307,
        name: '2x',
        videoThumb: 'HAzLlLIAVUM?t=252&si=2N9mSNIKYamM3Jph',
        feats: [
          { name: 'Han', qty: 5 },
        ],
        global: [
          { name: 'Master & Padawan' },
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'capt-han', name: 'Captain Han', main: true  },
          { image: 'wat', name: 'Wat' },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
        ]
      },
      {
        id: 308,
        name: '2x',
        videoThumb: 'HAzLlLIAVUM?t=51&si=oSiPct3UBoXvfa1P',
        feats: [
          { name: 'Separatists', qty: 1 },
        ],
        toons: [
          { image: 'gg', name: 'Grievous' },
          { image: 'b1', name: 'B1' },
          { image: 'b2', name: 'B2' },
          { image: 'magna', name: 'Magna' },
          { image: 'stap', name: 'STAP' }
        ]
      },
      {
        id: 309,
        name: '2x',
        videoThumb: 'HAzLlLIAVUM?t=365&si=XEt7KCaagaCqGb_Q',
        feats: [
          { name: 'Adapt or Die', qty: 5 },
        ],
        toons: [
          { image: 'iden', name: 'Iden', main: true },
          { image: 'starck', name: 'Starck' },
          { image: 'range', name: 'Range Trooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'shoretrooper', name: 'Shore Trooper' }
        ]
      },
      {
        id: 310,
        name: '2x',
        videoThumb: 'HAzLlLIAVUM?t=304&si=4xcKMbTGA1Z_fmYI',
        feats: [
          { name: 'Accuracy Up', qty: 5 },
        ],
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [
          { image: 'mothma', name: 'Mothma' },
          { image: 'pathfinder', name: 'Scarif Pathfinder', main: true },
          { image: 'biggs', name: 'Biggs' },
          { image: 'pao', name: 'Pao' },
          { image: 'captain-rex', name: 'Captain Rex' },
        ]
      },
      {
        id: 311,
        name: '2x',
        videoThumb: '',
        feats: [
          { name: 'Accuracy Up', qty: 5 },
        ],
        global: [
          { name: 'Dark Gideon' },
        ],
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon', main: true },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'stormtrooper', name: 'Stormtrooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'gideon', name: 'Gideon' }
        ]
      },
    ]
  },
  {
    title: `Sector 3 - Mini Boss`,
    boss: 'bo-mandalore',
    teams: [
      {
        id: 312,
        name: 'GR Jedi',
        videoThumb: 'zBrs1T_iqiw?t=85&si=auWnaDmH-PdBoOwA',
        toons: [
          { image: 'gk', name: 'General Kenobi' },
          { image: 'qgj', name: 'QGJ' },
          { image: 'jka', name: 'Jedi Ani' },
          { image: 'gas', name: 'General Skywalker' },
          { image: 'shaak-ti', name: 'Shaak Ti' }
        ]
      },
      {
        id: 313,
        name: 'Hokey Religions',
        videoThumb: 'zBrs1T_iqiw?t=229&si=It3XITd7FhFYkxdO',
        feats: [
          { name: 'Adapt or Die', qty: 5 },
        ],
        toons: [
          { image: 'iden', name: 'Iden' },
          { image: 'starck', name: 'Starck' },
          { image: 'range', name: 'Range Trooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'shoretrooper', name: 'Shore Trooper' }
        ]
      }
    ]
  },
  {
    title: 'Sector 3 - Progress',
    progress: [
      { name: 'Han', current: 10, target: 35 },
      { name: 'Separatists', current: 2, target: 10 },
      { name: 'Adapt or Die', current: 10, target: 50 },
      { name: 'Accuracy Up', current: 20, target: 50 }
    ]
  },



  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Jedi Cal', skip: true },
      { name: 'Challenge Path', current: 150, target: 250 },      
      { name: 'Captain Rex', current: 12, target: 15 },
      { name: 'Gungans', current: 2, target: 15 },
      { name: 'Dark Gideon', current: 10, target: 35 },
      { name: 'Master & Padawn', current: 15, target: 15 },
    ]
  }
];

export {
  day3
}