const day10 = [  
  {
    title: `Sector 5 - Battles`,
    teams: [
      {
        id: 1001,
        name: '4x',
        videoThumb: '3Mn7qzQfLbo?t=59&si=ZyfW1xAJWR3bkuIA',
        feats: [
          { name: 'Phoenix', qty: 5 },
          { name: 'Evasion Down', qty: 10 },
        ],
        global: [
          { name: 'Captain Rex' }
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'chewie', name: 'Chewie' },
          { image: 'han', name: 'Han' },
          { image: 'threepio', name: 'Threepio', main: true  },
          { image: 'captain-rex', name: 'Captain Rex' }
        ]
      },
      {
        id: 1002,
        name: '4x',
        videoThumb: '3Mn7qzQfLbo?t=97&si=YL_StWg-WkJd3whf',
        feats: [
          { name: 'Bounty Hunters', qty: 5 },
          { name: 'Evasion Down', qty: 5 },
        ],
        toons: [
          { image: 'bossk', name: 'Bossk' },
          { image: 'zam', name: 'Zam', main: true  },
          { image: 'greef', name: 'Greef'},
          { image: 'mando', name: 'Mandalorian' },
          { image: 'bam', name: 'Beskar Mando' }
        ]
      },
      {
        id: 1003,
        name: '2x',
        toons: [
          { image: 'cup', name: 'CUP' },
        ]
      },
    ]
  },
  {
    title: `Sector 5 - Final Boss`,
    boss: 'saw',
    teams: [
      {
        id: 1004,
        name: 'Hokey Religions',
        videoThumb: '4KDS7QQnH5A?t=365&si=NJSsCGqU-XrG2agj',
        feats: [
          { name: 'Phoenix', qty: 1 },
          { name: 'Evasion Down', qty: 5 },
        ],
        global: [
          { name: 'Captain Rex' }
        ],
        toons: [
          { image: 'dash', name: 'Dash' },
          { image: 'han', name: 'Han' },
          { image: 'chewie', name: 'Chewie' },
          { image: 'threepio', name: 'Threepio' },
          { image: 'captain-rex', name: 'Captain Rex'}
        ]
      },
    ]
  },
  {
    title: 'Sector 5 - Progress',
    progress: [
      { name: 'Phoenix', skip: true },
      { name: 'Qui-Gons', skip: true },
      { name: 'Evasion Down', current: 50, target: 50 },
      { name: 'Bounty Hunters', current: 35, target: 35 },
    ]
  },


  
  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Jedi Cal', skip: true },
      { name: 'Dark Gideon', skip: true },    
      { name: 'Captain Rex', complete: true },
      { name: 'Challenge Path', current: 250, target: 250 },  
      { name: 'Gungans', current: 4, target: 15 },
      { name: 'Master & Padawn', current: 8, target: 15 },
    ]
  }
];

export {
  day10
}