const sector3 = [
  {
    title: 'Han',
    text: 'Defeat 35 enemies with any Han',
    keycards: 10,
    teams: [
      {
        videoThumb: 'HAzLlLIAVUM?t=252&si=2N9mSNIKYamM3Jph',
        feats: [
          { name: 'Han', qty: 5 },
        ],
        global: [
          { name: 'Master & Padawan' },
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'capt-han', name: 'Captain Han', main: true  },
          { image: 'wat', name: 'Wat' },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
        ]
      },
      {
        name: '',
        videoThumb: 'HAzLlLIAVUM?t=190&si=Yc3LRwv5Lx-4ZvXK',
        feats: [
          { name: 'Han', qty: 3 },
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'han', name: 'Han' },
          { image: 'capt-han', name: 'Captain Han' },
          { image: 'chewie', name: 'Chewie' },
          { image: 'threepio', name: 'Threepio' }
        ]
      },
      {
        videoThumb: '',
        feats: [
          { name: 'Han', qty: 5 },
          { name: 'Accuracy Up', qty: 5 },
        ],
        global: [
          { name: 'Master & Padawan' },
        ],
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'capt-han', name: 'Captain Han', main: true  },
          { image: 'cere', name: 'Cere' },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
        ]
      },
    ]
  },
  {
    title: 'Separatists',
    text: 'Win 10 battles with a full squad of Separatist units',
    keycards: 10,
    teams: [
      {
        videoThumb: 'HAzLlLIAVUM?t=51&si=oSiPct3UBoXvfa1P',
        feats: [
          { name: 'Separatists', qty: 1 },
        ],
        toons: [
          { image: 'gg', name: 'Grievous' },
          { image: 'b1', name: 'B1' },
          { image: 'b2', name: 'B2' },
          { image: 'magna', name: 'Magna' },
          { image: 'stap', name: 'STAP' }
        ]
      },
      {
        videoThumb: 'HAzLlLIAVUM?t=88&si=bFHQiowoEsT-1VQA',
        feats: [
          { name: 'Separatists', qty: 1 },
        ],
        toons: [
          { image: 'gba', name: 'Brood Alpha' },
          { image: 'poggle', name: 'Poggle' },
          { image: 'sun-fac', name: 'Sun Fac' },
          { image: 'geo-spy', name: 'Geo Spy' },
          { image: 'geo-soldier', name: 'Geo Soldier' }
        ]
      },
      {
        videoThumb: 'HAzLlLIAVUM?t=131&si=32w2OYs9-JXhTfzY',
        feats: [
          { name: 'Separatists', qty: 1 },
        ],
        toons: [
          { image: 'trench', name: 'Trench' },
          { image: 'nute', name: 'Nute' },
          { image: 'wat', name: 'Wat' },
          { image: 'dooku', name: 'Dooku' },
          { image: 'jango', name: 'Jango' }
        ]
      },
    ]
  },
  {
    title: 'Adapt or Die',
    text: 'Gain We Adapt, or Die 50 times',
    keycards: 10,
    teams: [
      {
        videoThumb: 'HAzLlLIAVUM?t=365&si=XEt7KCaagaCqGb_Q',
        feats: [
          { name: 'Adapt or Die', qty: 5 },
        ],
        toons: [
          { image: 'iden', name: 'Iden', main: true },
          { image: 'starck', name: 'Starck' },
          { image: 'range', name: 'Range Trooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'shoretrooper', name: 'Shore Trooper' }
        ]
      }
    ]
  },
  {
    title: 'Accuracy Up',
    text: 'Gain Accuracy Up 50 times',
    keycards: 10,
    teams: [
      {
        name: 'Cheese',
        videoThumb: 'rlb_tlMtOlE?t=521&si=0RYlaHqojpDSpB4f',
        feats: [
          { name: 'Accuracy Up', qty: 40 },
        ],
        toons: [          
          { image: 'cls', name: 'Commander Luke' },
          { image: 'pathfinder', name: 'Scarif Pathfinder', main: true },
          { image: 'han', name: 'Han' },
          { image: 'chewie', name: 'Chewie' },
        ]
      },
      {
        videoThumb: 'HAzLlLIAVUM?t=304&si=4xcKMbTGA1Z_fmYI',
        feats: [
          { name: 'Accuracy Up', qty: 5 },
        ],
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [
          { image: 'mothma', name: 'Mothma' },
          { image: 'pathfinder', name: 'Scarif Pathfinder', main: true },
          { image: 'biggs', name: 'Biggs' },
          { image: 'pao', name: 'Pao' },
          { image: 'captain-rex', name: 'Captain Rex' },
        ]
      },
      {
        videoThumb: '',
        feats: [
          { name: 'Accuracy Up', qty: 10 },
        ],
        global: [
          { name: 'Master & Padawan' },
        ],
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'amidala', name: 'Amidala', main: true  },
          { image: 'cere', name: 'Cere', main: true },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
        ]
      },
      {
        videoThumb: '',
        feats: [
          { name: 'Accuracy Up', qty: 5 },
        ],
        global: [
          { name: 'Master & Padawan' },
        ],
        toons: [
          { image: 'padme', name: 'Padme' },
          { image: 'amidala', name: 'Amidala', main: true  },
          { image: 'jka', name: 'Jedi Anakin' },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
        ]
      },
      {
        videoThumb: '',
        feats: [
          { name: 'Accuracy Up', qty: 5 },
        ],
        global: [
          { name: 'Dark Gideon' },
        ],
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon', main: true },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'stormtrooper', name: 'Stormtrooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'gideon', name: 'Gideon' }
        ]
      },
    ]
  },
  {
    title: 'Mini Boss',
    text: 'Battle vs Bo-Katan Mandalore with a full squad of Galactic Republic Jedi and without Jedi, Sith or UFUs',
    keycards: 7,
    teams: [
      {
        name: 'GR Jedi',
        videoThumb: 'zBrs1T_iqiw?t=85&si=auWnaDmH-PdBoOwA',
        toons: [
          { image: 'gk', name: 'General Kenobi' },
          { image: 'qgj', name: 'QGJ' },
          { image: 'jka', name: 'Jedi Ani' },
          { image: 'gas', name: 'General Skywalker' },
          { image: 'shaak-ti', name: 'Shaak Ti' }
        ]
      },
      {
        name: 'Hokey Religions',
        videoThumb: 'zBrs1T_iqiw?t=229&si=It3XITd7FhFYkxdO',
        feats: [
          { name: 'Adapt or Die', qty: 5 },
        ],
        toons: [
          { image: 'iden', name: 'Iden' },
          { image: 'starck', name: 'Starck' },
          { image: 'range', name: 'Range Trooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'shoretrooper', name: 'Shore Trooper' }
        ]
      },
    ]
  },
  {
    title: 'Final Boss',
    text: 'Battle vs Dark Gideon and with Boss Nass surviving and Dark Side units only',
    keycards: 11,
    teams: [
      {
        name: 'Boss Nass',
        videoThumb: 'zBrs1T_iqiw?t=391&si=u0wE8Iwg2hFL2bVv',
        feats: [
          { name: 'Han', qty: 1 },
        ],
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [
          { image: 'dash', name: 'Dash' },
          { image: 'wat', name: 'Wat' },
          { image: 'captain-rex', name: 'Captain Rex' },
          { image: 'boss-nass', name: 'Boss Nass', main: true },
          { image: 'capt-han', name: 'Captain Han' },
        ]
      },
      {
        videoThumb: 'zBrs1T_iqiw?t=520&si=0f29drAJWBMm3Q_s',
        global: [
          { name: 'Gungans' },
        ],
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'boss-nass', name: 'Boss Nass', main: true },
          { image: 'phalanx', name: 'Phalanx' },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
      {
        name: 'Dark Side',
        videoThumb: 'zBrs1T_iqiw?t=575&si=har82nfsD4WpR6v2',
        feats: [
          { name: 'Separatists', qty: 1 },
        ],
        toons: [
          { image: 'gg', name: 'Grievous' },
          { image: 'b1', name: 'B1' },
          { image: 'b2', name: 'B2' },
          { image: 'magna', name: 'Magna' },
          { image: 'wat', name: 'Wat' }
        ]
      },
    ]
  }
];

export {
  sector3
}