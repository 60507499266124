const day14 = [

  {
    title: `Sector 1 - Datacron Farming`,
    teams: [
      {
        id: 1401,
        name: '4x',
        videoThumb: 'rlb_tlMtOlE?t=801&si=4zu5ZVV5BZHAxiSt',
        global: [
          { name: 'Gungans' },
        ],
        toons: [
          { image: 'dash', name: 'Dash' },
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx' },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
      {
        id: 1402,
        name: '2x',
        videoThumb: 'rlb_tlMtOlE?t=817&si=MpVvUbXsbE9tfPqB',
        global: [
          { name: 'Master & Padawan' },
        ],
        toons: [
          { image: 'jkl', name: 'Jedi Luke'  },
          { image: 'jolee', name: 'Jolee' },
          { image: 'master-qgj', name: 'Master QGJ', main: true },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan', main: true },
          { image: 'shaak-ti', name: 'Shaak Ti' },
        ]
      },
    ]
  },
  {
    title: 'Sector 1 - Progress',
    progress: [
      { name: 'Mandalorians', skip: true },
      { name: 'Ewoks', skip: true },
      { name: 'Evasion Up', complete: true },
      { name: 'Resistance', complete: true }
    ]
  },
  {
    title: `Sector 4 - Battles`,
    teams: [
      {
        id: 1403,
        name: '2x',
        videoThumb: 'rlb_tlMtOlE?t=742&si=BTCzJYnbWeHhmNkF',
        feats: [
          { name: 'STAP', qty: 1 }
        ],
        toons: [
          { image: 'dash', name: 'Dash'  },
          { image: 'stap', name: 'STAP', main: true },
          { image: 'han', name: 'Han'},
          { image: 'phalanx', name: 'Phalanx' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
    ]
  },
  {
    title: 'Sector 4 - Progress',
    progress: [
      { name: 'Shield Up', skip: true },
      { name: 'Accuracy Down', skip: true },
      { name: 'Rebels', skip: true },
      { name: 'STAP', current: 10, target: 10 },
    ]
  },


  
  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Jedi Cal', skip: true },
      { name: 'Dark Gideon', skip: true },    
      { name: 'Captain Rex', complete: true },
      { name: 'Challenge Path', complete: true },  
      { name: 'Gungans', current: 15, target: 15 },
      { name: 'Master & Padawn', current: 15, target: 15 },
    ]
  }
];

export {
  day14
}