const rancor = [
    { name: 'Lord Vader', desc: `Testing out DV and LV lead in all phases`, id: 'bfXsh4xbBo4' },
    { name: 'Jedi Master Luke', desc: `Testing out JML in all phases`, id: 'JAmjvMsekKA' },
    { name: 'Jedi Master Kenobi', desc: `Testing out JMK + CAT in all phases`, id: 'LTU-97SxtnU' },
    { name: 'Bounty Hunters', desc: `Testing out Bountty Hunters in all phases`, id: 'DoD5ZniuK14' },
    { name: 'Padme + CAT', desc: `Testing out Padme + CAT in all phases`, id: 'bG2WgFUIx3Q' },
    { name: 'SLKR Full First Order', desc: `Testing out SLKR in all phases`, id: 'ar6a_KkqE3Q' },
    { name: 'Padme', desc: `Testing out Padme in all phases`, id: 'swtDJgR4agY' },
    { name: 'Vader + Wat', desc: `Testing out Vader in all phases`, id: 'nfDVlp5fw7Q' },
    { name: 'SEE + Malak', desc: `Testing out Sith Etnernal Emperor in all phases`, id: 'nVaWmFy5spw' },
    { name: 'Shaak Ti + 501st', desc: `Testing out Shaak Ti in all phases`, id: 'RRMKWesksJI' },
    { name: 'Rey + 501st', desc: `Testing out Rey in all phases`, id: 'aiR_skm0KB0' },
    { name: 'Jedi Knight Luke', desc: `Testing out JKL in all phases`, id: 'JWcQfFHza3E' },
];
const sith = [
    { name: 'Easy Solo with Rey', desc: `Rey, Commander Ahsoka, C-3PO, Shaak-Ti and Hermit Yoda`, id: 'z7Ff2Ln2KFM' },
    { name: 'Easy Solo with Master Kenobi', desc: `Master Kenobi, Commander Ahsoka, C-3PO, Shaak-Ti and Mace`, id: 'rWYBKsEnFLY' },
    { name: 'Easy Solo with Lord Vader', desc: `Darth Vader, Lord Vader, Thrawn, Wat and Traya`, id: 'U-6Yg9wnlUo' },
    { name: 'Easy Solo with SLKR in 2022', desc: `Hux, SLKR, Thrawn, Wat and Hermit Yoda`, id: 'qakHqb3ZkEU' },
    { name: 'SLKR 3-Man Solo', desc: `SL Kylo Ren, Hux and Kylo Ren (Unmasked)`, id: '7kFDz-lOKak' },
    { name: 'RJT + Rex - Phase 1 Solo', desc: `RJT, Fives, Rex, C-3PO and BB-8`, id: 'RggdNN_5qCc' },
    { name: 'GAS + C-3PO - Phase 1 Solo', desc: `GAS, Fives, Rex, C-3PO and GMY`, id: 'q1jeB9pJHvI' },
    { name: 'RJT + Finn - Phase 1 (20% / 10M Damage)', desc: `All G12 RTJ, Finn, R2-D2, BB-8 and C-3PO`, id: 'CsRQCYOpZWM' },
    { name: 'Jedi - Phase 2 (20% / 10M)', desc: `All G12 JKR, GMY, Jolee, Hoda and Basti`, id: 'u6s6Tpi2scI' },
    { name: 'RJT + Rey - Phase 3 & 4 Solo', desc: `RJT lead, Rey, Hero Finn, BB-8 and C-3PO`, id: 'Hh8J-GXDtdc' },
    { name: 'Shaak Ti + 501st - Phase 3 & 4 Solo', desc: `Relic Shaak Ti + 501st Clones`, id: 'OBUppMOOo8Y' },
    { name: 'Chex Mix - Phase 3 (5% / 2.8M)', desc: `All G12 CLS, Han Solo, JKA, Chewi and Death Trooper`, id: 'mVOE6H6wjuc' }
];
const krayt = [
    { name: 'Lazy guide', desc: `11M+ Total Damage mostly on AUTO!`, id: '17Ho52ubKYY' },
    { name: 'Low level guide', desc: `3M+ Total Damage without swapping mods`, id: 'WaaNp2MXWG8' }
];

export {
    sith,
    krayt,
    rancor
}