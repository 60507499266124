const day4 = [  
  {
    title: `Sector 3 - Battles`,
    teams: [
      {
        id: 401,
        name: '2x',
        videoThumb: 'HAzLlLIAVUM?t=252&si=2N9mSNIKYamM3Jph',
        feats: [
          { name: 'Han', qty: 5 },
        ],
        global: [
          { name: 'Master & Padawan' },
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'capt-han', name: 'Captain Han', main: true  },
          { image: 'wat', name: 'Wat' },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
        ]
      },
      {
        id: 402,
        name: '2x',
        videoThumb: 'HAzLlLIAVUM?t=51&si=oSiPct3UBoXvfa1P',
        feats: [
          { name: 'Separatists', qty: 1 },
        ],
        toons: [
          { image: 'gg', name: 'Grievous' },
          { image: 'b1', name: 'B1' },
          { image: 'b2', name: 'B2' },
          { image: 'magna', name: 'Magna' },
          { image: 'stap', name: 'STAP' }
        ]
      },
      {
        id: 403,
        name: '2x',
        videoThumb: 'HAzLlLIAVUM?t=365&si=XEt7KCaagaCqGb_Q',
        feats: [
          { name: 'Adapt or Die', qty: 5 },
        ],
        toons: [
          { image: 'iden', name: 'Iden', main: true },
          { image: 'starck', name: 'Starck' },
          { image: 'range', name: 'Range Trooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'shoretrooper', name: 'Shore Trooper' }
        ]
      },
      {
        id: 404,
        name: '2x',
        videoThumb: 'HAzLlLIAVUM?t=304&si=4xcKMbTGA1Z_fmYI',
        feats: [
          { name: 'Accuracy Up', qty: 5 },
        ],
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [
          { image: 'mothma', name: 'Mothma' },
          { image: 'pathfinder', name: 'Scarif Pathfinder', main: true },
          { image: 'biggs', name: 'Biggs' },
          { image: 'pao', name: 'Pao' },
          { image: 'captain-rex', name: 'Captain Rex' },
        ]
      },
      {
        id: 405,
        name: '2x',
        videoThumb: '',
        feats: [
          { name: 'Accuracy Up', qty: 5 },
        ],
        global: [
          { name: 'Dark Gideon' },
        ],
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon', main: true },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'stormtrooper', name: 'Stormtrooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'gideon', name: 'Gideon' }
        ]
      },
    ]
  },
  {
    title: `Sector 3 - Final Boss`,
    boss: 'dark-gideon',
    teams: [
      {
        id: 406,
        name: 'Boss Nass',
        videoThumb: 'zBrs1T_iqiw?t=520&si=0f29drAJWBMm3Q_s',
        global: [
          { name: 'Gungans' },
        ],
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'boss-nass', name: 'Boss Nass', main: true },
          { image: 'phalanx', name: 'Phalanx' },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
      {
        id: 407,
        name: 'Dark Side',
        videoThumb: 'zBrs1T_iqiw?t=575&si=har82nfsD4WpR6v2',
        feats: [
          { name: 'Separatists', qty: 1 },
        ],
        toons: [
          { image: 'gg', name: 'Grievous' },
          { image: 'b1', name: 'B1' },
          { image: 'b2', name: 'B2' },
          { image: 'magna', name: 'Magna' },
          { image: 'wat', name: 'Wat' }
        ]
      }
    ]
  },
  {
    title: 'Sector 3 - Progress',
    progress: [
      { name: 'Han', current: 20, target: 35 },
      { name: 'Separatists', current: 4, target: 10 },
      { name: 'Adapt or Die', current: 20, target: 50 },
      { name: 'Accuracy Up', current: 40, target: 50 }
    ]
  },

  {
    title: `Sector 4 - Battles`,
    teams: [
      {
        id: 408,
        name: '2x',
        videoThumb: 'WO73F8CRelM?t=178&si=HP5vkUHJNKILGTJj',
        feats: [
          { name: 'STAP', qty: 1 },
          { name: 'Accuracy Down', qty: 5 }
        ],
        toons: [
          { image: 'dash', name: 'Dash'  },
          { image: 'stap', name: 'STAP', main: true },
          { image: 'eighth-brother', name: '8th Bro'},
          { image: 'thrawn', name: 'Thrawn'},
          { image: 'gideon', name: 'Gideon' },
        ]
      },
      {
        id: 409,
        name: '2x',
        videoThumb: 'WO73F8CRelM?t=394&si=ZBidmofNIQ4lTYok',
        feats: [
          { name: 'Shield Up', qty: 4 },
        ],
        global: [
          { name: 'Gungans' }
        ],
        toons: [          
          { image: 'rey', name: 'Rey' },
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx', main: true },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
      {
        id: 410,
        name: '2x',
        videoThumb: 'WO73F8CRelM?t=258&si=aEAgeF-1BB4OSFnu',
        feats: [
          { name: 'Accuracy Down', qty: 10 }
        ],
        toons: [
          { image: 'palpatine', name: 'Palpatine' },
          { image: 'seventh-sister', name: '7th Sis', main: true },
          { image: 'eighth-brother', name: '8th Bro', main: true},
          { image: 'malak', name: 'Malak' },
          { image: 'stap', name: 'STAP' },
        ]
      },
      {
        id: 411,
        name: '2x',
        videoThumb: 'WO73F8CRelM?t=110&si=s65dxp23IKJivwkt',
        feats: [
          { name: 'Rebels', qty: 1 },
        ],
        global: [
          { name: 'Captain Rex' }
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'chewie', name: 'Chewie' },
          { image: 'han', name: 'Han' },
          { image: 'threepio', name: 'Threepio' },
          { image: 'captain-rex', name: 'Captain Rex' }
        ]
      },
    ]
  },
  {
    title: 'Sector 4 - Progress',
    progress: [
      { name: 'STAP', current: 4, target: 10 },
      { name: 'Shield Up', current: 8, target: 50 },
      { name: 'Accuracy Down', current: 30, target: 50 },
      { name: 'Rebels', current: 2, target: 10 }
    ]
  },



  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Jedi Cal', skip: true },
      { name: 'Master & Padawn', complete: true },
      { name: 'Challenge Path', current: 200, target: 250 },      
      { name: 'Captain Rex', current: 15, target: 15 },
      { name: 'Gungans', current: 4, target: 15 },
      { name: 'Dark Gideon', current: 10, target: 35 },
    ]
  }
];

export {
  day4
}