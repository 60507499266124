const sector2 = [

  {
    title: 'Rebel Fighters',
    text: 'Defeat 35 enemies with Rebel Figthers',
    keycards: 5,
    teams: [
      {
        videoThumb: '-9XDYTjLfq4?t=71&si=dE6hBZ9ndUHmusiv',
        feats: [
          { name: 'Rebel Fighters', qty: 5 },
        ],
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [
          { image: 'mothma', name: 'Mothma' },
          { image: 'kyle', name: 'Kyle' },
          { image: 'biggs', name: 'Biggs' },
          { image: 'pao', name: 'Pao'},
          { image: 'captain-rex', name: 'Captain Rex' },
        ]
      },
      {
        videoThumb: '-9XDYTjLfq4?t=131&si=sasYnNugagzjGTJ0',
        feats: [
          { name: 'Rebel Fighters', qty: 5 },
        ],
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [
          { image: 'saw', name: 'Saw' },
          { image: 'captain-rex', name: 'Captain Rex' },
          { image: 'chirrut', name: 'Chirrut' },
          { image: 'baze', name: 'Baze'},
          { image: 'cassian', name: 'Cassian' },
        ]
      },
      {
        videoThumb: '-9XDYTjLfq4?t=174&si=zgeYJp4jEM-repfw',
        feats: [
          { name: 'Rebel Fighters', qty: 5 },
        ],
        global: [
          { name: 'Captain Rex' },
          { name: 'Master & Padawan' },
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'wat', name: 'Wat' },
          { image: 'captain-rex', name: 'Captain Rex', main: true },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
        ]
      },
    ]
  },
  {
    title: 'Imperial Troopers',
    text: 'Defeat 35 enemies with Imperial Troopers',
    keycards: 5,
    teams: [
      {
        videoThumb: '-9XDYTjLfq4?si=lO1rXhWNpM23QngU&t=220',
        feats: [
          { name: 'Imperial Troopers', qty: 5 },
        ],
        toons: [
          { image: 'veers', name: 'Veers' },
          { image: 'gideon', name: 'Gideon' },
          { image: 'starck', name: 'Starck' },
          { image: 'piett', name: 'Piett' },
          { image: 'range', name: 'Range Trooper' },
        ]
      },
      {
        videoThumb: '-9XDYTjLfq4?t=252&si=u5UlHC7PGiHhhY4s',
        feats: [
          { name: 'Imperial Troopers', qty: 5 },
        ],
        global: [
          { name: 'Dark Gideon' },
        ],
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon', main: true },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'stormtrooper', name: 'Stormtrooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'gideon', name: 'Gideon' }
        ]
      }
    ]
  },
  {
    title: `Nightsisters`,
    text: `Win 10 battles with a full squad of Nightsisters`,
    keycards: 5,
    teams: [
      {
        videoThumb: '-9XDYTjLfq4?si=sEEbLidhCHwQwmeF&t=282',
        feats: [
          { name: 'Nightsisters', qty: 1 },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'merrin', name: 'Merrin' },
          { image: 'daka', name: 'Daka' },
          { image: 'asajj', name: 'Asajj' },
          { image: 'zombie', name: 'Zombie' }
        ]
      },
    ]
  },
  {
    title: 'Potency Down',
    text: 'Attempt to inflict Potency Down 50 times',
    keycards: 5,
    teams: [   
      {
        name: 'Cheese',
        videoThumb: 'rlb_tlMtOlE?t=156&si=wc6LXJI2QIMQIoaH',
        feats: [
          { name: 'Potency Down', qty: 10 },
        ],
        toons: [          
          { image: 'cls', name: 'Commander Luke' },
          { image: 'gmy', name: 'Master Yoda', main: true },
          { image: 'han', name: 'Han' },
          { image: 'chewie', name: 'Chewie' },
        ]
      },
      {
        videoThumb: '-9XDYTjLfq4?t=327&si=vpEilQtkZTeRt8bO',
        feats: [
          { name: 'Potency Down', qty: 10 },
        ],
        global: [
          { name: 'Master & Padawan' },
        ],
        toons: [          
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'gmy', name: 'Master Yoda', main: true },
          { image: 'jolee', name: 'Jolee' },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
        ]
      },
      {
        videoThumb: '-9XDYTjLfq4?t=417&si=P8EZquSJwUsAuphi',
        feats: [
          { name: 'Potency Down', qty: 10 },
        ],
        global: [
          { name: 'Master & Padawan' },
        ],
        toons: [          
          { image: 'rey', name: 'Rey' },
          { image: 'young-lando', name: `Young Lando`, main: true },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
          { image: 'jedi-cal', name: 'Jedi Cal' },
        ]
      },
    ]
  },
  {
    title: 'Mini Boss',
    text: 'Battle vs Kelleran with Sabine defeating an enemy and a full squad of Galactic Republic',
    keycards: 7,
    teams: [
      {
        name: 'Sabine',
        videoThumb: 'otpaqFu5xSo?t=90&si=GA5jglEUEX7bo7kk',
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'sabine', name: 'Sabine', main: true },
          { image: 'wat', name: 'Wat' },
          { image: 'captain-rex', name: 'Captain Rex' },
          { image: 'gmy', name: 'Yoda' }
        ]
      },
      {
        name: 'Galactic Republic',
        videoThumb: 'otpaqFu5xSo?t=220&si=eBTab53C1AHP8M7U',
        feats: [
          { name: 'Potency Down', qty: 5 },
        ],
        toons: [          
          { image: 'padme', name: 'Padme' },
          { image: 'jka', name: 'Jedi Anakin' },
          { image: 'gk', name: 'General Kenobi' },
          { image: 'gmy', name: 'Master Yoda' },
          { image: 'ahsoka', name: 'Ahsoka' },
        ]
      },
    ]
  },
  {
    title: 'Final Boss',
    text: 'Battle vs Bossk with Saw surviving and a full squad of Empire',
    keycards: 9,
    teams: [
      {
        name: 'Saw',
        videoThumb: 'otpaqFu5xSo?t=425&si=LLbZme1IL9m65xwJ',
        feats: [
          { name: 'Rebel Fighters', qty: 5 },
        ],
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [          
          { image: 'mothma', name: 'Mothma' },
          { image: 'saw', name: 'Saw', main: true },
          { image: 'biggs', name: 'Biggs' },
          { image: 'pao', name: 'Pao'},
          { image: 'captain-rex', name: 'Captain Rex' }
        ]
      },
      {
        videoThumb: 'otpaqFu5xSo?t=354&si=mBwAXrVVzwe_VHHK',
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [          
          { image: 'dash', name: 'Dash' },
          { image: 'saw', name: 'Saw', main: true },
          { image: 'wat', name: 'Wat' },
          { image: 'admiralraddus', name: 'Raddus'},
          { image: 'captain-rex', name: 'Captain Rex' }
        ]
      },
      {
        name: 'Empire',
        videoThumb: 'otpaqFu5xSo?t=515&si=0ug4ZDOHpf8VBYDN',
        feats: [
          { name: 'Imperial Troopers', qty: 5 },
        ],
        toons: [
          { image: 'veers', name: 'Veers' },
          { image: 'gideon', name: 'Gideon' },
          { image: 'starck', name: 'Starck' },
          { image: 'piett', name: 'Piett' },
          { image: 'range', name: 'Range Trooper' },
        ]
      },
    ]
  }
];

export {
  sector2
}