import React, { useRef } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

import './_style.scss';

const SwTypeAhead = props => {
  const ref = useRef();

  const onSelect = (value) => {
    props.onChange && props.onChange(value);
    ref.current.blur();
    ref.current.clear();
  }

  const list = props.options.filter(o => o.noCounters !== true);

  return (
    <div className="sw-typeahead">
      <Typeahead
        onChange={onSelect} placeholder={props.placeholder} 
        clearButton={props.clearButton} options={list} 
        labelKey={props.labelKey} id={props.id} ref={ref} open={props.open}
        renderMenuItemChildren={props.renderOption} autoFocus={props.autoFocus}
        emptyLabel={props.emptyLabel} maxHeight="400px" dropup={props.dropup}
      />
    </div>
  );
}

export default SwTypeAhead;