const leviathan = [
    {
        name: '17 Ways to beat Leviathan',  id: 'hfYsyaxcKe8',
        desc: `The complete counter guide`
    },
    {
        name: 'Leviathan Mirror Counter Guide',  id: 'NdiDGJUIUa8',
        desc: `Featuring Mark VI Interceptor 6`
    },
    {
        name: 'Leviathan Tier 4 Unlock + Bonus Tier Guide',  id: '5r6-2xPYZNE',
        desc: `Dark Sovereign Journey Fleet Mastery Event`
    }
];

const negotiator = [
    {
        name: '8 Ways to beat Negotiator',  id: 'rK8Min21Hys',
        desc: `The complete counter guide`
    },
    { 
        name: 'Tie Fighter joins R7 vs R8 Negotiator mirror', id: 'OjaQc-264Go' , 
        desc: `Use Tie Fighter to boost your Negotiator fleet`
    },
    {
        name: 'R7 vs R8 Negotiator mirror guide', id: 'Jzbz7Okh3Os',
        desc: `Learn how to take down R8 Negotiators with ease!`
    }
];


const malevolence = [
    {
        name: '8 Ways to beat Malevolence',  id: 'tS74oCQGtZM',
        desc: `The complete counter guide`
    },
    {
        name: 'Malevolence vs Profundity Counter Guide',  id: 'VwAGPlAgXuo',
        desc: `How to defeat Profundity with Malevolence`
    },
    {
        name: 'Malevolence vs Executor Triple Attackers Guide',  id: 'yfmK1MGAL24',
        desc: `How to defeat Executor with RC, IG and XB starting`
    },
    {
        name: 'Malevolence vs Executor In-depth Guide',  id: 'WvFNuHkYaKU',
        desc: `The dodge meta is back!`
    }
];

const executor = [
    {
        name: 'Executor vs Leviathan',  id: 'VFAg200P5FE',
        desc: `In-depth counter guide`
    },
    {
        name: '12 Ways to beat Executor',  id: 'QEuXFNBz3-s',
        desc: `The complete counter guide`
    },
    {
        name: 'Executor vs Profundity Counter Guide',  id: 'zj-R1VzzAnU',
        desc: `How to defeat Profundity with Executor`
    },
    { 
        name: 'Executor Mirror Match Guide 2.5', id: 'ZauH5W_4ero', 
        desc: `7* Executor mirror matches without removing mods!`
    },
    { 
        name: 'Executor Journey Guide Fleet Mastery', id: '7Th7rtPEmeY', 
        desc: `Beat the bonus tier to earn blueprints`
    }
];

const finalizer = [
    {
        name: '8 Ways to beat Finalizer',  id: 'oTu1J12LvsI',
        desc: `The complete counter guide`
    },
    {
        name: '7* Tie Echelon vs Executor Guide',  id: 'MRGtZS_XIuY',
        desc: `Executor counter with 7* Echelon and R6 Finalizer`
    },
    { 
        name: 'Finalizer Advanced Fleet Mastery', id: 'HoN-ZuigRkw', 
        desc: `Beat the bonus tier with this easy to use fleet`
    }
];

const raddus = [
    {
        name: '8 Ways to beat Raddus',  id: '7vl0cWs_PS8',
        desc: `The complete counter guide`
    }
];

const homeOne = [
    {
        name: '7 Ways to beat Raddus',  id: 'ipWU0zN8URM',
        desc: `The complete counter guide`
    },
    {
        name: 'Home One vs Executor Counter Guide', id: 'W4SO2UjR3To',
        desc: `Rebels take down Executor with RC, IG and XB starting`
    }
];

const executrix = [
    {
        name: 'Executrix vs Profundity Counter Guide',  id: 'cMlyOa_3zYo',
        desc: `How to defeat Profundity with Executrix`
    }
];

const chimaera = [
    {
        name: 'Chimaera vs Leviathan',  id: 'HggSqNq2FVc',
        desc: `In-depth counter guide`
    },
    {
        name: '9 Ways to beat Chimaera',  id: 'nVnxzYCa4tk',
        desc: `The complete counter guide`
    }
];

const profundity = [
    {
        name: 'Profundity vs Leviathan',  id: 'xCYaAG8djbw',
        desc: `In-depth counter guide`
    },
    {
        name: '4 Ways to beat Profundity',  id: 'xTOM3SL2xDk',
        desc: `The complete counter guide`
    },
    {
        name: 'Profundity Unlock Guide',  id: 'ezIfU6ugSiA',
        desc: `Guide for completing Tier 1 to 4`
    },
    {
        name: 'Profundity Bonus Tier Guide',  id: 'pgEfr9k0W2I',
        desc: `Guide for completing Bonus Tier`
    }
];


const counters = [
    { 
        name: 'Chimaera', url: 'https://youtu.be/oaXmbdPuyi8?t=962&si=W3NTsRkpCDqWbxwG', 
        value: 'chimaera', counter: 'executor', banners: 68,
        squad: ['chimaera', 'scythe', 'tie-advanced', 'tie-fighter'],
        defense: ['executor', 'houndstooth', 'razor-crest', 'xanadu-blood']
    },
    { 
        name: 'Profundity', url: 'https://youtu.be/NdiDGJUIUa8?t=348&si=2jJYUXRyYaizaa_f', 
        value: 'profundity', counter: 'leviathan', banners: 70,
        squad: ['leviathan', 'mark-vi-interceptor', 'sith-fighter', 'fury'],
        defense: ['leviathan', 'b-28', 'sith-fighter', 'fury']
    },
    { 
        name: 'Negotiator', url: 'https://youtu.be/oaXmbdPuyi8?t=856&si=qqfCh2Ta08B63CMQ', 
        value: 'negotiator', counter: 'profundity', banners: 73,
        squad: ['negotiator', 'ani-eta', 'marauder-ship', 'plo-starfighter'],
        defense: ['profundity', 'milf', 'rebel-ywing', 'outrider']
    },
    {
        name: 'Raddus', url: 'https://www.youtube.com/live/bBq3LGv7_Lw?t=5865&si=2cmQDGl_Eay0VO8b',
        value: 'raddus', counter: 'malevolence', banners: 73,
        squad: ['raddus', 'starfortress', 'poe-xwing', 'rey-falcon'],
        defense: ['malevolence', 'vulture', 'hyena', 'ship-sf']
    },
    { 
        name: 'Chimaera', url: 'https://youtu.be/YwTEr4zjJPM?t=1405&si=kS1PtPTfCotkFdZv', 
        value: 'chimaera', counter: 'negotiator', banners: 72,
        squad: ['chimaera', 'scythe', 'tie-advanced', 'tie-fighter'],
        defense: ['negotiator', 'marauder-ship', 'ani-eta', 'btl-ywing']
    },
    { 
        name: 'Negotiator', url: 'https://youtu.be/tyiUW3dJDho?t=1831&si=9NOINGhpJv6otnGW', 
        value: 'negotiator', counter: 'leviathan', banners: 67,
        squad: ['negotiator', 'ani-eta', 'marauder-ship', 'ahsoka-eta'],
        defense: ['leviathan', 'b-28', 'sith-fighter', 'fury']
    },
    { 
        name: 'Executrix', url: 'https://www.youtube.com/live/PE2Hmx_mIvM?t=4644&si=HKkrP0Xij3W779vN', 
        value: 'Executrix', counter: 'profundity', banners: 74,
        squad: ['executrix', 'scythe', 'gauntlet', 'emperor-shuttle'],
        defense: ['profundity', 'milf', 'rebel-ywing', 'outrider']
    },
    { 
        name: 'Chimaera', url: 'https://youtu.be/HggSqNq2FVc?t=940&si=bXucZ0D41FF9x2Ir', 
        value: 'chimaera', counter: 'leviathan', banners: 67,
        squad: ['chimaera', 'tie-advanced', 'scythe', 'tie-fighter'],
        defense: ['leviathan', 'b-28', 'sith-fighter', 'fury']
    },
    { 
        name: 'Profundity', url: 'https://youtu.be/xCYaAG8djbw?t=852&si=Acv7vcSXbvMjtmrj', 
        value: 'profundity', counter: 'leviathan', banners: 70,
        squad: ['profundity', 'milf', 'rebel-ywing', 'outrider'],
        defense: ['leviathan', 'b-28', 'sith-fighter', 'fury']
    },
    { 
        name: 'Executor', url: 'https://youtu.be/VFAg200P5FE?t=1150&si=3qJgQ_nBAyoHu6wx', 
        value: 'executor', counter: 'leviathan', banners: 65,
        squad: ['executor', 'ig-2000', 'xanadu-blood', 'rc'],
        defense: ['leviathan', 'b-28', 'sith-fighter', 'fury']
    },
    { 
        name: 'Chimaera', url: 'https://youtu.be/V9cMPA4sBqY?t=1578&si=Vn90dL5Yt9-OF8io', 
        value: 'chimaera', counter: 'executor', banners: 73,
        squad: ['chimaera', 'tie-advanced', 'scythe', 'tie-fighter'],
        defense: ['executor', 'ig-2000', 'razor-crest', 'xanadu-blood']
    },
    { 
        name: 'Executrix', url: 'https://youtu.be/mtN6IX94X20?t=1189', 
        value: 'executrix', counter: 'executor', banners: 68,
        squad: ['executrix', 'tie-advanced', 'scythe', 'tie-fighter'],
        defense: ['executor', 'ig-2000', 'razor-crest', 'xanadu-blood']
    },
    {
        name: 'Home One', url: 'https://youtu.be/ipWU0zN8URM?t=562',
        value: 'homeone', counter: 'homeone', banners: 75,
        squad: ['homeone', 'bistan-uwing', 'biggs-xwing', 'milf'],
        defense: ['homeone', 'bistan-uwing', 'biggs-xwing', 'milf']
    },
    {
        name: 'Profundity', url: 'https://youtu.be/ipWU0zN8URM?t=464',
        value: 'profundity', counter: 'homeone', banners: 68,
        squad: ['profundity', 'outrider', 'ghost', 'rebel-ywing'],
        defense: ['homeone', 'bistan-uwing', 'biggs-xwing', 'milf']
    },
    {
        name: 'Executor', url: 'https://youtu.be/ipWU0zN8URM?t=398',
        value: 'executor', counter: 'homeone', banners: 74,
        squad: ['executor', 'houndstooth', 'xanadu-blood', 'rc'],
        defense: ['homeone', 'bistan-uwing', 'biggs-xwing', 'milf']
    },
    {
        name: 'Raddus', url: 'https://youtu.be/oTu1J12LvsI?t=587',
        value: 'raddus', counter: 'finalizer', banners: 68,
        squad: ['raddus', 'poe-xwing', 'resistance-xwing', 'rey-falcon'],
        defense: ['finalizer', 'tie-echelon', 'tie-silencer', 'command-shuttle']
    },
    {
        name: 'Finalizer', url: 'https://youtu.be/oTu1J12LvsI?t=469',
        value: 'finalizer', counter: 'finalizer', banners: 68,
        squad: ['finalizer', 'tie-echelon', 'tie-silencer', 'command-shuttle'],
        defense: ['finalizer', 'tie-echelon', 'tie-silencer', 'command-shuttle']
    },
    {
        name: 'Profundity', url: 'https://youtu.be/oTu1J12LvsI?t=384',
        value: 'profundity', counter: 'finalizer', banners: 75,
        squad: ['profundity', 'ghost', 'rebel-ywing', 'outrider'],
        defense: ['finalizer', 'tie-echelon', 'tie-silencer', 'command-shuttle']
    },
    {
        name: 'Executor', url: 'https://youtu.be/oTu1J12LvsI?t=317',
        value: 'executor', counter: 'finalizer', banners: 71,
        squad: ['executor', 'houndstooth', 'razor-crest', 'xanadu-blood'],
        defense: ['finalizer', 'tie-echelon', 'tie-silencer', 'command-shuttle']
    },
    {
        name: 'Negotiator', url: 'https://youtu.be/oTu1J12LvsI?t=243',
        value: 'negotiator', counter: 'finalizer', banners: 69,
        squad: ['negotiator', 'rex-arc', 'ani-eta', 'btl-ywing'],
        defense: ['finalizer', 'tie-echelon', 'tie-silencer', 'command-shuttle']
    },
    { 
        name: 'Profundity', url: 'https://youtu.be/xTOM3SL2xDk?t=447', 
        value: 'Profundity', counter: 'profundity', banners: 67,
        squad: ['profundity', 'milf', 'rebel-ywing', 'outrider'],
        defense: ['profundity', 'milf', 'rebel-ywing', 'outrider']
    },
    { 
        name: 'Executrix', url: 'https://youtu.be/xTOM3SL2xDk?t=39', 
        value: 'Executrix', counter: 'profundity', banners: 65,
        squad: ['executrix', 'b-28', 'tie-advanced', 'emperor-shuttle'],
        defense: ['profundity', 'milf', 'rebel-ywing', 'outrider']
    },
    { 
        name: 'Finalizer', url: 'https://youtu.be/QEuXFNBz3-s?t=1553', 
        value: 'finalizer', counter: 'executor', banners: 54,
        squad: ['finalizer', 'tie-silencer', 'command-shuttle', 'tie-echelon'],
        defense: ['executor', 'ig-2000', 'razor-crest', 'xanadu-blood']
    },
    { 
        name: 'Profundity', url: 'https://youtu.be/QEuXFNBz3-s?t=1770', 
        value: 'profundity', counter: 'executor', banners: 72,
        squad: ['profundity', 'milf', 'outrider', 'rebel-ywing'],
        defense: ['executor', 'ig-2000', 'razor-crest', 'xanadu-blood']
    },
    {
        name: 'Malevolence', url: 'https://youtu.be/QEuXFNBz3-s?t=1101',
        value: 'malevolence', counter: 'executor', banners: 60,
        squad: ['malevolence', 'vulture', 'hyena', 'tie'],
        defense: ['executor', 'houndstooth', 'razor-crest', 'ig-2000']
    },
    {
        name: 'Home One', url: 'https://youtu.be/QEuXFNBz3-s?t=770',
        value: 'homeone', counter: 'executor', banners: 67,
        squad: ['homeone', 'milf', 'biggs-xwing', 'bistan-uwing'],
        defense: ['executor', 'houndstooth', 'razor-crest', 'ig-2000']
    },
    { 
        name: 'Negotiator', url: 'https://youtu.be/QEuXFNBz3-s?t=625', 
        value: 'negotiator', counter: 'executor', banners: 70,
        squad: ['negotiator', 'ahsoka-eta', 'ani-eta', 'btl-ywing'],
        defense: ['executor', 'houndstooth', 'razor-crest', 'ig-2000']
    },
    { 
        name: 'Finalizer', url: 'https://youtu.be/QEuXFNBz3-s?t=948', 
        value: 'finalizer', counter: 'executor', banners: 67,
        squad: ['finalizer', 'tie-silencer', 'command-shuttle', 'tie-echelon'],
        defense: ['executor', 'houndstooth', 'razor-crest', 'ig-2000']
    },
    { 
        name: 'Profundity', url: 'https://youtu.be/QEuXFNBz3-s?t=340', 
        value: 'profundity', counter: 'executor', banners: 72,
        squad: ['profundity', 'milf', 'outrider', 'rebel-ywing'],
        defense: ['executor', 'houndstooth', 'razor-crest', 'xanadu-blood']
    },
    { 
        name: 'Profundity', url: 'https://youtu.be/V9cMPA4sBqY?t=1732&si=d0rfZye0djcCY_CG', 
        value: 'profundity', counter: 'negotiator', banners: 75,
        squad: ['profundity', 'milf', 'rebel-ywing', 'outrider'],
        defense: ['negotiator', 'rex-arc', 'ani-eta', 'btl-ywing']
    },
    {
        name: 'Malevolence', url: 'https://youtu.be/tS74oCQGtZM?t=1038',
        value: 'malevolence', counter: 'malevolence', banners: 64,
        squad: ['malevolence', 'vulture', 'hyena', 'ship-sf'],
        defense: ['malevolence', 'hyena', 'ship-sf', 'vulture']
    },
    {
        name: 'Profundity', url: 'https://youtu.be/tS74oCQGtZM?t=660',
        value: 'profundity', counter: 'malevolence', banners: 75,
        squad: ['profundity', 'outrider', 'rebel-ywing', 'ghost'],
        defense: ['malevolence', 'vulture', 'hyena', 'ship-sf']
    },
    {
        name: 'Finalizer', url: 'https://youtu.be/tS74oCQGtZM?t=414',
        value: 'finalizer', counter: 'malevolence', banners: 68,
        squad: ['finalizer', 'tie-echelon', 'tie-silencer', 'command-shuttle'],
        defense: ['malevolence', 'vulture', 'hyena', 'ship-sf']
    },
    {
        name: 'Negotiator', url: 'https://youtu.be/tS74oCQGtZM?t=50',
        value: 'negotiator', counter: 'malevolence', banners: 72,
        squad: ['negotiator', 'btl-ywing', 'ani-eta', 'umbaran'],
        defense: ['malevolence', 'vulture', 'hyena', 'ship-sf']
    },
    { 
        name: 'Executor', url: 'https://youtu.be/zj-R1VzzAnU', 
        value: 'executor', counter: 'profundity', banners: 69,
        squad: ['executor', 'houndstooth', 'rc', 'xanadu-blood'],
        defense: ['profundity', 'milf', 'rebel-ywing', 'outrider']
    },
    { 
        name: 'Malevolence', url: 'https://youtu.be/VwAGPlAgXuo', 
        value: 'malevolence', counter: 'profundity', banners: 63,
        squad: ['malevolence', 'spy-starfighter', 'vulture', 'hyena'],
        defense: ['profundity', 'milf', 'rebel-ywing', 'outrider']
    },
    { 
        name: 'Home One', url: 'https://youtu.be/1O4_5tx7R88?t=1200', 
        value: 'homeone', counter: 'executrix', banners: 64,
        squad: ['homeone', 'milf', 'bistan-uwing', 'cassian-uwing'],
        defense: ['executrix', 'b-28', 'tie-bomber', 'tie-advanced']
    },
    {
        name: 'Chimaera', url: 'https://youtu.be/7vl0cWs_PS8?t=114',
        value: 'chimaera', counter: 'raddus', banners: 74,
        squad: ['chimaera', 'tie-advanced', 'tie-bomber', 'emperor-shuttle'],
        defense: ['raddus', 'starfortress', 'rey-falcon', 'resistance-xwing']
    },
    { 
        name: 'Chimaera', url: 'https://youtu.be/rK8Min21Hys?t=64', 
        value: 'chimaera', counter: 'negotiator', banners: 66,
        squad: ['chimaera', 'tie-fighter', 'tie-advanced', 'tie-bomber'],
        defense: ['negotiator', 'ahsoka-eta', 'ani-eta', 'btl-ywing']
    },
    {
        name: 'Negotiator', url: 'https://youtu.be/7vl0cWs_PS8?t=402',
        value: 'negotiator', counter: 'raddus', banners: 72,
        squad: ['negotiator', 'ani-eta', 'rex-arc', 'btl-ywing'],
        defense: ['raddus', 'starfortress', 'poe-xwing', 'rey-falcon']
    },
    {
        name: 'Profundity', url: 'https://youtu.be/7vl0cWs_PS8?t=531',
        value: 'profundity', counter: 'raddus', banners: 72,
        squad: ['profundity', 'outrider', 'ghost', 'rebel-ywing'],
        defense: ['raddus', 'starfortress', 'poe-xwing', 'rey-falcon']
    },
    {
        name: 'Executor', url: 'https://youtu.be/nVnxzYCa4tk?t=780',
        value: 'executor', counter: 'chimaera', banners: 73,
        squad: ['executor', 'houndstooth', 'razor-crest', 'xanadu-blood'],
        defense: ['chimaera', 'tie-advanced', 'tie-bomber', 'tie-fighter']
    },
    { 
        name: 'Home One', url: 'https://youtu.be/JvQTX4sNvqg?t=1370', 
        value: 'homeone', counter: 'negotiator', banners: 73,
        squad: ['homeone', 'bistan-uwing', 'biggs-xwing', 'milf'],
        defense: ['negotiator', 'rex-arc', 'ani-eta', 'btl-ywing']
    },
    { 
        name: 'Home One', url: 'https://youtu.be/oTu1J12LvsI?t=123', 
        value: 'homeone', counter: 'finalizer', banners: 75,
        squad: ['homeone', 'milf', 'bistan-uwing', 'biggs-xwing'],
        defense: ['finalizer', 'tie-echelon', 'tie-silencer', 'command-shuttle'],
    },
    {
        name: 'Raddus Mirror', url: 'https://youtu.be/7vl0cWs_PS8?t=602',
        value: 'raddus', counter: 'raddus', banners: 70,
        squad: ['raddus', 'rey-falcon', 'poe-xwing', 'resistance-xwing'],
        defense: ['raddus', 'starfortress', 'poe-xwing', 'rey-falcon']
    },
    { 
        name: 'Chimaera', url: 'https://youtu.be/tS74oCQGtZM?t=157', 
        value: 'chimaera', counter: 'malevolence', banners: 67,
        squad: ['chimaera', 'tie-advanced', 'tie-bomber', 'tie'],
        defense: ['malevolence', 'ship-sf', 'hyena', 'vulture']
    },
    { 
        name: 'Home One', url: 'https://youtu.be/tS74oCQGtZM?t=298', 
        value: 'homeone', counter: 'malevolence', banners: 63,
        squad: ['homeone', 'milf', 'bistan-uwing', 'biggs-xwing'],
        defense: ['malevolence', 'ship-sf', 'hyena', 'vulture']
    },
    { 
        name: 'Home One', url: 'https://youtu.be/nVnxzYCa4tk?t=629', 
        value: 'homeone', counter: 'chimaera', banners: 66,
        squad: ['homeone', 'milf', 'bistan-uwing', 'cassian-uwing'],
        defense: ['chimaera', 'tie-advanced', 'tie-bomber', 'tie-fighter']
    },
    {
        name: 'Malevolence', url: 'https://youtu.be/yfmK1MGAL24',
        value: 'malevolence', counter: 'executor', banners: 62,
        squad: ['malevolence', 'tie', 'hyena', 'vulture'],
        defense: ['executor', 'ig-2000', 'razor-crest', 'xanadu-blood']
    },
    {
        name: 'Home One', url: 'https://youtu.be/W4SO2UjR3To',
        value: 'homeone', counter: 'executor', banners: 65,
        squad: ['homeone', 'milf', 'biggs-xwing', 'bistan-uwing'],
        defense: ['executor', 'ig-2000', 'razor-crest', 'xanadu-blood']
    },
    {
        name: 'Home One', url: 'https://youtu.be/7vl0cWs_PS8?t=45',
        value: 'homeone', counter: 'raddus', banners: 73,
        squad: ['homeone', 'milf', 'biggs-xwing', 'bistan-uwing'],
        defense: ['raddus', 'starfortress', 'poe-xwing', 'rey-falcon']
    },
    { 
        name: 'Home One', url: 'https://youtu.be/rK8Min21Hys?t=221', 
        value: 'homeone', counter: 'negotiator', banners: 67,
        squad: ['homeone', 'bistan-uwing', 'biggs-xwing', 'milf'],
        defense: ['negotiator', 'ahsoka-eta', 'ani-eta', 'btl-ywing']
    },
    { 
        name: 'Finalizer', url: 'https://youtu.be/nVnxzYCa4tk?t=474', 
        value: 'finalizer', counter: 'chimaera', banners: 63,
        squad: ['finalizer', 'tie-silencer', 'command-shuttle', 'fo-sftf'],
        defense: ['chimaera', 'tie-advanced', 'tie-bomber', 'tie-fighter']
    },
    {
        name: 'Negotiator', url: 'https://youtu.be/CQnJugHAD0E?t=821',
        value: 'negotiator', counter: 'chimaera', banners: 73,
        squad: ['negotiator', 'btl-ywing', 'ani-eta', 'umbaran'],
        defense: ['chimaera', 'tie-advanced', 'tie-bomber', 'b-28']
    },
    {
        name: 'Raddus', url: 'https://youtu.be/nVnxzYCa4tk?t=1024',
        value: 'raddus', counter: 'chimaera', banners: 58,
        squad: ['raddus', 'poe-xwing', 'rey-falcon', 'ahsoka-eta'],
        defense: ['chimaera', 'tie-advanced', 'tie-bomber', 'tie-fighter']
    },
    {
        name: 'Negotiator', url: 'https://youtu.be/nVnxzYCa4tk?t=319',
        value: 'negotiator', counter: 'chimaera', banners: 62,
        squad: ['negotiator', 'umbaran', 'ani-eta', 'ahsoka-eta'],
        defense: ['chimaera', 'tie-advanced', 'tie-bomber', 'tie-fighter']
    },
    {
        name: 'Finalizer', url: 'https://youtu.be/7vl0cWs_PS8?t=216',
        value: 'finalizer', counter: 'raddus', banners: 70,
        squad: ['finalizer', 'tie-echelon', 'tie-silencer', 'command-shuttle'],
        defense: ['raddus', 'rey-falcon', 'poe-xwing', 'starfortress']
    },
    {
        name: 'Malevolence', url: 'https://youtu.be/CQnJugHAD0E?t=406',
        value: 'malevolence', counter: 'raddus', banners: 73,
        squad: ['malevolence', 'vulture', 'hyena', 'ship-sf'],
        defense: ['raddus', 'starfortress', 'poe-xwing', 'rey-falcon']
    },
    { 
        name: 'Finalizer', url: 'https://youtu.be/R5Lb8rEocrM', 
        value: 'finalizer', counter: 'executor', banners: 68,
        squad: ['finalizer', 'tie-silencer', 'command-shuttle', 'tie-echelon'],
        defense: ['executor', 'houndstooth', 'razor-crest', 'xanadu-blood']
    },
    { 
        name: 'Finalizer', url: 'https://youtu.be/B_iT8zSjWWA?t=1092', 
        value: 'finalizer', counter: 'executrix', banners: 72,
        squad: ['finalizer', 'tie-silencer', 'command-shuttle', 'tie-echelon'],
        defense: ['executrix', 'b-28', 'tie-bomber', 'tie-advanced']
    },
    { 
        name: 'Finalizer', url: 'https://youtu.be/rK8Min21Hys?t=470', 
        value: 'finalizer', counter: 'negotiator', banners: 62,
        squad: ['finalizer', 'tie-silencer', 'command-shuttle', 'fo-sftf'],
        defense: ['negotiator', 'ahsoka-eta', 'ani-eta', 'btl-ywing']
    },
    {
        name: 'Executor', url: 'https://youtu.be/CWmyLyRBOjg?t=1323',
        value: 'executor', counter: 'negotiator', banners: 73,
        squad: ['executor', 'houndstooth', 'razor-crest', 'xanadu-blood'],
        defense: ['negotiator', 'ahsoka-eta', 'ani-eta', 'btl-ywing']
    },
    { 
        name: 'Executor Mirror', id: 'ZauH5W_4ero', 
        value: 'executor', counter: 'executor', banners: 68,
        squad: ['executor', 'houndstooth', 'razor-crest', 'xanadu-blood'],
        defense: ['executor', 'houndstooth', 'razor-crest', 'xanadu-blood']
    },
    {
        name: 'Executor', url: 'https://youtu.be/tS74oCQGtZM?t=553',
        value: 'executor', counter: 'malevolence', banners: 72,
        squad: ['executor', 'houndstooth', 'razor-crest', 'xanadu-blood'],
        defense: ['malevolence', 'ship-sf', 'hyena', 'vulture']
    },
    {
        name: 'Malevolence', id: 'WvFNuHkYaKU',
        value: 'malevolence', counter: 'executor', banners: 57,
        squad: ['malevolence', 'tie', 'hyena', 'vulture'],
        defense: ['executor', 'houndstooth', 'razor-crest', 'xanadu-blood']
    },
    {
        name: 'Malevolence', url: 'https://youtu.be/oTu1J12LvsI?t=167',
        value: 'malevolence', counter: 'finalizer', banners: 71,
        squad: ['malevolence', 'vulture', 'hyena', 'ship-sf'],
        defense: ['finalizer', 'tie-echelon', 'tie-silencer', 'command-shuttle']
    },
    {
        name: 'Malevolence', url: 'https://youtu.be/nVnxzYCa4tk?t=96',
        value: 'malevolence', counter: 'chimaera', banners: 67,
        squad: ['malevolence', 'geo-starfighter', 'spy-starfighter', 'ship-sf'],
        defense: ['chimaera', 'tie-advanced', 'tie-bomber', 'tie']
    },
    {
        name: 'Malevolence', url: 'https://youtu.be/nVnxzYCa4tk?t=210',
        value: 'malevolence', counter: 'chimaera', banners: 63,
        squad: ['malevolence', 'vulture', 'hyena', 'ship-sf'],
        defense: ['chimaera', 'tie-advanced', 'tie-bomber', 'tie']
    },
    {
        name: 'Malevolence', url: 'https://youtu.be/ipWU0zN8URM?t=337',
        value: 'malevolence', counter: 'homeone', banners: 69,
        squad: ['malevolence', 'vulture', 'hyena', 'ship-sf'],
        defense: ['homeone', 'bistan-uwing', 'biggs-xwing', 'milf']
    },
    {
        name: 'Malevolence', id: 'UP9cYjBwSmc',
        value: 'malevolence', counter: 'executrix', banners: 66,
        squad: ['malevolence', 'vulture', 'hyena', 'ship-sf'],
        defense: ['executrix', 'gauntlet', 'tie-bomber', 'tie']
    },
    {
        name: 'IG-2000 Reinforcement', url: 'https://youtu.be/rK8Min21Hys?t=337',
        value: 'malevolence', counter: 'negotiator', banners: 63,
        squad: ['malevolence', 'vulture', 'hyena', 'ship-sf'],
        defense: ['negotiator', 'btl-ywing', 'ani-eta', 'ahsoka-eta']
    },
    {
        name: 'Finalizer', id: 'IZRAM7jnn4E',
        value: 'finalizer', counter: 'executrix', banners: 67,
        squad: ['finalizer', 'fo-sftf', 'tie-silencer', 'command-shuttle'],
        defense: ['executrix', 'tie-advanced', 'tie-bomber', 'b-28']
    },
    {
        name: 'Finalizer', url: 'https://youtu.be/ipWU0zN8URM?t=80',
        value: 'finalizer', counter: 'homeone', banners: 70,
        squad: ['finalizer', 'tie-echelon', 'tie-silencer', 'command-shuttle'],
        defense: ['homeone', 'bistan-uwing', 'biggs-xwing', 'milf']
    },
    {
        name: 'Finalizer', url: 'https://youtu.be/EkIh5ly1A_Q?t=806',
        value: 'finalizer', counter: 'homeone', banners: 70,
        squad: ['finalizer', 'tie-echelon', 'tie-silencer', 'command-shuttle'],
        defense: ['homeone', 'bistan-uwing', 'biggs-xwing', 'raven-claw']
    },
    {
        name: 'Raddus Guide', url: 'https://youtu.be/rK8Min21Hys?t=1074',
        value: 'raddus', counter: 'negotiator', banners: 64,
        squad: ['raddus', 'starfortress', 'poe-xwing', 'tie'],
        defense: ['negotiator', 'btl-ywing', 'ani-eta', 'ahsoka-eta']
    },
    {
        name: 'Raddus', id: '0i4U6ZtWUrg',
        value: 'raddus', counter: 'executrix', banners: 71,
        squad: ['raddus', 'rey-falcon', 'poe-xwing', 'resistance-xwing'],
        defense: ['executrix', 'tie-advanced', 'tie-bomber', 'tie']
    },
    {
        name: 'Chimaera', id: '0xdy2vODWrY',
        value: 'chimaera', counter: 'finalizer', banners: 72,
        squad: ['chimaera', 'tie-advanced', 'tie-bomber', 'b-28'],
        defense: ['finalizer', 'fo-sftf', 'tie-silencer', 'command-shuttle']
    },
    {
        name: 'Negotiator', url: 'https://youtu.be/8nEtmtcd4cw?t=1561',
        value: 'negotiator', counter: 'executrix', banners: 73,
        squad: ['negotiator', 'btl-ywing', 'ani-eta', 'ahsoka-eta'],
        defense: ['executrix', 'gauntlet', 'tie-bomber', 'tie-advanced']
    },
    {
        name: 'Negotiator', url: 'https://youtu.be/ipWU0zN8URM?t=279',
        value: 'negotiator', counter: 'homeone', banners: 74,
        squad: ['negotiator', 'btl-ywing', 'ani-eta', 'umbaran'],
        defense: ['homeone', 'bistan-uwing', 'biggs-xwing', 'milf']
    },
    {
        name: 'Negotiator Mirror with Tie Fighter', url: 'https://youtu.be/tqdSpj44hF8?t=2004',
        value: 'negotiator', counter: 'negotiator', banners: 69,
        squad: ['negotiator', 'btl-ywing', 'ani-eta', 'tie-fighter'],
        defense: ['negotiator', 'btl-ywing', 'ani-eta', 'ahsoka-eta']
    }
];

export {
    negotiator,
    malevolence,
    finalizer,
    executor,
    raddus,
    counters,
    homeOne,
    executrix,
    chimaera,
    profundity,
    leviathan
}