const day13 = [
  {
    title: `Sector 2 - Datacron Farming`,
    teams: [
      {
        id: 1301,
        name: '2x',
        videoThumb: 'CgX6JpCIIdQ?t=476&si=qlWFMFVuTNm32cpa',
        global: [
          { name: 'Dark Gideon' },
        ],
        feats: [
          { name: 'Imperial Troopers', qty: 5 },
        ],
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon' },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'stormtrooper', name: 'Stormtrooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'gideon', name: 'Gideon' }
        ]
      },
    ]
  },
  {
    title: 'Sector 2 - Progress',
    progress: [
      { name: 'Potency Down', complete: true },
      { name: 'Rebel Fighters', complete: true },
      { name: 'Imperial Troopers', complete: true },
      { name: 'Nightsisters', complete: true },
    ]
  },


  {
    title: `Sector 4 - Datacron Farming`,
    teams: [
      {
        id: 1302,
        name: '2x',
        videoThumb: 'rlb_tlMtOlE?t=612&si=7nDQE0RBfIVKIiad',
        feats: [
          { name: 'Shield Up', qty: 4 },
          { name: 'Accuracy Down', qty: 20 },
        ],
        toons: [          
          { image: 'dash', name: 'Dash'  },
          { image: 'phalanx', name: 'Phalanx', main: true },
          { image: 'tarpals', name: 'Tarpals' },
          { image: 'thrawn', name: 'Thrawn' },
          { image: 'cad', name: 'Cad' },
        ]
      },
      {
        id: 1303,
        name: '2x',
        videoThumb: '',
        feats: [
          { name: 'Rebels', qty: 1 },
        ],
        global: [
          { name: 'Captain Rex' }
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'chewie', name: 'Chewie' },
          { image: 'han', name: 'Han' },
          { image: 'threepio', name: 'Threepio' },
          { image: 'captain-rex', name: 'Captain Rex' }
        ]
      },
    ]
  },
  {
    title: 'Sector 4 - Progress',
    progress: [
      { name: 'Accuracy Down', complete: true },
      { name: 'STAP', complete: true },
      { name: 'Shield Up', current: 50, target: 50 },
      { name: 'Rebels', current: 10, target: 10 }
    ]
  },


  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Jedi Cal', skip: true },
      { name: 'Master & Padawn', complete: true },
      { name: 'Captain Rex', complete: true },
      { name: 'Challenge Path', complete: true },
      { name: 'Gungans', complete: true },
      { name: 'Dark Gideon', current: 35, target: 35 },
    ]
  }
];

export {
  day13
}