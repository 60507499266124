const day14 = [
  {
    title: `Sector 3 - Datacron Farming`,
    teams: [
      {
        id: 1401,
        name: '2x',
        videoThumb: '',
        feats: [
          { name: 'Phoenix', qty: 5 },
          { name: 'Qui-Gons', qty: 1 },
        ],
        global: [
          { name: 'Captain Rex' },
          { name: 'Master & Padawn' }
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'qgj', name: 'QGJ' },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
          { image: 'captain-rex', name: 'Captain Rex', main: true }
        ]
      },
      {
        id: 1402,
        name: '2x',
        videoThumb: '',
        feats: [
          { name: 'Bounty Hunters', qty: 5 },
          { name: 'Evasion Down', qty: 5 },
        ],
        toons: [
          { image: 'bossk', name: 'Bossk' },
          { image: 'zam', name: 'Zam', main: true  },
          { image: 'greef', name: 'Greef'},
          { image: 'mando', name: 'Mandalorian' },
          { image: 'bam', name: 'Beskar Mando' }
        ]
      },
    ]
  },
  {
    title: 'Sector 5 - Progress',
    progress: [
      { name: 'Evasion Down', complete: true },
      { name: 'Phoenix', complete: true },
      { name: 'Qui-Gons', current: 10, target: 10 },
      { name: 'Bounty Hunters', current: 35, target: 35 }
    ]
  },



  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Jedi Cal', skip: true },
      { name: 'Master & Padawn', complete: true },
      { name: 'Captain Rex', complete: true },
      { name: 'Challenge Path', complete: true },
      { name: 'Gungans', complete: true },
      { name: 'Dark Gideon', complete: true },
    ]
  }
];

export {
  day14
}