import React, { useEffect, useState } from 'react';

import Page from '../../components/page';
import SubMenu from '../../components/submenu';
import DatacronList from './list';
import { set15Data } from './data/set15-units'
import { set16Data } from './data/set16-units'
import './_style.scss';

const menuLinks = [
  { label: 'Set 16 - Unexpected Resurgence' },
  { label: 'Set 15 - Together As One' },
];

const Datacrons = () => {
  const [activeTab, selectTab] = useState(0);

  const setList = (id) => {
    selectTab(id)
  }

  useEffect(() => {
    document.title = 'Datacron Guide and Farming List - SWGOH-4 Life';
  });

  const intro = <div className="row">
    <div className="col-12">
      <p>The default datacron is orderedy by priority of farming. You can hide datacrons or reorder them based on your roster. For easier tracking of your farming and rolling progress, you can also individually mark datacron level bonuses complete by clicking on them.</p>
      <div className='sw-dc-meta mb-0'>
        <div className='sw-dc-meta__important'>Key Bonus</div>
        <div className='sw-dc-meta__drag'>
          <svg width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
            <path d='M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0' />
          </svg>
          <div>Drag to Reorder</div>
        </div>
      </div>
    </div>
  </div>;

  return (
    <Page title="Datacron Farming List" intro={intro}>
      <SubMenu links={menuLinks} active={activeTab} onClick={setList} theme='light' breakpoint={425} />
      <DatacronList dataZero={set16Data} dataOne={set15Data} activeTab={activeTab} />
    </Page>
  );
}

export default Datacrons;
