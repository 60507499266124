import React, { useState, useCallback, useEffect } from 'react';
import update from 'immutability-helper';

import DatacronItem from './item';
import DatacronAccounts from './accounts';

const DatacronList = (props) => {
  const { dataZero, dataOne, activeTab } = props
  const [list, setList] = useState([...dataZero]);
  const [loadedAccount, setLoadedAccount] = useState('Default');
  const [loadedAccountString, setLoadedAccountString] = useState('');
  const [hideCount, setHideCount] = useState(0);
  const [showAccounts, toggleAccounts] = useState(false);

  const moveItem = useCallback((dragIndex, hoverIndex) => {
    const updateList = (prevItems) => update(prevItems, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, prevItems[dragIndex]]
      ]
    })
    setList((prevItems) => {
      if (activeTab === 0) {
        localStorage.setItem(`dcFarmingList15${loadedAccountString}`, JSON.stringify(updateList(prevItems)))
      } else {
        localStorage.setItem(`dcFarmingList15${loadedAccountString}`, JSON.stringify(updateList(prevItems)))
      }
      return updateList(prevItems)
    })
  }, [activeTab, loadedAccountString])

  const renderItem = useCallback((item, index) => {

    const onUpdateList = (id, type, bonus) => {
      let updateList = []
      list.forEach(l => {
        if (l.order === id) {
          let setValue = true
          let updateItem = {}

          if (type === 'complete') {
            const findBonus = l[bonus]
            const allLevels = [l.l3, l.l6, l.l9]
            let updateAllLevels = []
            allLevels.forEach(a => {
              if (a) {
                if (a.id === findBonus.id) {
                  updateAllLevels.push({ ...a, complete: !a.complete })
                } else {
                  updateAllLevels.push(a)
                }
              }
            })
            const allLevelsCleaned = updateAllLevels.filter(f => f !== undefined)
            const allLevelsComplete = allLevelsCleaned.map(f => f.complete)
            const completeAll = allLevelsComplete.every(x => x)
            updateItem = {
              ...l,
              complete: completeAll,
              [bonus]: {
                ...findBonus,
                complete: !findBonus.complete
              }
            }
          } else {
            updateItem = {
              ...l,
              [type]: setValue
            }
          }
          updateList.push(updateItem)
        } else {
          updateList.push(l)
        }
      })
      setList(updateList)

      if (type === 'hide') {
        const getHideCount = updateList.filter(h => h.hide === true)
        setHideCount(getHideCount.length)
      }

      if (activeTab === 0) {
        localStorage.setItem(`dcFarmingList16${loadedAccountString}`, JSON.stringify(updateList))
      } else {
        localStorage.setItem(`dcFarmingList15${loadedAccountString}`, JSON.stringify(updateList))
      }
    }

    const onComplete = (id, bonus) => {
      onUpdateList(id, 'complete', bonus)
    }

    const onHide = (id) => {
      onUpdateList(id, 'hide')
    }

    return (
      <DatacronItem
        key={`${item.order}${item.unit}`}
        id={item.order}
        index={index}
        unit={item.unit}
        l3={item.l3}
        l6={item.l6}
        l9={item.l9}
        hide={item.hide}
        complete={item.complete}
        stats={item.stats}
        moveItem={moveItem}
        onComplete={onComplete}
        onHide={onHide}
        activeTab={activeTab}
      />
    )
  }, [moveItem, list, activeTab, loadedAccountString])

  const onUnhideAll = () => {
    const updateList = list.map(l => { return { ...l, hide: false } })
    setList(updateList)
    setHideCount(0)
    if (activeTab === 0) {
      localStorage.setItem(`dcFarmingList16${loadedAccountString}`, JSON.stringify(updateList))
    } else {
      localStorage.setItem(`dcFarmingList15${loadedAccountString}`, JSON.stringify(updateList))
    }
  }

  const onAccountLoad = (name) => {
    setLoadedAccount(name)
    localStorage.setItem('dcFarmingListLoadedAccount', name)
    toggleAccounts(false)
    const loadedListString = name === 'Default' ? '' : name
    setLoadedAccountString(loadedListString)

    if (activeTab === 0) {
      const getList = localStorage.getItem(`dcFarmingList16${loadedListString}`)
      if (getList && getList !== 'undefined') {
        setList(JSON.parse(getList))
        const getHideCount = JSON.parse(getList).filter(h => h.hide === true)
        setHideCount(getHideCount.length)
      } else {
        setHideCount(0)
        setList(dataZero)
      }
    } else {
      const getList = localStorage.getItem(`dcFarmingList15${loadedListString}`)
      if (getList && getList !== 'undefined') {
        setList(JSON.parse(getList))
        const getHideCount = JSON.parse(getList).filter(h => h.hide === true)
        setHideCount(getHideCount.length)
      } else {
        setHideCount(0)
        setList(dataOne)
      }
    }
  }

  useEffect(() => {
    const getLoadedList = localStorage.getItem('dcFarmingListLoadedAccount')
    let loadedListString = ''
    if (getLoadedList && getLoadedList !== 'undefined') {
      setLoadedAccount(getLoadedList)
      loadedListString = getLoadedList === 'Default' ? '' : getLoadedList
      setLoadedAccountString(loadedListString)
    }

    if (activeTab === 0) {
      const getList = localStorage.getItem(`dcFarmingList16${loadedListString}`)
      if (getList && getList !== 'undefined') {
        setList(JSON.parse(getList))
        const getHideCount = JSON.parse(getList).filter(h => h.hide === true)
        setHideCount(getHideCount.length)
      } else {
        setHideCount(0)
        setList(dataZero)
      }
    } else {
      const getList = localStorage.getItem(`dcFarmingList15${loadedListString}`)
      if (getList && getList !== 'undefined') {
        setList(JSON.parse(getList))
        const getHideCount = JSON.parse(getList).filter(h => h.hide === true)
        setHideCount(getHideCount.length)
      } else {
        setHideCount(0)
        setList(dataOne)
      }
    }
  }, [activeTab, dataOne, dataZero])

  return (
    <>
      <div className='sw-dc-accounts'>
        <div className='sw-dc-accounts__name lead text-uppercase font-weight-bold'>{loadedAccount && loadedAccount} Account</div>
        <div className='sw-dc-accounts__actions'>
          <button className='btn btn-sm btn-info px-3 mr-2' onClick={() => toggleAccounts(true)}>Manage Accounts</button>
          <button className='btn btn-sm btn-info px-3' onClick={onUnhideAll} disabled={hideCount === 0}>Unhide Datacrons {hideCount > 0 && `(${hideCount})`}</button>
        </div>
      </div>
      <div className='sw-dc-list'>
        {list.length > 0 && list.map((l, i) => renderItem(l, i))}
      </div>

      {showAccounts && <DatacronAccounts onAccountLoad={onAccountLoad} loadedAccount={loadedAccount} onClose={() => toggleAccounts(false)} />}
    </>
  );
}

export default DatacronList;