const day4 = [
  {
    title: `Sector 2 - Battles`,
    teams: [
      {
        id: 401,
        name: '2x',
        videoThumb: '-9XDYTjLfq4?t=71&si=dE6hBZ9ndUHmusiv',
        feats: [
          { name: 'Rebel Fighters', qty: 5 },
        ],
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [
          { image: 'mothma', name: 'Mothma' },
          { image: 'kyle', name: 'Kyle' },
          { image: 'biggs', name: 'Biggs' },
          { image: 'pao', name: 'Pao'},
          { image: 'captain-rex', name: 'Captain Rex' },
        ]
      },
      {
        id: 402,
        name: '2x',
        videoThumb: '-9XDYTjLfq4?si=lO1rXhWNpM23QngU&t=220',
        feats: [
          { name: 'Imperial Troopers', qty: 5 },
        ],
        toons: [
          { image: 'veers', name: 'Veers' },
          { image: 'gideon', name: 'Gideon' },
          { image: 'starck', name: 'Starck' },
          { image: 'piett', name: 'Piett' },
          { image: 'range', name: 'Range Trooper' },
        ]
      },
      {
        id: 403,
        name: '4x',
        videoThumb: '-9XDYTjLfq4?si=sEEbLidhCHwQwmeF&t=282',
        feats: [
          { name: 'Nightsisters', qty: 1 },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'merrin', name: 'Merrin' },
          { image: 'daka', name: 'Daka' },
          { image: 'asajj', name: 'Asajj' },
          { image: 'zombie', name: 'Zombie' }
        ]
      },
      {
        id: 404,
        name: '2x',
        videoThumb: '-9XDYTjLfq4?t=327&si=vpEilQtkZTeRt8bO',
        feats: [
          { name: 'Potency Down', qty: 10 },
        ],
        global: [
          { name: 'Master & Padawan' },
        ],
        toons: [          
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'gmy', name: 'Master Yoda', main: true },
          { image: 'jolee', name: 'Jolee' },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
        ]
      },
    ]
  },
  {
    title: `Sector 2 - Final Boss`,
    boss: 'bossk',
    teams: [
      {
        id: 405,
        videoThumb: 'otpaqFu5xSo?t=354&si=mBwAXrVVzwe_VHHK',
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [          
          { image: 'dash', name: 'Dash' },
          { image: 'saw', name: 'Saw', main: true },
          { image: 'wat', name: 'Wat' },
          { image: 'admiralraddus', name: 'Raddus'},
          { image: 'captain-rex', name: 'Captain Rex' }
        ]
      },
      {
        id: 406,
        name: 'Empire',
        videoThumb: 'otpaqFu5xSo?t=515&si=0ug4ZDOHpf8VBYDN',
        feats: [
          { name: 'Imperial Troopers', qty: 5 },
        ],
        toons: [
          { image: 'veers', name: 'Veers' },
          { image: 'gideon', name: 'Gideon' },
          { image: 'starck', name: 'Starck' },
          { image: 'piett', name: 'Piett' },
          { image: 'range', name: 'Range Trooper' },
        ]
      }
    ]
  },
  {
    title: 'Sector 2 - Progress',
    progress: [      
      { name: 'Rebel Fighters', current: 25, target: 35 },
      { name: 'Imperial Troopers', current: 25, target: 35 },
      { name: 'Nightsisters', current: 8, target: 10 },
      { name: 'Potency Down', current: 35, target: 35 },
    ]
  },




  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Jedi Cal', skip: true },
      { name: 'Dark Gideon', skip: true },
      { name: 'Challenge Path', current: 100, target: 250 },      
      { name: 'Captain Rex', current: 8, target: 15 },
      { name: 'Gungans', current: 4, target: 15 },
      { name: 'Master & Padawn', current: 8, target: 15 },
    ]
  }
];

export {
  day4
}