export const set16Data = [  
  {
    id: 'sabine-ls-buffs',
    unit: 'hera',
    order: 0,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-buffs'
    },
    l6: {
      id: 'attacker-damage'
    },
    l9: {
      id: 'unit-sabine',
      important: true
    },
    stats: ['Defense', 'Crit Damage']
  },
  {
    id: 'mando-maul-ds-tm',
    unit: 'mando-maul',
    order: 1,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-tm'
    },
    l6: {
      id: 'tank-reduction'
    },
    l9: {
      id: 'unit-gar',
      important: true
    },
    stats: ['Physical Damage', 'Health']
  },
  {
    id: 'nihilus-ds-revive',
    unit: 'traya',
    order: 2,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-revive'
    },
    l6: {
      id: 'support-block'
    },
    l9: {
      id: 'unit-nihilus',
      important: true
    },
    stats: ['Protection', 'Defense']
  },
  {
    id: 'consular-ls-buffs',
    unit: 'amidala',
    order: 3,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-buffs'
    },
    l6: {
      id: 'healer-cooldowns'
    },
    l9: {
      id: 'unit-consular',
      important: true
    },
    stats: ['Health', 'Protection']
  },
  {
    id: 'visas',
    unit: 'palpatine',
    order: 4,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-buffs'
    },
    l6: {
      id: 'healer-cooldowns'
    },
    l9: {
      id: 'unit-visas',
      important: true
    },
    stats: ['Physical Damage', 'Crit Damage']
  },
  {
    id: 'barriss-ls-buffs',
    unit: 'qgj',
    order: 5,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-buffs'
    },
    l6: {
      id: 'healer-cooldowns'
    },
    l9: {
      id: 'unit-barriss',
      important: true
    },
    stats: ['Physical Damage', 'Crit Damage']
  },
  {
    id: 'wampa-ds-dots',
    unit: 'wampa',
    order: 6,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-dots'
    },
    l6: {
      id: 'attacker-damage'
    },
    l9: {
      id: 'unit-wampa',
      important: true
    },
    stats: ['Health', 'Crit Damage']
  },
  {
    id: 'bossk-ds-revive',
    unit: 'bossk',
    order: 7,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-revive'
    },
    l6: {
      id: 'tank-reduction'
    },
    l9: {
      id: 'unit-bossk',
      important: true
    },
    stats: ['Special Damage', 'Defense']
  },
  {
    id: 'trip-ds-tm',
    unit: 'aphra',
    order: 8,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-prot-up'
    },
    l6: {
      id: 'support-block'
    },
    l9: {
      id: 'unit-trip',
      important: true
    },
    stats: ['Crit Damage', 'Physical Damage']
  },
  {
    id: 'nest-ls-buffs',
    unit: 'nest',
    order: 9,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-buffs'
    },
    l6: {
      id: 'attacker-damage'
    },
    l9: {
      id: 'unit-nest',
      important: true
    },
    stats: ['Health', 'Crit Damage']
  },
  {
    id: 'wat-ds-tm',
    unit: 'trench',
    order: 10,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-tm'
    },
    l6: {
      id: 'support-tm'
    },
    l9: {
      id: 'unit-wat',
      important: true
    },
    stats: ['Defense', 'Crit Damage']
  },
  {
    id: 'iden-ds-tm',
    unit: 'iden',
    order: 11,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-tm'
    },
    l6: {
      id: 'attacker-damage'
    },
    l9: {
      id: 'unit-iden',
      important: true
    },
    stats: ['Protection', 'Defense']
  },
  {
    id: 'sth-ls-buffs',
    unit: 'sana',
    order: 12,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-buffs'
    },
    l6: {
      id: 'tank-reduction',
      important: true
    },
    l9: {
      id: 'unit-sth',
      important: true
    },
    stats: ['Defense', 'Protection']
  },
  {
    id: 'vandor-chewie-ls-buffs',
    unit: 'dash',
    order: 13,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-buffs'
    },
    l6: {
      id: 'tank-reduction',
      important: true
    },
    l9: {
      id: 'unit-vandor-chewie',
      important: true
    },
    stats: ['Health', 'Crit Damage']
  },
  {
    id: 'gamorrean-ds-revive',
    unit: 'see',
    order: 14,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-revive'
    },
    l6: {
      id: 'tank-reduction'
    },
    l9: {
      id: 'unit-gamorrean',
      important: true
    },
    stats: ['Health', 'Defense']
  },
  {
    id: 'rey-ls-buffs',
    unit: 'rey',
    order: 15,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-buffs'
    },
    l6: {
      id: 'attacker-damage'
    },
    stats: ['Physical Damage', 'Health']
  },
  {
    id: 'malgus-ds-revive',
    unit: 'malgus',
    order: 16,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-revive',
      important: true
    },
    l6: {
      id: 'tank-reduction'
    },
    stats: ['Health', 'Defense']
  },
  {
    id: 'bane-ds-tm',
    unit: 'bane',
    order: 17,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-tm'
    },
    l6: {
      id: 'attacker-damage',
      important: true
    },
    stats: ['Health', 'Crit Damage']
  },
  {
    id: 'jmk-ls-buffs',
    unit: 'jmk',
    order: 18,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-buffs'
    },
    l6: {
      id: 'tank-reduction',
      important: true
    },
    stats: ['Health', 'Crit Damage']
  },
  {
    id: 'gas-ls-buffs',
    unit: 'gas',
    order: 19,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-buffs'
    },
    l6: {
      id: 'attacker-damage',
      important: true
    },
    stats: ['Protection', 'Defense']
  },
  {
    id: 'lv-ds-revive',
    unit: 'lv',
    order: 20,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-revive'
    },
    l6: {
      id: 'attacker-damage',
      important: true
    },
    stats: ['Protection', 'Defense']
  },
  {
    id: 'gg-ds-revive',
    unit: 'gg',
    order: 21,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-revive'
    },
    l6: {
      id: 'tank-reduction',
      important: true
    },
    stats: ['Health', 'Crit Damage']
  },
  {
    id: 'finn-ls-buffs',
    unit: 'finn',
    order: 22,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-buffs'
    },
    l6: {
      id: 'tank-grit',
      important: true
    },
    stats: ['Defense', 'Protection']
  },
  {
    id: 'raddus-ls-buffs',
    unit: 'admiralraddus',
    order: 23,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-buffs'
    },
    l6: {
      id: 'tank-reduction',
      important: true
    },
    stats: ['Defense', 'Protection']
  },
  {
    id: 'jabba-ls-buffs',
    unit: 'jabba',
    order: 24,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-buffs'
    },
    l6: {
      id: 'tank-grit',
      important: true
    },
    stats: ['Defense', 'Protection']
  },
  {
    id: 'leia-organa-ls-buffs',
    unit: 'leia-organa',
    order: 25,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-buffs',
      important: true
    },
    l6: {
      id: 'attacker-damage'
    },
    stats: ['Crit Damage', 'Defense']
  },
  {
    id: 'bo-mandalor-ls-buffs',
    unit: 'bo-mandalor',
    order: 26,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-buffs',
      important: true
    },
    l6: {
      id: 'attacker-damage'
    },
    stats: ['Crit Damage', 'Defense']
  },
  {
    id: 'dark-gideon-ds-tm',
    unit: 'dt-gideon',
    order: 27,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-tm'
    },
    l6: {
      id: 'tank-grit',
      important: true
    },
    stats: ['Crit Damage', 'Defense']
  },
  {
    id: 'cere-ds-tm',
    unit: 'cere',
    order: 28,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-tm'
    },
    l6: {
      id: 'attacker-damage',
      important: true
    },
    stats: ['Crit Damage', 'Defense']
  },
  {
    id: 'talzin-tm',
    unit: 'talzin',
    order: 29,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-tm'
    },
    l6: {
      id: 'support-tenacity',
      important: true
    },
    stats: ['Crit Damage', 'Physical Damage']
  },
]