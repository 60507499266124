const day11 = [

  {
    title: `Sector 1 - Datacron Farming`,
    teams: [
      {
        id: 1101,
        name: '2x',
        videoThumb: 'rlb_tlMtOlE?t=801&si=4zu5ZVV5BZHAxiSt',
        global: [
          { name: 'Gungans' },
        ],
        toons: [
          { image: 'dash', name: 'Dash' },
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx' },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
      {
        id: 1102,
        name: '2x',
        videoThumb: 'rlb_tlMtOlE?t=817&si=MpVvUbXsbE9tfPqB',
        global: [
          { name: 'Master & Padawan' },
        ],
        toons: [
          { image: 'jkl', name: 'Jedi Luke'  },
          { image: 'jolee', name: 'Jolee' },
          { image: 'master-qgj', name: 'Master QGJ', main: true },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan', main: true },
          { image: 'shaak-ti', name: 'Shaak Ti' },
        ]
      },
    ]
  },
  {
    title: 'Sector 1 - Progress',
    progress: [
      { name: 'Mandalorians', skip: true },
      { name: 'Ewoks', skip: true },
      { name: 'Evasion Up', complete: true },
      { name: 'Resistance', complete: true }
    ]
  },
  {
    title: `Sector 2 - Battles`,
    teams: [
      {
        id: 1103,
        name: '2x',
        videoThumb: 'rlb_tlMtOlE?t=244&si=RZT9xXg-OOBABf7l',
        feats: [
          { name: 'Rebel Fighters', qty: 5 },
        ],
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [
          { image: 'mothma', name: 'Mothma' },
          { image: 'kyle', name: 'Kyle' },
          { image: 'biggs', name: 'Biggs' },
          { image: 'pao', name: 'Pao'},
          { image: 'captain-rex', name: 'Captain Rex' },
        ]
      },
    ]
  },
  {
    title: 'Sector 2 - Progress',
    progress: [      
      { name: 'Potency Down', complete: true },
      { name: 'Rebel Fighters', current: 35, target: 35 },
      { name: 'Imperial Troopers', current: 25, target: 35 },
      { name: 'Nightsisters', current: 8, target: 10 },
    ]
  },


  
  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Jedi Cal', skip: true },
      { name: 'Dark Gideon', skip: true },    
      { name: 'Captain Rex', complete: true },
      { name: 'Challenge Path', complete: true },  
      { name: 'Gungans', current: 6, target: 15 },
      { name: 'Master & Padawn', current: 10, target: 15 },
    ]
  }
];

export {
  day11
}