const day5 = [  
  {
    title: `Sector 4 - Battles`,
    teams: [
      {
        id: 501,
        name: '2x',
        videoThumb: 'WO73F8CRelM?t=178&si=HP5vkUHJNKILGTJj',
        feats: [
          { name: 'STAP', qty: 1 },
          { name: 'Accuracy Down', qty: 5 }
        ],
        toons: [
          { image: 'dash', name: 'Dash'  },
          { image: 'stap', name: 'STAP', main: true },
          { image: 'eighth-brother', name: '8th Bro'},
          { image: 'thrawn', name: 'Thrawn'},
          { image: 'gideon', name: 'Gideon' },
        ]
      },
    ]
  },
  {
    title: `Sector 4 - Mini Boss`,
    boss: 'gg',
    teams: [
      {
        id: 507,
        name: 'Dark Side',
        videoThumb: 'vvx-6CZvwOw?t=145&si=9Ql1wMCEjc8dGTPD',
        toons: [
          { image: 'veers', name: 'Veers'  },
          { image: 'malak', name: 'Malak' },
          { image: 'gideon', name: 'Gideon' },
          { image: 'piett', name: 'Piett'},
          { image: 'range', name: 'Range' }
        ]
      }
    ]
  },
  {
    title: `Sector 4 - Battles`,
    teams: [
      {
        id: 502,
        name: '2x',
        videoThumb: 'WO73F8CRelM?t=394&si=ZBidmofNIQ4lTYok',
        feats: [
          { name: 'Shield Up', qty: 4 },
        ],
        global: [
          { name: 'Gungans' }
        ],
        toons: [          
          { image: 'rey', name: 'Rey' },
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx', main: true },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
      {
        id: 503,
        name: '2x',
        videoThumb: 'WO73F8CRelM?t=258&si=aEAgeF-1BB4OSFnu',
        feats: [
          { name: 'Accuracy Down', qty: 10 }
        ],
        toons: [
          { image: 'palpatine', name: 'Palpatine' },
          { image: 'seventh-sister', name: '7th Sis', main: true },
          { image: 'eighth-brother', name: '8th Bro', main: true},
          { image: 'malak', name: 'Malak' },
          { image: 'stap', name: 'STAP' },
        ]
      },
      {
        id: 504,
        name: '2x',
        videoThumb: 'WO73F8CRelM?t=110&si=s65dxp23IKJivwkt',
        feats: [
          { name: 'Rebels', qty: 1 },
        ],
        global: [
          { name: 'Captain Rex' }
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'chewie', name: 'Chewie' },
          { image: 'han', name: 'Han' },
          { image: 'threepio', name: 'Threepio' },
          { image: 'captain-rex', name: 'Captain Rex' }
        ]
      },
      {
        id: 505,
        name: '2x',
        videoThumb: '',
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon', main: true },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'stormtrooper', name: 'Stormtrooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'gideon', name: 'Gideon' }
        ]
      },
      {
        id: 506,
        name: '2x',
        videoThumb: 'WO73F8CRelM?t=74&si=TVpj8sb2ChzwU9Hl',
        feats: [
          { name: 'Rebels', qty: 1 },
        ],
        toons: [
          { image: 'admiralraddus', name: 'Raddus' },
          { image: 'jyn', name: 'Jyn' },
          { image: 'cassian', name: 'Cassian' },
          { image: 'pathfinder', name: 'Pathfinder' },
          { image: 'k2', name: 'K2' }
        ]
      },
    ]
  },
  {
    title: `Sector 4 - Final Boss`,
    boss: 'boss-nass',
    teams: [
      {
        id: 508,
        name: 'No GLs',
        videoThumb: 'vvx-6CZvwOw?t=373&si=uW8IoaoRoM-uAo_K',
        toons: [
          { image: 'padme', name: 'Padme'},
          { image: 'ahsoka', name: 'Ahsoka' },
          { image: 'gk', name: 'General Kenobi' },
          { image: 'jka', name: 'Jedi Ani'},
          { image: 'shaak-ti', name: 'Shaak Ti' }
        ]
      }
    ]
  },
  {
    title: 'Sector 4 - Progress',
    progress: [
      { name: 'STAP', current: 8, target: 10 },
      { name: 'Shield Up', current: 16, target: 50 },
      { name: 'Accuracy Down', current: 50, target: 50 },
      { name: 'Rebels', current: 6, target: 10 }
    ]
  },
  {
    title: `Sector 5 - Battles`,
    teams: [
      {
        id: 509,
        name: '2x',
        videoThumb: '3Mn7qzQfLbo?t=59&si=ZyfW1xAJWR3bkuIA',
        feats: [
          { name: 'Phoenix', qty: 5 },
          { name: 'Evasion Down', qty: 10 },
        ],
        global: [
          { name: 'Captain Rex' }
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'chewie', name: 'Chewie' },
          { image: 'han', name: 'Han' },
          { image: 'threepio', name: 'Threepio', main: true  },
          { image: 'captain-rex', name: 'Captain Rex' }
        ]
      },
      {
        id: 510,
        name: '2x',
        videoThumb: '3Mn7qzQfLbo?t=194&si=NNeYF3cPXbXbuQlS',
        feats: [
          { name: 'Phoenix', qty: 5 },
          { name: 'Qui-Gons', qty: 1 },
        ],
        global: [
          { name: 'Captain Rex' },
          { name: 'Master & Padawn' }
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'qgj', name: 'QGJ' },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
          { image: 'captain-rex', name: 'Captain Rex', main: true }
        ]
      },
      {
        id: 511,
        name: '2x',
        videoThumb: '3Mn7qzQfLbo?t=97&si=YL_StWg-WkJd3whf',
        feats: [
          { name: 'Bounty Hunters', qty: 5 },
          { name: 'Evasion Down', qty: 5 },
        ],
        toons: [
          { image: 'bossk', name: 'Bossk' },
          { image: 'zam', name: 'Zam', main: true  },
          { image: 'greef', name: 'Greef'},
          { image: 'mando', name: 'Mandalorian' },
          { image: 'bam', name: 'Beskar Mando' }
        ]
      },
    ]
  },
  {
    title: 'Sector 5 - Progress',
    progress: [
      { name: 'Evasion Down', current: 30, target: 50 },
      { name: 'Phoenix', current: 20, target: 35 },
      { name: 'Qui-Gons', current: 2, target: 10 },
      { name: 'Bounty Hunters', current: 10, target: 35 }
    ]
  },



  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Jedi Cal', skip: true },
      { name: 'Master & Padawn', complete: true },  
      { name: 'Captain Rex', complete: true  },
      { name: 'Challenge Path', complete: true },    
      { name: 'Gungans', current: 8, target: 15 },
      { name: 'Dark Gideon', current: 14, target: 35 },
    ]
  }
];

export {
  day5
}