import React, { useEffect, useState } from 'react';

import Page from '../../components/page';
import Counter from '../../components/counter';
import Video from '../../components/video';
import Toon from '../../components/toon';
import SwTypeAhead from '../../components/typeahead';
import { counters, negotiator, malevolence, raddus, finalizer, executor, homeOne, executrix, chimaera, profundity, leviathan } from './data/videos';
import { list } from './data/counters';
import CountersMenu from '../5v5/menu';

const renderOption = (option) => (
  <div>
    <Toon name={`${option.name} (${counters.filter(v => v.counter === option.value).length})`} image={option.value} listItem />
  </div>
);

const Ships = (props) => {
  const [leaderName, selectName] = useState(null);
  const [leaderVideos, selectVideos] = useState([]);

  useEffect(() => {
    loadVideos(props.match.params.id);
  }, [props.match.params.id]);

  const selectLeader = (selected) => {
    const getName = selected[0].value;
    props.history.push(`/counters/ships/${getName ? `${getName}/` : ''}`);
  }

  const loadVideos = (selected) => {
    const getName = list.find(l => l.value === selected);
    const filterVideos = getName && counters.filter(c => c.counter === selected);
    selectName(getName ? getName.name : null);
    document.title = getName ? `${getName.name} - Ship Counters - SWGOH-4 Life` : 'Ships - SWGOH-4 Life';
    selectVideos(getName ? filterVideos.sort((a, b) => parseFloat(b.banners) - parseFloat(a.banners)) : null);
  }

  const intro = <div className="row">
    <div className="col-12">
      <div className="mb-2">
        <strong>Select the fleet commander you want to counter</strong>
      </div>
      <SwTypeAhead
        onChange={selectLeader} placeholder="Search or select" emptyLabel="No counters found"
        options={list} labelKey="name" id="selectLeader" renderOption={renderOption}
      />
    </div>
  </div>;

  return (
    <Page menu={<CountersMenu />} title={leaderName ? `${leaderName} Fleet Counters (${leaderVideos.length})` : 'Ship Guides and Counters'} intro={intro}>

      <div>
        {leaderName && leaderVideos.length > 0 ?
          <div className="sw-feats">
            {leaderVideos.map((video, i) =>
              <Counter
                toons={video.squad} id={video.id} key={i}
                url={video.url} banners={video.banners} defense={video.defense}
              />)}
          </div>
          :
          <>

            <div className="sw-feats">
              <h2 className="mb-5 mb-md-6 sw-heading">Chimaera Guides</h2>
              <div className="row">
                {chimaera.map((guide, i) =>
                  <div className="col-12 col-sm-6 col-xl-4 pb-5" key={i}>
                    <Video
                      id={guide.id} name={guide.title} desc={guide.desc}
                      url={guide.url} image={guide.image}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="sw-feats">
              <h2 className="mb-5 mb-md-6 sw-heading">Leviathan Guides</h2>
              <div className="row">
                {leviathan.map((guide, i) =>
                  <div className="col-12 col-sm-6 col-xl-4 pb-5" key={i}>
                    <Video
                      id={guide.id} name={guide.title} desc={guide.desc}
                      url={guide.url} image={guide.image}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="sw-feats">
              <h2 className="mb-5 mb-md-6 sw-heading">Profundity Guides</h2>
              <div className="row">
                {profundity.map((guide, i) =>
                  <div className="col-12 col-sm-6 col-xl-4 pb-5" key={i}>
                    <Video
                      id={guide.id} name={guide.title} desc={guide.desc}
                      url={guide.url} image={guide.image}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="sw-feats">
              <h2 className="mb-5 mb-md-6 sw-heading">Executor Guides</h2>
              <div className="row">
                {executor.map((guide, i) =>
                  <div className="col-12 col-sm-6 col-xl-4 pb-5" key={i}>
                    <Video
                      id={guide.id} name={guide.title} desc={guide.desc}
                      url={guide.url} image={guide.image}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="sw-feats">
              <h2 className="mb-5 mb-md-6 sw-heading">Negotiator Guides</h2>
              <div className="row">
                {negotiator.map((guide, i) =>
                  <div className="col-12 col-sm-6 col-xl-4 pb-5" key={i}>
                    <Video
                      id={guide.id} name={guide.title} desc={guide.desc}
                      url={guide.url} image={guide.image}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="sw-feats">
              <h2 className="mb-5 mb-md-6 sw-heading">Malevolence Guides</h2>
              <div className="row">
                {malevolence.map((guide, i) =>
                  <div className="col-12 col-sm-6 col-xl-4 pb-5" key={i}>
                    <Video
                      id={guide.id} name={guide.title} desc={guide.desc}
                      url={guide.url} image={guide.image}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="sw-feats">
              <h2 className="mb-5 mb-md-6 sw-heading">Finalizer Guides</h2>
              <div className="row">
                {finalizer.map((guide, i) =>
                  <div className="col-12 col-sm-6 col-xl-4 pb-5" key={i}>
                    <Video
                      id={guide.id} name={guide.title} desc={guide.desc}
                      url={guide.url} image={guide.image}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="sw-feats">
              <h2 className="mb-5 mb-md-6 sw-heading">Home One Guides</h2>
              <div className="row">
                {homeOne.map((guide, i) =>
                  <div className="col-12 col-sm-6 col-xl-4 pb-5" key={i}>
                    <Video
                      id={guide.id} name={guide.title} desc={guide.desc}
                      url={guide.url} image={guide.image}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="sw-feats">
              <h2 className="mb-5 mb-md-6 sw-heading">Executrix Guides</h2>
              <div className="row">
                {executrix.map((guide, i) =>
                  <div className="col-12 col-sm-6 col-xl-4 pb-5" key={i}>
                    <Video
                      id={guide.id} name={guide.title} desc={guide.desc}
                      url={guide.url} image={guide.image}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="sw-feats">
              <h2 className="mb-5 mb-md-6 sw-heading">Raddus Guides</h2>
              <div className="row">
                {raddus.map((guide, i) =>
                  <div className="col-12 col-sm-6 col-xl-4 pb-5" key={i}>
                    <Video
                      id={guide.id} name={guide.title} desc={guide.desc}
                      url={guide.url} image={guide.image}
                    />
                  </div>
                )}
              </div>
            </div>

          </>
        }
      </div>
    </Page>
  );
}

export default Ships;
