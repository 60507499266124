const sector1 = [  
  {
    title: 'Mandalorians',
    text: 'Defeat 35 enemies with Light Side Mandalorians',
    keycards: 5,
    teams: [
      {
        name: 'Low Gear',
        videoThumb: 'rlb_tlMtOlE?t=58&si=NYtY_-8FNNi-X_LE',
        feats: [
          { name: 'Mandalorians', qty: 5 },
        ],
        toons: [
          { image: 'thrawn', name: 'Thrawn' },
          { image: 'sabine', name: 'Sabine', main: true },
          { image: 'ben', name: 'Old Ben' },
          { image: 'gideon', name: 'Gideon' },
          { image: 'jkl', name: 'Jedi Luke' },
        ]
      },
      {
        videoThumb: 'skxEXfuDRPk?t=207&si=gXgUmiT_kQsIPRNR',
        feats: [
          { name: 'Mandalorians', qty: 5 },
          { name: 'Evasion Up', qty: 5 },
        ],
        global: [
          { name: 'Master & Padawan' },
          { name: 'Captain Rex' },
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'bam', name: 'Beskar Mandalorian', main: true },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
          { image: 'captain-rex', name: 'Captain Rex' },
        ]
      },
      {
        videoThumb: 'skxEXfuDRPk?t=260&si=lqfHv0--_WeTXFsD',
        feats: [
          { name: 'Mandalorians', qty: 5 },
        ],
        toons: [
          { image: 'bokatan', name: 'Bo-Katan' },
          { image: 'bam', name: 'Beskar Mandalorian' },
          { image: 'armorer', name: 'Armorer' },
          { image: 'sabine', name: 'Sabine' },
          { image: 'mando', name: 'Mandalorian' }
        ]
      },
      {
        videoThumb: 'skxEXfuDRPk?t=284&si=n7Htk3tnaOR53dW6',
        feats: [
          { name: 'Mandalorians', qty: 5 },
        ],
        toons: [
          { image: 'bo-mandalor', name: 'Bo-Katan' },
          { image: 'bam', name: 'Beskar Mandalorian' },
          { image: 'paz', name: 'Paz' },
          { image: 'ig-12', name: 'IG-12' },
          { image: 'armorer', name: 'Armorer' }
        ]
      },
    ]
  },

  {
    title: 'Evasion Up',
    text: 'Gain Evasion Up 50 times',
    keycards: 5,
    teams: [
      {
        videoThumb: 'skxEXfuDRPk?t=151&si=F4HEWc6A8Osv6XRD',
        feats: [
          { name: 'Evasion Up', qty: 10 },
        ],
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [          
          { image: 'hunter', name: 'Hunter', main: true },
          { image: 'bb-echo', name: 'Echo', main: true },
          { image: 'captain-rex', name: 'Captain Rex' },
          { image: 'wrecker', name: 'wrecker'},
          { image: 'tech', name: 'tech' }
        ]
      },
      {
        videoThumb: 'skxEXfuDRPk?t=118&si=It08yyUQDnGo5MyV',
        feats: [
          { name: 'Evasion Up', qty: 10 },
        ],
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [          
          { image: 'cls', name: 'Commander Luke' },
          { image: 'han', name: 'Han', main: true },
          { image: 'captain-rex', name: 'Captain Rex' },
          { image: 'threepio', name: 'Threepio'},
          { image: 'chewie', name: 'Chewie' }
        ]
      },
      {
        videoThumb: 'skxEXfuDRPk?t=171&si=DUVkq462gJaUpCo7',
        feats: [
          { name: 'Evasion Up', qty: 10 },
        ],
        global: [
          { name: 'Mater & Padawan' },
        ],
        toons: [
          { image: 'bane', name: 'Bane', main: true },
          { image: 'seventh-sister', name: '7th Sis', main: true },
          { image: 'han', name: 'Han Solo', main: true },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
        ]
      },
    ]
  },
  {
    title: 'Ewoks',
    text: 'Defeat 35 enemies with Ewoks',
    keycards: 5,
    teams: [
      {
        name: 'Low Gear',
        videoThumb: 'rlb_tlMtOlE?t=94&si=Pb5zITiJn92VIUxL',
        feats: [
          { name: 'Ewoks', qty: 5 },
        ],
        toons: [
          { image: 'thrawn', name: 'Thrawn' },
          { image: 'wicket', name: 'Wicket', main: true },
          { image: 'ben', name: 'Old Ben' },
          { image: 'gideon', name: 'Gideon' },
          { image: 'jkl', name: 'Jedi Luke' },
        ]
      },
      {
        videoThumb: 'skxEXfuDRPk?t=357&si=wx1bQgi1HdK9uGmv',
        feats: [
          { name: 'Ewoks', qty: 5 },
        ],
        global: [
          { name: 'Master & Padawan' },
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'paploo', name: 'Paploo', main: true },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
          { image: 'han', name: 'Han Solo'},
        ]
      },
      {
        videoThumb: 'skxEXfuDRPk?t=311&si=GEnzWgaVnvK5Ag7w',
        feats: [
          { name: 'Ewoks', qty: 5 },
        ],
        toons: [
          { image: 'chirpa', name: 'Chirpa' },
          { image: 'paploo', name: 'Paploo' },
          { image: 'logray', name: 'Logray' },
          { image: 'wicket', name: 'Wicket' },
          { image: 'kneesaa', name: 'Kneesaa' }
        ]
      },
    ]
  },
  {
    title: 'Resistance',
    text: 'Win 10 battles with a full squad of Resistance units',
    keycards: 5,
    teams: [
      {
        videoThumb: 'skxEXfuDRPk?t=407&si=etu7AWNp5VO5aA5_',
        feats: [
          { name: 'Resistance', qty: 1 },
        ],
        toons: [
          { image: 'finn', name: 'Finn' },
          { image: 'hero-finn', name: 'Hero Finn' },
          { image: 'hero-poe', name: 'Hero Poe' },
          { image: 'zorii', name: 'Zorii' },
          { image: 'poe', name: 'Poe' },
        ]
      },
      {
        videoThumb: 'skxEXfuDRPk?t=427&si=lFzYzK3536IX2cTb',
        feats: [
          { name: 'Resistance', qty: 1 },
        ],
        toons: [
          { image: 'rjt', name: 'Rey (JT)' },
          { image: 'r2', name: 'R2' },
          { image: '3po', name: 'C-3PO' },
          { image: 'bb', name: 'BB-8' },
          { image: 'resistance-trooper', name: 'Trooper' },
        ]
      },
      {
        videoThumb: 'skxEXfuDRPk?t=456&si=M8WN1td4cBV0-3Hh',
        feats: [
          { name: 'Resistance', qty: 1 },
        ],
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'scavenger', name: 'Scavenger' },
          { image: 'holdo', name: 'Holdo' },
          { image: 'vet-han', name: 'Vet Han' },
          { image: 'vet-chewie', name: 'Vet Chewie' },
        ]
      }
    ]
  },
  {
    title: 'Mini Boss',
    text: `Battle vs Cere with Scout Trooper defeating an enemy and with Ligh Side only`,
    keycards: 7,
    teams: [
      {
        name: `Scout Trooper`,
        videoThumb: '83bG36VxlZw?t=272&si=6EPD-iP8yKwjScSg',
        global: [
          { name: 'Dark Gideon' },
        ],
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon' },
          { image: 'scout-trooper', name: 'Scout Trooper', main: true },
          { image: 'stormtrooper', name: 'Stormtrooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'gideon', name: 'Gideon' }
        ]
      },
      {
        videoThumb: '83bG36VxlZw?t=394&si=JozZMj4Fa6XAQ7Ao',
        toons: [
          { image: 'veers', name: 'Veers' },
          { image: 'scout-trooper', name: 'Scout Trooper', main: true },
          { image: 'piett', name: 'Piett' },
          { image: 'range', name: 'Range Trooper' },
          { image: 'starck', name: 'Starck' }
        ]
      },
      {
        name: `Light Side`,
        videoThumb: '83bG36VxlZw?t=171&si=mqCm6EPwkgemfUl7',
        feats: [
          { name: 'Evasion Up', qty: 10 },
        ],
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [          
          { image: 'hunter', name: 'Hunter' },
          { image: 'bb-echo', name: 'Echo' },
          { image: 'captain-rex', name: 'Captain Rex' },
          { image: 'wrecker', name: 'wrecker'},
          { image: 'tech', name: 'tech' }
        ]
      },
      {
        videoThumb: '83bG36VxlZw?t=85&si=JdVxPHRj8mx09gLr',
        feats: [
          { name: 'Evasion Up', qty: 10 },
        ],
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [          
          { image: 'gas', name: 'GAS' },
          { image: 'rex', name: 'Rex' },
          { image: 'arc', name: 'ARC' },
          { image: 'fives', name: 'Fives'},
          { image: 'echo', name: 'Echo' }
        ]
      }
    ]
  },
  {
    title: 'Final Boss',
    text: 'Battle vs Darth Vader with Thrawn surviving and without using Empire',
    keycards: 9,
    teams: [
      {
        name: 'Thrawn',
        videoThumb: '83bG36VxlZw?t=501&si=XfE3Nn28rvgTYRSB',
        feats: [
          { name: 'Evasion Up', qty: 10 },
        ],
        toons: [
          { image: 'palpatine', name: 'Palpatine' },
          { image: 'mara', name: 'Mara'},
          { image: 'thrawn', name: 'Thrawn', main: true },
          { image: 'malak', name: 'Malak'  },
          { image: 'han', name: 'Han' },
        ]
      },
      {
        name: 'No Empire',
        videoThumb: '83bG36VxlZw?t=570&si=TmNQOmsNTBu3PpWO',
        feats: [
          { name: 'Evasion Up', qty: 10 },
        ],
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [          
          { image: 'hunter', name: 'Hunter' },
          { image: 'bb-echo', name: 'Echo' },
          { image: 'captain-rex', name: 'Captain Rex' },
          { image: 'wrecker', name: 'wrecker'},
          { image: 'tech', name: 'tech' }
        ]
      }
    ]
  }
];

export {
  sector1
}