const sector6 = [
    { name: 'Low Gear Guide', id: 'rlb_tlMtOlE' },
    { name: 'Datacron Farming', id: 'CgX6JpCIIdQ' },
    { name: 'Conquest Volume 154 Data Disk & Feats Preview Guide', id: 'PfhwoABQszY' },
];

const sector1 = [
    { name: 'Sector 1 - Feats Guide', id: 'skxEXfuDRPk' },
    { name: 'Sector 1 - Mini & Final Boss Guide', id: '83bG36VxlZw' },
];

const sector2 = [
    { name: 'Sector 2 - Feats Guide', id: '-9XDYTjLfq4' },
    { name: 'Sector 2 - Mini & Final Boss Guide', id: 'otpaqFu5xSo' },
];

const sector3 = [
    { name: 'Sector 3 - Feats Guide', id: 'HAzLlLIAVUM' },
    { name: 'Sector 3 - Mini & Final Boss Guide', id: 'zBrs1T_iqiw' },
];

const sector4 = [
    { name: 'Sector 4 - Feats Guide', id: 'WO73F8CRelM' },
    { name: 'Sector 4 - Mini & Final Boss Guide', id: 'vvx-6CZvwOw' },
];

const sector5 = [
    { name: 'Sector 5 - Feats Guide', id: '3Mn7qzQfLbo' },
    { name: 'Sector 5 - Mini & Final Boss Guide', id: '4KDS7QQnH5A' }
];

const sectors = {
    sector1: sector1,
    sector2: sector2,
    sector3: sector3,
    sector4: sector4,
    sector5: sector5,
    sector6: sector6,
};

export {
    sectors
}