const ls = [
    { name: 'Cere & Cal Mission', desc: 'Showing both Cal and Jedi Call', id: 'CmeL_UsIZzM' },
    { name: 'Saw & Wookies', desc: 'Kashyyyk Missions', id: '-HaHCj_IYNs' },
    { name: 'JMK & Rey', desc: 'Coruscant Missions', id: 'gy4z_DimieQ' },
    { name: 'Post 9th Sister Lead Fix', desc: 'Guide for JML vs Ninth Sister on Bracca + Mothma on Coruscant', id: 'dNXwh41TdDI' },
    { name: 'Leia vs TB', desc: 'Coruscant, Bracca & Kashyyyk', id: 'tuijX4-bWP8' }
];

const mix = [
    { name: 'Bo-Katan', desc: '3 Attempts at the SM on Tatooine', id: '1ENfddA1z2M' },
    { name: 'Reva Mission', desc: '6 Attempts for earning Reva shards on Tatooine', id: 'eWAIMakyzyY' },
    { name: 'Reva Shards', desc: 'Guide for earning Reva shards after Jawa Scavenger buff on Tatooine in Sector 3', id: '2Q8f_Gpnkcg' },
    { name: 'Hondo & Lando', desc: 'Rey, SLKR and JMK', id: 'D691KlENDrQ' },
    { name: 'Jabba Missions', desc: 'Guide for Corellia, Felucia & Tatooine', id: 'k_Msldf_jqA' }
];

const ds = [
    { name: 'Palpatine & Trench', desc: 'Mustafar Missions', id: 'XQ0ML_qnRtY' },
    { name: 'DR, Trench & Reva', desc: 'Dathomir & Tatooine Missions', id: 'nNPhAaob1LE' },
    { name: 'Geos & Nightsisters', desc: 'Geonosis & Dathomir Missions', id: 'w754OsqH5vs' },
    { name: 'SLKR, GI, LV & Trench', desc: 'Geonosis Missions', id: '3EUB7pnqIIQ' },
    { name: 'Doctor Aphra', desc: 'Guide for Corellia & Dathomir', id: 'uZxgBx1S91E' }
];

const ships = [
    { name: 'Geonosis Mission', desc: 'How to beat Geonosis fleet mission with Leviathan', id: 'v5AJ8YN5E1o' },
    { name: 'Scythe Mission', desc: 'How to beat Mustafar fleet mission with either Sith Fighter or Mark VI Interceptor', id: 'uHcSSLVyH1E' },
    { name: 'Profundity vs ROTE', desc: 'Coruscant, Bracca, Kashyyyk, Lothal, Kessel & Kafrene', id: 'jsdTV585mbY' },
];

const planetsP1 = [
    { name: 'Coruscant - S1 LS', desc: 'Jedi, Mace, LS and Fleet', id: 'wvU3g6gjpcs' },
    { name: 'Mustafar - S1 DS', desc: 'LV, SLKR, SEE, GI and Fleet', id: 'cNBvWc03ErI' },
    { name: 'Corellia - S1 Mix', desc: 'Qira, EP Mara, Admiral Raddus and Fleet', id: 'CXmBvll3_SU' }
];


const planetsP2 = [
    { name: 'Bracca - S2 LS', desc: 'Jedi, LS CM and Fleet', id: 's34PRPeJKsQ' },
    { name: 'Geonosis - S2 DS', desc: 'LV, SLKR, SEE and GI', id: 'GWRtcATtOho' },
    { name: 'Felucia - S2 Mix', desc: 'Mix CM and Fleet', id: '4qAsN4u5Ejo' },
];

const planetsP3 = [
    { name: 'Kashyyyk - S3 LS', desc: '2x LS CM', id: 'IRNnaG4W6oU' },
    { name: 'Dathomir - S3 DS', desc: 'Empire and 2x DS CM', id: 'p9LAD2WlaoU' },
    { name: 'Tatooine - S3 Mix', desc: 'Fennec, Reva SM, Mix CM and Fleet', id: 'sI8t5Pg3NX8' },
    { name: 'Zeffo - S3 Bonus', desc: 'Full Clear of Zeffo', id: 'EkS1iNQyKTI' },
];

const planetsP4 = [
    { name: 'Haven Medical Station - S4 DS', desc: 'Full Clear of the Haven', id: '8Kfh8g1DAxg' },
    { name: 'Kessel - S4 Mix', desc: 'Full Clear of Kessel', id: '8iwD4tC9H8g' },
    { name: 'Lothal - S4 LS', desc: 'Full Clear of Lothal', id: 'zXfD449EcQ0' },
];

const fullClear = [
    { name: 'Sector 2', desc: 'Autoplay Edition', id: '-9s4bBqcMw0' },
    { name: 'Sector 1', desc: 'Autoplay Edition', id: 'rR5JBVxwdL8' },
    { name: 'Sector 3', desc: 'Including Zeffo', id: '-n_9bepyhpQ' },
    { name: 'Sector 2', desc: 'Excluding DS ships', id: 'OuEYhcL_yME' },
    { name: 'Sector 1', desc: 'My first ever full clear!', id: 'vtOoPROo0Ho' },
];

const firstLook = [
    { name: 'Day 1', desc: 'P1 Coruscant + Corellia + Mustafar', id: '6AxS1Zljt3g' },
    { name: 'Day 2', desc: 'P2 Felucia + Bracca / P1 Mustafar', id: 'NIDAFO2-sKg' },
    { name: 'Day 3', desc: 'P3 Tatooine / P2 Geonosis + Bracca', id: '53171nJlggY' },
    { name: 'Day 4', desc: 'P3 Kashyyk + Tatooine / P2 Geonosis', id: 'wBZc-AORWag' },
    { name: 'Day 5', desc: 'P4 Kessel / P3 Dathomir + Kashyyk', id: 'c1hwsrGrQjU' },
    { name: 'Day 6', desc: 'P4 Lothal + Kessel / P3 Dathomir', id: 'j6Tk4DtT1Zw' },
];

export {
    firstLook,
    planetsP1,
    planetsP2,
    planetsP3,
    planetsP4,
    fullClear,
    ships,
    ls,
    mix,
    ds
}