const day2 = [
  {
    title: `Sector 1 - Battles`,
    teams: [
      {
        id: 201,
        name: '2x',
        videoThumb: 'skxEXfuDRPk?t=207&si=gXgUmiT_kQsIPRNR',
        feats: [
          { name: 'Mandalorians', qty: 5 },
          { name: 'Evasion Up', qty: 5 },
        ],
        global: [
          { name: 'Master & Padawan' },
          { name: 'Captain Rex' },
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'bam', name: 'Beskar Mandalorian', main: true },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
          { image: 'captain-rex', name: 'Captain Rex' },
        ]
      },
    ]
  },
  {
    title: `Sector 1 - Final Boss`,
    boss: 'darth-vader',
    teams: [
      {
        id: 202,
        name: 'Thrawn',
        videoThumb: '83bG36VxlZw?t=501&si=XfE3Nn28rvgTYRSB',
        feats: [
          { name: 'Evasion Up', qty: 10 },
        ],
        toons: [
          { image: 'palpatine', name: 'Palpatine' },
          { image: 'mara', name: 'Mara'},
          { image: 'thrawn', name: 'Thrawn', main: true },
          { image: 'malak', name: 'Malak'  },
          { image: 'han', name: 'Han' },
        ]
      },
      {
        id: 203,
        name: 'No Empire',
        videoThumb: '83bG36VxlZw?t=570&si=TmNQOmsNTBu3PpWO',
        feats: [
          { name: 'Evasion Up', qty: 10 },
        ],
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [          
          { image: 'hunter', name: 'Hunter' },
          { image: 'bb-echo', name: 'Echo' },
          { image: 'captain-rex', name: 'Captain Rex' },
          { image: 'wrecker', name: 'wrecker'},
          { image: 'tech', name: 'tech' }
        ]
      },
    ]
  },
  {
    title: 'Sector 1 - Progress',
    progress: [
      { name: 'Evasion Up', current: 50, target: 50 },
      { name: 'Mandalorians', current: 20, target: 35 },
      { name: 'Ewoks', current: 10, target: 35 },
      { name: 'Resistance', current: 6, target: 10 }
    ]
  },


  {
    title: `Sector 2 - Battles`,
    teams: [
      {
        id: 204,
        name: '2x',
        videoThumb: '-9XDYTjLfq4?t=71&si=dE6hBZ9ndUHmusiv',
        feats: [
          { name: 'Rebel Fighters', qty: 5 },
        ],
        toons: [
          { image: 'mothma', name: 'Mothma' },
          { image: 'kyle', name: 'Kyle' },
          { image: 'biggs', name: 'Biggs' },
          { image: 'pao', name: 'Pao'},
          { image: 'hoth-scout', name: 'Hoth Scout' }
        ]
      },
      {
        id: 205,
        name: '2x',
        videoThumb: '-9XDYTjLfq4?t=174&si=zgeYJp4jEM-repfw',
        feats: [
          { name: 'Rebel Fighters', qty: 5 },
        ],
        global: [
          { name: 'Captain Rex' },
          { name: 'Master & Padawan' },
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'wat', name: 'Wat' },
          { image: 'captain-rex', name: 'Captain Rex', main: true },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
        ]
      },
      {
        id: 206,
        name: '2x',
        videoThumb: '-9XDYTjLfq4?si=lO1rXhWNpM23QngU&t=220',
        feats: [
          { name: 'Imperial Troopers', qty: 5 },
        ],
        toons: [
          { image: 'veers', name: 'Veers' },
          { image: 'gideon', name: 'Gideon' },
          { image: 'starck', name: 'Starck' },
          { image: 'piett', name: 'Piett' },
          { image: 'range', name: 'Range Trooper' },
        ]
      },
      {
        id: 207,
        name: '4x',
        videoThumb: '-9XDYTjLfq4?t=327&si=vpEilQtkZTeRt8bO',
        feats: [
          { name: 'Potency Down', qty: 10 },
        ],
        global: [
          { name: 'Master & Padawan' },
        ],
        toons: [          
          { image: 'jkl', name: 'Jedi Luke' },
          { image: 'gmy', name: 'Master Yoda', main: true },
          { image: 'jolee', name: 'Jolee' },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
        ]
      },
      {
        id: 208,
        name: '4x',
        videoThumb: '-9XDYTjLfq4?si=sEEbLidhCHwQwmeF&t=282',
        feats: [
          { name: 'Nightsisters', qty: 1 },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'merrin', name: 'Merrin' },
          { image: 'daka', name: 'Daka' },
          { image: 'asajj', name: 'Asajj' },
          { image: 'zombie', name: 'Zombie' }
        ]
      },
    ]
  },
  {
    title: `Sector 2 - Mini Boss`,
    boss: 'kelleran',
    teams: [
      {
        id: 209,
        name: 'Sabine',
        videoThumb: 'otpaqFu5xSo?t=90&si=GA5jglEUEX7bo7kk',
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'sabine', name: 'Sabine', main: true },
          { image: 'wat', name: 'Wat' },
          { image: 'captain-rex', name: 'Captain Rex' },
          { image: 'gmy', name: 'Yoda' }
        ]
      },
      {
        id: 210,
        name: 'Galactic Republic',
        videoThumb: 'otpaqFu5xSo?t=220&si=eBTab53C1AHP8M7U',
        feats: [
          { name: 'Potency Down', qty: 5 },
        ],
        toons: [          
          { image: 'padme', name: 'Padme' },
          { image: 'jka', name: 'Jedi Anakin' },
          { image: 'gk', name: 'General Kenobi' },
          { image: 'gmy', name: 'Master Yoda' },
          { image: 'ahsoka', name: 'Ahsoka' },
        ]
      }
    ]
  },
  {
    title: 'Sector 2 - Progress',
    progress: [
      { name: 'Rebel Fighters', current: 20, target: 35 },
      { name: 'Imperial Troopers', current: 10, target: 35 },
      { name: 'Nightsisters', current: 4, target: 10 },
      { name: 'Potency Down', current: 50, target: 50 }
    ]
  },



  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Jedi Cal', skip: true },
      { name: 'Challenge Path', current: 100, target: 250 },      
      { name: 'Captain Rex', current: 10, target: 15 },
      { name: 'Gungans', current: 2, target: 15 },
      { name: 'Dark Gideon', current: 8, target: 35 },
      { name: 'Master & Padawn', current: 12, target: 15 },
    ]
  }
];

export {
  day2
}