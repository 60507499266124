const day1 = [
  {
    title: `Sector 1 - Battles`,
    teams: [
      {
        id: 101,
        name: '2x',
        videoThumb: 'skxEXfuDRPk?t=484&si=5FJFkd1B_VorC3yF',
        global: [
          { name: 'Master & Padawan' },
        ],
        toons: [
          { image: 'jkl', name: 'Jedi Luke'  },
          { image: 'jolee', name: 'Jolee' },
          { image: 'master-qgj', name: 'Master QGJ', main: true },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan', main: true },
          { image: 'shaak-ti', name: 'Shaak Ti' },
        ]
      },
      {
        id: 102,
        name: '2x',
        videoThumb: 'skxEXfuDRPk?t=118&si=It08yyUQDnGo5MyV',
        feats: [
          { name: 'Evasion Up', qty: 10 },
        ],
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [          
          { image: 'cls', name: 'Commander Luke' },
          { image: 'han', name: 'Han', main: true },
          { image: 'captain-rex', name: 'Captain Rex' },
          { image: 'threepio', name: 'Threepio'},
          { image: 'chewie', name: 'Chewie' }
        ]
      },
      {
        id: 104,
        name: '2x',
        videoThumb: 'skxEXfuDRPk?t=407&si=etu7AWNp5VO5aA5_',
        feats: [
          { name: 'Resistance', qty: 1 },
        ],
        toons: [
          { image: 'finn', name: 'Finn' },
          { image: 'hero-finn', name: 'Hero Finn' },
          { image: 'hero-poe', name: 'Hero Poe' },
          { image: 'zorii', name: 'Zorii' },
          { image: 'poe', name: 'Poe' },
        ]
      },
      {
        id: 105,
        name: '2x',
        videoThumb: 'skxEXfuDRPk?t=427&si=lFzYzK3536IX2cTb',
        feats: [
          { name: 'Resistance', qty: 1 },
        ],
        toons: [
          { image: 'rjt', name: 'Rey (JT)' },
          { image: 'r2', name: 'R2' },
          { image: '3po', name: 'C-3PO' },
          { image: 'bb', name: 'BB-8' },
          { image: 'resistance-trooper', name: 'Trooper' },
        ]
      },
      {
        id: 106,
        name: '2x',
        videoThumb: 'skxEXfuDRPk?t=557&si=gaaMdR2WMOwzMokn',
        global: [
          { name: 'Gungans' },
        ],
        toons: [
          { image: 'dash', name: 'Dash' },
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx' },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
    ]
  },
  {
    title: `Sector 1 - Mini Boss`,
    boss: 'cere',
    teams: [
      {
        id: 111,
        name: `Light Side`,
        videoThumb: '83bG36VxlZw?t=85&si=JdVxPHRj8mx09gLr',
        feats: [
          { name: 'Evasion Up', qty: 10 },
        ],
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [          
          { image: 'gas', name: 'GAS' },
          { image: 'rex', name: 'Rex' },
          { image: 'arc', name: 'ARC' },
          { image: 'fives', name: 'Fives'},
          { image: 'echo', name: 'Echo' }
        ]
      }
    ]
  },
  {
    title: 'Sector 1 - Progress',
    progress: [
      { name: 'Mandalorians', skip: true },
      { name: 'Ewoks', skip: true },
      { name: 'Evasion Up', current: 30, target: 50 },
      { name: 'Resistance', current: 6, target: 10 }
    ]
  },

  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Jedi Cal', skip: true },
      { name: 'Dark Gideon', skip: true },
      { name: 'Challenge Path', current: 25, target: 250 },      
      { name: 'Captain Rex', current: 2, target: 15 },
      { name: 'Gungans', current: 2, target: 15 },
      { name: 'Master & Padawn', current: 2, target: 15 },
    ]
  }
];

export {
  day1
}