export const set15Data = [  
  {
    id: 'bulk-slkr',
    unit: 'slkr',
    order: 0,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-bulk',
      important: true
    },
    l6: {
      id: 'firstorder-400pct-off',
      important: true
    },
    l9: {
      id: 'unit-slkr',
      important: true
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'bulk-fosftp',
    unit: 'slkr',
    order: 1,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-bulk',
      important: true
    },
    l6: {
      id: 'firstorder-stacking',
      important: true
    },
    l9: {
      id: 'unit-fosftp',
      important: true
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'bulk-lv',
    unit: 'lv',
    order: 2,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-bulk',
      important: true
    },
    l6: {
      id: 'inquisitors-tm',
      important: true
    },
    l9: {
      id: 'unit-ninth-sister',
      important: true
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'bulk-jmk',
    unit: 'jmk',
    order: 3,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-bulk',
      important: true
    },
    l6: {
      id: 'gr-dmg-reduce',
      important: true
    },
    l9: {
      id: 'unit-padme'
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'bulk-reva',
    unit: 'reva',
    order: 4,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-bulk',
      important: true
    },
    l6: {
      id: 'inquisitors-assist',
      important: true
    },
    l9: {
      id: 'unit-reva',
      important: true
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'bulk-jml',
    unit: 'jml',
    order: 5,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-bulk',
      important: true
    },
    l6: {
      id: 'gr-dmg-reduce'
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'bulk-padme',
    unit: 'padme',
    order: 6,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-bulk',
      important: true
    },
    l6: {
      id: 'gr-ignore-taunt',
      important: true
    },
    l9: {
      id: 'unit-padme',
      important: true
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'bulk-malgus',
    unit: 'malgus',
    order: 7,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-bulk',
      important: true
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'bulk-jabba',
    unit: 'jabba',
    order: 8,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-bulk',
      important: true
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'bulk-leia',
    unit: 'leia-organa',
    order: 9,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-bulk',
      important: true
    },
    l6: {
      id: 'gr-dmg-reduce'
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'bulk-rey',
    unit: 'rey',
    order: 10,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-bulk',
      important: true
    },
    l6: {
      id: 'gr-dmg-reduce'
    },
    stats: ['Offense', 'Armor Penetration']
  },
  {
    id: 'bulk-see',
    unit: 'see',
    order: 11,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-bulk',
      important: true
    },
    l6: {
      id: 'firstorder-stacking'
    },
    l9: {
      id: 'unit-fost'
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'bulk-boss-nass',
    unit: 'boss-nass',
    order: 12,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-bulk',
      important: true
    },
    l6: {
      id: 'gungan-shield',
      important: true
    },
    l9: {
      id: 'unit-boss-nass',
      important: true
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'potency-aphra',
    unit: 'aphra',
    order: 13,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-potency',
      important: true
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'bulk-gas',
    unit: 'gas',
    order: 14,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-bulk',
      important: true
    },
    l6: {
      id: 'gr-dmg-reduce',
      important: true
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'bulk-tech',
    unit: 'hunter',
    order: 15,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-bulk',
      important: true
    },
    l6: {
      id: 'gr-dmg-reduce',
      important: true
    },
    l9: {
      id: 'unit-tech',
      important: true
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'bulk-qgj',
    unit: 'qgj',
    order: 16,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-bulk',
      important: true
    },
    l6: {
      id: 'gr-dmg-reduce',
      important: true
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'bulk-kelleran',
    unit: 'kelleran',
    order: 17,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-bulk',
      important: true
    },
    l6: {
      id: 'gr-dmg-reduce',
      important: true
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'bulk-traya',
    unit: 'traya',
    order: 18,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-bulk',
      important: true
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'bulk-basti',
    unit: 'jedi-basti',
    order: 19,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-bulk',
      important: true
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'bulk-raddus',
    unit: 'admiralraddus',
    order: 20,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-bulk',
      important: true
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'bulk-cere',
    unit: 'palpatine',
    order: 21,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-bulk',
      important: true
    },
    l6: {
      id: 'firstorder-stacking'
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'bulk-bo',
    unit: 'bo-mandalor',
    order: 22,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-bulk',
      important: true
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'bulk-dtmg',
    unit: 'dt-gideon',
    order: 23,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-bulk',
      important: true
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'bulk-cere',
    unit: 'cere',
    order: 24,
    complete: false,
    hide: false,
    l3: {
      id: 'ds-bulk',
      important: true
    },
    l6: {
      id: 'firstorder-stacking'
    },
    stats: ['Armor Penetration', 'Offense']
  },
  {
    id: 'bulk-amidala',
    unit: 'amidala',
    order: 25,
    complete: false,
    hide: false,
    l3: {
      id: 'ls-bulk',
      important: true
    },
    l6: {
      id: 'gr-dmg-reduce',
      important: true
    },
    l9: {
      id: 'unit-luminara'
    },
    stats: ['Armor Penetration', 'Offense']
  },
]