export const set16Bonus = [
  {
    id: 'ds-dots',
    label: 'Protection Up on DOTs',
    full: `Whenever Dark Side allies inflict Damage Over Time on an enemy, they gain a stack of Protection Up (5%, stacking) until the end of the encounter.`
  },
  {
    id: 'ds-revive',
    label: 'Tank Revive',
    full: `The first time a Dark Side Tank ally is defeated, revive them with 100% Health, reset their cooldowns, and they gain a bonus turn. Limit once per battle.`
  },
  {
    id: 'ds-tm',
    label: 'TM on Special',
    full: `Whenever Dark Side allies use a Special ability they gain 15% Turn Meter.`
  },
  {
    id: 'ls-buffs',
    label: 'Gain Buffs on Basic',
    full: `Whenever Light Side allies use a Basic ability, they gain Critical Chance Up, Critical Damage Up, and Offense Up for 2 turns.`
  },
  {
    id: 'support-tenacity',
    label: 'Dispel Tenacity Up',
    full: `Whenever a Support ally starts their turn dispel Tenacity Up from all enemies and then reduce their Tenacity by 100% until the end of the turn. Whenever a Support inflicts debuff on an enemy all allies get benefits based on their roles for the rest of the encounter: - Attackers: +10% Critical Damage - Healers: +5% Tenacity - Supports: +10% Potency - Tanks: +5% Defense. Whenever a Support grants a buff to another ally they also inflict Buff Immunity for 1 turn on all enemies.`
  },
  {
    id: 'support-block',
    label: 'Ability Block',
    full: `Whenever a Support ally deals damage to an enemy and no other enemy is Ability Blocked, inflict Ability Block for 1 turn on the target enemy, which can't be evaded or resisted.`
  },
  {
    id: 'support-tm',
    label: 'TM on Buff',
    full: `Whenever Support allies grant a buff to another ally, that ally gains 10% Turn Meter (limit once per turn per ally).`
  },
  {
    id: 'tank-reduction',
    label: 'Reduce Received Damage',
    full: `At the start of each turn, if non-Galactic Legend Tank allies have 50% or more Turn Meter, damage they receive is reduced by 75% until the end of the turn.`
  },
  {
    id: 'tank-grit',
    label: 'Gain Grit',
    full: `At the start of battle, Tank allies lose all Max Protection and gain that much Max Health. Tank allies gain Grit until the end of battle, which can't be copied, dispelled, or prevented. Grit: Takes reduced damage from percent Health damage effects`
  },
  {
    id: 'tank-immunity',
    label: 'Damage Immunity',
    full: `Tank allies start the battle with Damage Immunity for 2 turns (can't be copied)`
  },
  {
    id: 'healer-cooldowns',
    label: 'Reduce Cooldowns',
    full: `Whenever a character is healed by an allied Healer they reduce their cooldowns by 1. Whenever an ally Healer is critically hit they recover 10% Health and gain 10 Speed (stacking, max 100) for the rest of the encounter. Whenever an ally is revived on an ally Healer's turn all allies recover 100% Max Health and Max Protection. The first time each Healer ally is defeated they are revived and recover 100% Max Health.`
  },
  {
    id: 'attacker-damage',
    label: 'Attack Again',
    full: `Whenever an Attacker critically hits they attack again (limit once per turn) Otherwise, they gain 10% Defense Penetration (stacking). Whenever an Attacker attacks out of turn they deal bonus True damage which can't be evaded.`
  },
  {
    id: 'attacker-buffs',
    label: 'Gain Buffs',
    full: `At the end of their turn, Attacker allies gain Defense Penetration Up and Offense Up for 1 turn.`
  },
  {
    id: 'unit-cup',
    label: 'CUP',
    full: `Whenever Coruscant Underworld Police uses a Special ability, Shock all enemies for 2 turns which can't be evaded or resisted. The first time Coruscant Underworld Police reaches 50% Health he gains Damage Immunity for 3 turns. At the start of Coruscant Underworld Police's turn, he gains 15% Offense per active ally, his attacks ignore Protection, and a random ally recovers 100% Protection, until the end of his turn. Whenever Coruscant Underworld Police uses a Special ability call all allies to assist and the target enemy loses 10% Max Health until end of the encounter.`
  },
  {
    id: 'unit-trip',
    label: '0-0-0',
    full: `Whenever 0-0-0 critically hits an enemy, 0-0-0 gains Damage Immunity for 1 turn, which can't be copied, 25% Turn Meter, and Blinds that enemy for 1 turn, which can't be evaded or resisted. If the enemy was Target Locked, 0-0-0 also gains Protection Up (100%, stacking), Retribution, and Speed Up for 2 turns`
  },
  {
    id: 'unit-gamorrean',
    label: 'Gamorrean Guard',
    full: `Whenever Gamorrean Guard loses Taunt, remove 50% Turn Meter from all enemies.`
  },
  {
    id: 'unit-gar',
    label: 'Gar Saxon',
    full: `Whenever another ally uses an ability on their turn, Gar Saxon assists (limit once per turn) dealing 50% more damage.`
  },
  {
    id: 'unit-nest',
    label: 'Nest',
    full: `Enfys Nest is immune to Max Health reduction. Whenever Nest inflicts a debuff on an enemy she gains Critical Damage Up (20%) until end of the encounter. Whenever Nest uses a Special ability, she attacks again dealing an additional 20% damage as True damage. Whenever Nest attacks out of turn, she Exposes the target enemy for 2 turns. At the start of her turn Nest's damage is increased by 10% (stacking) for every enemy turn taken before this turn until the end of her turn and will assist the next other ally to take a turn.`
  },
  {
    id: 'unit-ig-11',
    label: 'IG-11',
    full: `Whenever another ally recovers Protection, IG-11 gains Foresight for 1 turn.`
  },
  {
    id: 'unit-wat',
    label: 'Wat',
    full: `Whenever another ally is defeated, Wat Tambor takes a bonus turn and their cooldowns are reset.`
  },
  {
    id: 'unit-vandor-chewie',
    label: 'Vandor Chewie',
    full: `Vandor Chewbacca has +5% Max Health and Max Protection per Relic Amplifier level, and damage they receive is decreased by 15%.`
  },
  {
    id: 'unit-wampa',
    label: 'Wampa',
    full: `Whenever Wampa inflicts a debuff, they recover 25% of their Max Health and Max Protection (limit once per turn).`
  },
  {
    id: 'unit-visas',
    label: 'Visas',
    full: `The first time Visas Marr is defeated, if there is at least one other active ally, revive all other defeated allies and other allies recover +100% Health and Protection, gain 100% Turn Meter and 100% Offense until the end of battle.`
  },
  {
    id: 'unit-elder',
    label: 'Ewok Elder',
    full: `At the start of each of Ewok Elder's turns, they reset their cooldowns and lose 5% Health for each turn each ability was reduced. Ewok Elder can't be defeated this way.`
  },
  {
    id: 'unit-sabine',
    label: 'Sabine Wren',
    full: `Whenever an enemy takes a turn, Sabine Wren takes a bonus turn. During this bonus turn, Sabine Wren deals 100% more damage. This bonus turn can't trigger this effect on enemies.`
  },
  {
    id: 'unit-nihilus',
    label: 'Darth Nihilus',
    full: `Whenever Darth Nihilus reduces his cooldowns, he gains 75% Offense and 20% Turn Meter for 1 turn. Whenever Darth Nihilus defeats an enemy, Expose all enemies for 2 turns, which can't be evaded. Whenever Darth Nihilus inflicts Damage Over Time on an enemy, all allies gain 3 stacks of Heal Over Time for 2 turns. If there is another Sith Support ally at the start of the encounter, Darth Nihilus gains 100% Max Health for the rest of the encounter.`
  },
  {
    id: 'unit-consular',
    label: 'Jedi Consular',
    full: `While Jedi Consular is active all Jedi allies are immune to Healing Immunity. Whenever Jedi Consular uses his Basic ability, reduce his cooldowns by 1 and he gains 7% Max Health (stacking, Max 210%) for the rest of the encounter. Whenever Jedi Consular heals an ally, dispel all debuffs on them and they gain Jedi's Will for 2 turns. Whenever Jedi Consular uses Jedi Healing, revive all non-Jedi allies with 100% Max Health and Max Protection. Whenever Jedi Consular uses Attack as Defense deal bonus True damage equal to 50% his Max Health, which can't be evaded. The first time Jedi Consular falls below 25% Health, he gains 100% Evasion for 2 turns.`
  },
  {
    id: 'unit-50rt',
    label: '50R-T',
    full: `At the start of 50R-T's turn, dispel all debuffs on them. They recover 5% Health for each debuff dispelled this way.`
  },
  {
    id: 'unit-50rt',
    label: 'Young Han Solo',
    full: `Young Han Solo deals +5% more damage per Relic Amplifier level on all allies until the end of battle.`
  },
  {
    id: 'unit-iden',
    label: 'Iden Versio',
    full: `At the start of the encounter Iden gains Taunt, which can't be dispelled until she is defeated. Whenever an non-Droid Imperial Trooper is defeated she gains Taunt, which can't be dispelled until she is defeated.`
  },
  {
    id: 'unit-barriss',
    label: 'Barriss',
    full: `Whenever Barriss uses a Special ability, all Jedi allies gain 15% Max Protection (stacking, max 150%) for the rest of the encounter. Whenever she gains Turn Meter, all allies gain 10% Max Health (stacking, max 150%) for rest of the encounter and she dispels all buffs from a random enemy. Whenever Barriss is critically hit all Jedi allies gains 10% Critical Damage (stacking) for the rest of the encounter.`
  },
  {
    id: 'unit-sth',
    label: 'Stormtrooper Han',
    full: `Whenever Stormtrooper Han gains Taunt all allies gain Protection Up (50%) for 3 turns. While Stormtrooper Han is Taunting, he gains 100% counter chance and Defense, and is immune to critical hits. Whenever Stormtrooper Han removes Turn Meter from an enemy reset Draw Fire's cooldown. All allies have +50% Offense while Stormtrooper Han's Health is above 50%, and all allies gain 70% Defense when his Health is below 50%.`
  },
  {
    id: 'unit-bossk',
    label: 'Bossk',
    full: `Whenever Bossk uses a Basic ability, Bossk gains Protection Up (50%, stacking) for 2 turns and 50% Turn Meter. Whenever Bossk damages an enemy using a Special ability, inflict Fear for 1 turn on that enemy, which can't be evaded or resisted.`
  },
  {
    id: 'unit-geo-soldier',
    label: 'Geo Soldier',
    full: `Whenever Geonosian Soldier critically hits an enemy, Geonosian Soldier gains 10% Critical Damage, Defense Penetration, and Offense (stacking, max 200%) until the end of battle.`
  },
]