import { day1 } from './day1';
import { day2 } from './day2';
import { day3 } from './day3';
import { day4 } from './day4';
import { day5 } from './day5';
import { day6 } from './day6';
import { day7 } from './day7';
import { day8 } from './day8';
import { day9 } from './day9';
import { day10 } from './day10';
import { day11 } from './day11';
import { day12 } from './day12';
import { day13 } from './day13';
import { day14 } from './day14';

const gold = [
  day1, day2, day3, day4, day5, day6, day7, day8, day9, day10, day11, day12, day13, day14
]

export const goldProgress = [
  { id: 101, qty: 2, completed: 0 },
  { id: 102, qty: 2, completed: 0 },
  { id: 103, qty: 2, completed: 0 },
  { id: 104, qty: 2, completed: 0 },
  { id: 105, qty: 2, completed: 0 },
  { id: 106, qty: 2, completed: 0 },
  { id: 107, qty: 2, completed: 0 },
  { id: 108, qty: 2, completed: 0 },
  { id: 109, qty: 2, completed: 0 },
  { id: 110, qty: 1, completed: 0 },
  { id: 111, qty: 1, completed: 0 },
  { id: 112, qty: 2, completed: 0 },

  { id: 201, qty: 2, completed: 0 },
  { id: 202, qty: 2, completed: 0 },
  { id: 203, qty: 2, completed: 0 },
  { id: 204, qty: 2, completed: 0 },
  { id: 205, qty: 2, completed: 0 },
  { id: 206, qty: 1, completed: 0 },
  { id: 207, qty: 1, completed: 0 },
  { id: 208, qty: 2, completed: 0 },
  { id: 209, qty: 2, completed: 0 },
  { id: 210, qty: 2, completed: 0 },
  { id: 211, qty: 2, completed: 0 },
  { id: 212, qty: 2, completed: 0 },
  { id: 213, qty: 1, completed: 0 },
  { id: 214, qty: 2, completed: 0 },

  { id: 301, qty: 2, completed: 0 },
  { id: 302, qty: 2, completed: 0 },
  { id: 303, qty: 4, completed: 0 },
  { id: 304, qty: 2, completed: 0 },
  { id: 305, qty: 1, completed: 0 },
  { id: 306, qty: 2, completed: 0 },
  { id: 307, qty: 4, completed: 0 },
  { id: 308, qty: 2, completed: 0 },
  { id: 309, qty: 2, completed: 0 },
  { id: 310, qty: 2, completed: 0 },
  { id: 311, qty: 1, completed: 0 },
  { id: 312, qty: 2, completed: 0 },
  { id: 313, qty: 2, completed: 0 },

  { id: 401, qty: 2, completed: 0 },
  { id: 402, qty: 2, completed: 0 },
  { id: 403, qty: 4, completed: 0 },
  { id: 404, qty: 2, completed: 0 },
  { id: 405, qty: 1, completed: 0 },
  { id: 406, qty: 1, completed: 0 },
  { id: 407, qty: 4, completed: 0 },
  { id: 408, qty: 2, completed: 0 },
  { id: 409, qty: 2, completed: 0 },
  { id: 410, qty: 2, completed: 0 },
  { id: 411, qty: 1, completed: 0 },
  { id: 412, qty: 2, completed: 0 },

  { id: 501, qty: 4, completed: 0 },
  { id: 502, qty: 4, completed: 0 },
  { id: 503, qty: 2, completed: 0 },
  { id: 504, qty: 2, completed: 0 },
  { id: 505, qty: 2, completed: 0 },
  { id: 506, qty: 1, completed: 0 },
  { id: 507, qty: 1, completed: 0 },
  { id: 508, qty: 2, completed: 0 },
  { id: 509, qty: 2, completed: 0 },
  { id: 510, qty: 1, completed: 0 },
  { id: 511, qty: 1, completed: 0 },
  { id: 512, qty: 2, completed: 0 },

  { id: 601, qty: 4, completed: 0 },
  { id: 602, qty: 4, completed: 0 },
  { id: 603, qty: 2, completed: 0 },
  { id: 604, qty: 2, completed: 0 },
  { id: 605, qty: 2, completed: 0 },
  { id: 606, qty: 1, completed: 0 },
  { id: 607, qty: 1, completed: 0 },
  { id: 608, qty: 2, completed: 0 },
  { id: 609, qty: 2, completed: 0 },
  { id: 610, qty: 1, completed: 0 },
  { id: 611, qty: 2, completed: 0 },
  { id: 612, qty: 2, completed: 0 },

  { id: 701, qty: 4, completed: 0 },
  { id: 702, qty: 2, completed: 0 },
  { id: 703, qty: 2, completed: 0 },
  { id: 704, qty: 2, completed: 0 },
  { id: 705, qty: 1, completed: 0 },
  { id: 706, qty: 4, completed: 0 },
  { id: 707, qty: 4, completed: 0 },
  { id: 708, qty: 2, completed: 0 },
  { id: 709, qty: 1, completed: 0 },
  { id: 710, qty: 2, completed: 0 },
  { id: 711, qty: 2, completed: 0 },
  { id: 711, qty: 2, completed: 0 },
  { id: 713, qty: 2, completed: 0 },

  { id: 801, qty: 4, completed: 0 },
  { id: 802, qty: 2, completed: 0 },
  { id: 803, qty: 2, completed: 0 },
  { id: 804, qty: 2, completed: 0 },
  { id: 805, qty: 1, completed: 0 },
  { id: 806, qty: 4, completed: 0 },
  { id: 807, qty: 4, completed: 0 },
  { id: 808, qty: 2, completed: 0 },
  { id: 809, qty: 1, completed: 0 },
  { id: 810, qty: 2, completed: 0 },
  { id: 811, qty: 2, completed: 0 },
  { id: 812, qty: 2, completed: 0 },
  { id: 813, qty: 2, completed: 0 },
  { id: 814, qty: 2, completed: 0 },

  { id: 901, qty: 4, completed: 0 },
  { id: 902, qty: 4, completed: 0 },
  { id: 903, qty: 2, completed: 0 },
  { id: 904, qty: 1, completed: 0 },
  { id: 905, qty: 1, completed: 0 },
  { id: 906, qty: 2, completed: 0 },
  { id: 907, qty: 2, completed: 0 },
  { id: 908, qty: 2, completed: 0 },
  { id: 909, qty: 2, completed: 0 },
  { id: 910, qty: 2, completed: 0 },
  { id: 911, qty: 2, completed: 0 },
  { id: 912, qty: 2, completed: 0 },
  { id: 913, qty: 2, completed: 0 },

  { id: 1001, qty: 4, completed: 0 },
  { id: 1002, qty: 4, completed: 0 },
  { id: 1003, qty: 2, completed: 0 },
  { id: 1004, qty: 1, completed: 0 },
  { id: 1005, qty: 2, completed: 0 },
  { id: 1006, qty: 2, completed: 0 },
  { id: 1007, qty: 2, completed: 0 },
  { id: 1008, qty: 2, completed: 0 },
  { id: 1009, qty: 2, completed: 0 },
  { id: 1010, qty: 2, completed: 0 },
  { id: 1011, qty: 2, completed: 0 },
  { id: 1012, qty: 2, completed: 0 },
  { id: 1013, qty: 2, completed: 0 },

  { id: 1101, qty: 2, completed: 0 },
  { id: 1102, qty: 2, completed: 0 },
  { id: 1103, qty: 2, completed: 0 },
  { id: 1104, qty: 2, completed: 0 },
  { id: 1105, qty: 2, completed: 0 },
  { id: 1106, qty: 2, completed: 0 },
  { id: 1107, qty: 2, completed: 0 },
  { id: 1108, qty: 2, completed: 0 },
  { id: 1109, qty: 2, completed: 0 },
  { id: 1110, qty: 2, completed: 0 },
  { id: 1111, qty: 2, completed: 0 },
  { id: 1112, qty: 2, completed: 0 },

  { id: 1201, qty: 2, completed: 0 },
  { id: 1202, qty: 2, completed: 0 },
  { id: 1203, qty: 2, completed: 0 },
  { id: 1204, qty: 2, completed: 0 },
  { id: 1205, qty: 2, completed: 0 },
  { id: 1206, qty: 2, completed: 0 },
  { id: 1207, qty: 2, completed: 0 },
  { id: 1208, qty: 2, completed: 0 },
  { id: 1209, qty: 2, completed: 0 },
  { id: 1210, qty: 2, completed: 0 },
  { id: 1211, qty: 2, completed: 0 },
  { id: 1212, qty: 2, completed: 0 },

  { id: 1301, qty: 3, completed: 0 },
  { id: 1302, qty: 1, completed: 0 },
  { id: 1303, qty: 2, completed: 0 },
  { id: 1304, qty: 2, completed: 0 },
  { id: 1305, qty: 2, completed: 0 },
  { id: 1306, qty: 2, completed: 0 },
  { id: 1307, qty: 2, completed: 0 },
  { id: 1308, qty: 2, completed: 0 },
  { id: 1309, qty: 2, completed: 0 },
  { id: 1310, qty: 2, completed: 0 },
  { id: 1311, qty: 2, completed: 0 },
  { id: 1312, qty: 2, completed: 0 },
  { id: 1313, qty: 2, completed: 0 },

  { id: 1401, qty: 4, completed: 0 },
  { id: 1402, qty: 2, completed: 0 },
  { id: 1403, qty: 2, completed: 0 },
  { id: 1404, qty: 2, completed: 0 },
  { id: 1405, qty: 2, completed: 0 },
  { id: 1406, qty: 2, completed: 0 },
  { id: 1407, qty: 2, completed: 0 },
  { id: 1408, qty: 2, completed: 0 },
  { id: 1409, qty: 2, completed: 0 },
  { id: 1410, qty: 2, completed: 0 }
];

export {
  gold
}