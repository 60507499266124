/*
Accuracy Down
Cad Bane
Doctor Aphra
Eighth Brother
Seventh Sister
Scout Trooper
*/

const sector4 = [
  {
    title: 'STAP',
    text: 'Win 10 battles with STAP surviving',
    keycards: 10,
    teams: [  
      {
        videoThumb: 'WO73F8CRelM?t=178&si=HP5vkUHJNKILGTJj',
        feats: [
          { name: 'STAP', qty: 1 },
          { name: 'Accuracy Down', qty: 5 }
        ],
        toons: [
          { image: 'dash', name: 'Dash'  },
          { image: 'stap', name: 'STAP', main: true },
          { image: 'eighth-brother', name: '8th Bro'},
          { image: 'thrawn', name: 'Thrawn'},
          { image: 'gideon', name: 'Gideon' },
        ]
      }
    ]
  },
  {
    title: 'Shield Up',
    text: 'Gain Shield Up 50 times',
    keycards: 10,
    teams: [
      {
        name: 'Cheese',
        videoThumb: 'rlb_tlMtOlE?t=612&si=SO941edvOWdpoSkN',
        feats: [
          { name: 'Shield Up', qty: 4 },
          { name: 'Accuracy Down', qty: 20 },
        ],
        toons: [          
          { image: 'dash', name: 'Dash'  },
          { image: 'phalanx', name: 'Phalanx', main: true },
          { image: 'tarpals', name: 'Tarpals' },
          { image: 'thrawn', name: 'Thrawn' },
          { image: 'cad', name: 'Cad' },
        ]
      },
      {
        videoThumb: 'WO73F8CRelM?t=394&si=ZBidmofNIQ4lTYok',
        feats: [
          { name: 'Shield Up', qty: 4 },
        ],
        global: [
          { name: 'Gungans' }
        ],
        toons: [          
          { image: 'rey', name: 'Rey' },
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx', main: true },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
      {
        feats: [
          { name: 'Shield Up', qty: 5 },
        ],
        global: [
          { name: 'Gungans' }
        ],
        toons: [          
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx', main: true },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
          { image: 'jar-jar', name: 'Jar Jar' },
        ]
      },
    ]
  },
  {
    title: 'Accuracy Down',
    text: 'Attempt to inflict Accuracy Down 50 times',
    keycards: 10,
    teams: [
      {
        name: 'Cheese',
        videoThumb: 'rlb_tlMtOlE?t=612&si=SO941edvOWdpoSkN',
        feats: [
          { name: 'Shield Up', qty: 4 },
          { name: 'Accuracy Down', qty: 20 },
        ],
        toons: [          
          { image: 'dash', name: 'Dash'  },
          { image: 'phalanx', name: 'Phalanx', main: true },
          { image: 'tarpals', name: 'Tarpals' },
          { image: 'thrawn', name: 'Thrawn' },
          { image: 'cad', name: 'Cad' },
        ]
      },
      {
        videoThumb: 'WO73F8CRelM?t=314&si=jD6hjK2dDK6j6WVw',
        feats: [
          { name: 'Accuracy Down', qty: 10 }
        ],
        toons: [
          { image: 'reva', name: 'Reva'  },
          { image: 'seventh-sister', name: '7th Sis', main: true },
          { image: 'eighth-brother', name: '8th Bro', main: true},
          { image: 'gi', name: 'Grand Inquisitor'},
          { image: 'fifth-brother', name: '5h Bro' },
        ]
      },
      {
        videoThumb: 'WO73F8CRelM?t=258&si=aEAgeF-1BB4OSFnu',
        feats: [
          { name: 'Accuracy Down', qty: 10 }
        ],
        toons: [
          { image: 'palpatine', name: 'Palpatine' },
          { image: 'seventh-sister', name: '7th Sis', main: true },
          { image: 'eighth-brother', name: '8th Bro', main: true},
          { image: 'malak', name: 'Malak' },
          { image: 'stap', name: 'STAP' },
        ]
      },
      {
        videoThumb: 'WO73F8CRelM?t=355&si=nmOWXEZJ2WKkQ05k',
        feats: [
          { name: 'Accuracy Down', qty: 10 }
        ],
        toons: [
          { image: 'bossk', name: 'Bossk' },
          { image: 'greef', name: 'Greef'  },
          { image: 'bam', name: 'Beskar Mando' },
          { image: 'cad', name: 'Cad Bane', main: true  },
          { image: 'mando', name: 'Mandalorian' }
        ]
      },
    ]
  },
  {
    title: 'Rebels',
    text: 'Win 10 battles with a full squad of Rebels',
    keycards: 10,
    teams: [  
      {
        videoThumb: 'WO73F8CRelM?t=110&si=s65dxp23IKJivwkt',
        feats: [
          { name: 'Rebels', qty: 1 },
        ],
        global: [
          { name: 'Captain Rex' }
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'chewie', name: 'Chewie' },
          { image: 'han', name: 'Han' },
          { image: 'threepio', name: 'Threepio' },
          { image: 'captain-rex', name: 'Captain Rex' }
        ]
      },
      {
        videoThumb: 'WO73F8CRelM?t=138&si=go_I6-W5pUiuwiDT',
        feats: [
          { name: 'Rebels', qty: 1 },
        ],
        global: [
          { name: 'Captain Rex' }
        ],
        toons: [
          { image: 'mothma', name: 'Mothma' },
          { image: 'biggs', name: 'Biggs' },
          { image: 'kyle', name: 'Kyle' },
          { image: 'pao', name: 'Pao' },
          { image: 'captain-rex', name: 'Captain Rex' }
        ]
      }, 
      {
        videoThumb: '',
        feats: [
          { name: 'Rebels', qty: 1 },
        ],
        global: [
          { name: 'Captain Rex' }
        ],
        toons: [
          { image: 'saw', name: 'Saw' },
          { image: 'baze', name: 'Baze' },
          { image: 'chirrut', name: 'Chirrut' },
          { image: 'cara', name: 'Cara' },
          { image: 'captain-rex', name: 'Captain Rex' }
        ]
      }, 
      {
        videoThumb: '',
        feats: [
          { name: 'Rebels', qty: 1 },
        ],
        global: [
          { name: 'Captain Rex' }
        ],
        toons: [
          { image: 'gl-leia', name: 'Leia Organa' },
          { image: 'ben', name: 'Old Ben' },
          { image: 'r2', name: 'R2' },
          { image: 'drogan', name: 'Drogan' },
          { image: 'captain-rex', name: 'Captain Rex' }
        ]
      },
      {
        videoThumb: 'WO73F8CRelM?t=74&si=TVpj8sb2ChzwU9Hl',
        feats: [
          { name: 'Rebels', qty: 1 },
        ],
        toons: [
          { image: 'admiralraddus', name: 'Raddus' },
          { image: 'jyn', name: 'Jyn' },
          { image: 'cassian', name: 'Cassian' },
          { image: 'pathfinder', name: 'Pathfinder' },
          { image: 'k2', name: 'K2' }
        ]
      },
    ]
  },
  {
    title: 'Mini Boss',
    text: 'Battle vs Grievous with Bo-Katan Mandalor surviving and with Dark Side only',
    keycards: 9,
    teams: [
      {
        name: 'Bo-Katan',
        videoThumb: 'vvx-6CZvwOw?t=81&si=Z75pcYmMQ9gekDOz',
        toons: [
          { image: 'bo-mandalor', name: 'Bo Mandalor', main: true },
          { image: 'bam', name: 'Beskar Mando' },
          { image: 'ig-12', name: 'IG-12' },
          { image: 'paz', name: 'Paz'},
          { image: 'armorer', name: 'Armorer' }
        ]
      },
      {
        name: 'Dark Side',
        videoThumb: 'vvx-6CZvwOw?t=145&si=9Ql1wMCEjc8dGTPD',
        toons: [
          { image: 'veers', name: 'Veers'  },
          { image: 'malak', name: 'Malak' },
          { image: 'gideon', name: 'Gideon' },
          { image: 'piett', name: 'Piett'},
          { image: 'range', name: 'Range' }
        ]
      }
    ]
  },
  {
    title: 'Final Boss',
    text: 'Battle vs Boss Nass with Leia Organa surviving and without Galagtic Legends',
    keycards: 11,
    teams: [
      {
        name: 'Leia Organa',
        videoThumb: 'vvx-6CZvwOw?t=270&si=RIACiK6OeaRVFwbP',
        feats: [
          { name: 'Rebels', qty: 1 },
        ],
        toons: [
          { image: 'gl-leia', name: 'Leia Organa', main: true },
          { image: 'ben', name: 'Old Ben' },
          { image: 'r2', name: 'R2' },
          { image: 'drogan', name: 'Drogan' },
          { image: 'captain-rex', name: 'Captain Rex' }
        ]
      },
      {
        name: 'No GLs',
        videoThumb: 'vvx-6CZvwOw?t=373&si=uW8IoaoRoM-uAo_K',
        toons: [
          { image: 'padme', name: 'Padme'},
          { image: 'ahsoka', name: 'Ahsoka' },
          { image: 'gk', name: 'General Kenobi' },
          { image: 'jka', name: 'Jedi Ani'},
          { image: 'shaak-ti', name: 'Shaak Ti' }
        ]
      }
    ]
  }
];

export {
  sector4
}