const day6 = [
  {
    title: `Sector 5 - Battles`,
    teams: [
      {
        id: 601,
        name: '2x',
        videoThumb: '3Mn7qzQfLbo?t=59&si=ZyfW1xAJWR3bkuIA',
        feats: [
          { name: 'Phoenix', qty: 5 },
          { name: 'Evasion Down', qty: 10 },
        ],
        global: [
          { name: 'Captain Rex' }
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'chewie', name: 'Chewie' },
          { image: 'han', name: 'Han' },
          { image: 'threepio', name: 'Threepio', main: true  },
          { image: 'captain-rex', name: 'Captain Rex' }
        ]
      },
      {
        id: 602,
        name: '2x',
        videoThumb: '3Mn7qzQfLbo?t=194&si=NNeYF3cPXbXbuQlS',
        feats: [
          { name: 'Phoenix', qty: 5 },
          { name: 'Qui-Gons', qty: 1 },
        ],
        global: [
          { name: 'Captain Rex' },
          { name: 'Master & Padawn' }
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'qgj', name: 'QGJ' },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
          { image: 'captain-rex', name: 'Captain Rex', main: true }
        ]
      },
    ]
  },
  {
    title: `Sector 5 - Mini Boss`,
    boss: 'bane',
    teams: [
      {
        id: 603,
        name: 'Veers',
        videoThumb: '4KDS7QQnH5A?t=81&si=fqDdZ1lqPuOA_ZYV',
        toons: [
          { image: 'veers', name: 'Veers' },
          { image: 'gideon', name: 'Gideon' },
          { image: 'range', name: 'Range' },
          { image: 'piett', name: 'Piett' },
          { image: 'malak', name: 'Malak' }
        ]
      },
      {
        id: 604,
        name: 'Light Side',
        videoThumb: '4KDS7QQnH5A?t=205&si=NazoM78Zb2fTDlbd',
        toons: [
          { image: 'gas', name: 'General Skywalker' },
          { image: 'arc', name: 'ARC Trooper' },
          { image: 'rex', name: 'Rex' },
          { image: 'echo', name: 'Echo' },
          { image: 'fives', name: 'Fives' }
        ]
      }
    ]
  },
  
  {
    title: `Sector 5 - Battles`,
    teams: [
      {
        id: 605,
        name: '4x',
        videoThumb: '3Mn7qzQfLbo?t=97&si=YL_StWg-WkJd3whf',
        feats: [
          { name: 'Bounty Hunters', qty: 5 },
          { name: 'Evasion Down', qty: 5 },
        ],
        toons: [
          { image: 'bossk', name: 'Bossk' },
          { image: 'zam', name: 'Zam', main: true  },
          { image: 'greef', name: 'Greef'},
          { image: 'mando', name: 'Mandalorian' },
          { image: 'bam', name: 'Beskar Mando' }
        ]
      },
      {
        id: 606,
        name: '2x',
        videoThumb: '3Mn7qzQfLbo?t=330&si=fHK2uOxjsmUYCdkd',
        toons: [
          { image: 'rey', name: 'Rey' },
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx' },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
      {
        id: 607,
        name: '2x',
        videoThumb: '3Mn7qzQfLbo?t=377&si=3MCqUcG0XfY4TnrK',
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon', main: true },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'stormtrooper', name: 'Stormtrooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'gideon', name: 'Gideon' }
        ]
      },
      {
        id: 608,
        name: '2x',
        videoThumb: '3Mn7qzQfLbo?t=194&si=NNeYF3cPXbXbuQlS',
        feats: [
          { name: 'Phoenix', qty: 5 },
          { name: 'Qui-Gons', qty: 1 },
        ],
        global: [
          { name: 'Captain Rex' },
          { name: 'Master & Padawn' }
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'qgj', name: 'QGJ' },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
          { image: 'captain-rex', name: 'Captain Rex', main: true }
        ]
      },
    ]
  },
  {
    title: `Sector 5 - Final Boss`,
    boss: 'saw',
    teams: [
      {
        id: 610,
        name: 'Hokey Religions',
        videoThumb: '4KDS7QQnH5A?t=365&si=NJSsCGqU-XrG2agj',
        feats: [
          { name: 'Phoenix', qty: 1 },
          { name: 'Evasion Down', qty: 5 },
        ],
        global: [
          { name: 'Captain Rex' }
        ],
        toons: [
          { image: 'dash', name: 'Dash' },
          { image: 'han', name: 'Han' },
          { image: 'chewie', name: 'Chewie' },
          { image: 'threepio', name: 'Threepio' },
          { image: 'captain-rex', name: 'Captain Rex'}
        ]
      }
    ]
  },
  {
    title: 'Sector 5 - Progress',
    progress: [
      { name: 'Evasion Down', current: 50, target: 50 },
      { name: 'Phoenix', current: 35, target: 35 },
      { name: 'Qui-Gons', current: 6, target: 10 },
      { name: 'Bounty Hunters', current: 30, target: 35 }
    ]
  },



  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Jedi Cal', skip: true },
      { name: 'Master & Padawn', complete: true },  
      { name: 'Captain Rex', complete: true  },
      { name: 'Challenge Path', complete: true },    
      { name: 'Gungans', current: 10, target: 15 },
      { name: 'Dark Gideon', current: 16, target: 35 },
    ]
  }
];

export {
  day6
}