const day9 = [  
  {
    title: `Sector 5 - Battles`,
    teams: [
      {
        id: 901,
        name: '4x',
        videoThumb: '3Mn7qzQfLbo?t=59&si=ZyfW1xAJWR3bkuIA',
        feats: [
          { name: 'Phoenix', qty: 5 },
          { name: 'Evasion Down', qty: 10 },
        ],
        global: [
          { name: 'Captain Rex' }
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'chewie', name: 'Chewie' },
          { image: 'han', name: 'Han' },
          { image: 'threepio', name: 'Threepio', main: true  },
          { image: 'captain-rex', name: 'Captain Rex' }
        ]
      },
      {
        id: 902,
        name: '4x',
        videoThumb: '3Mn7qzQfLbo?t=97&si=YL_StWg-WkJd3whf',
        feats: [
          { name: 'Bounty Hunters', qty: 5 },
          { name: 'Evasion Down', qty: 5 },
        ],
        toons: [
          { image: 'bossk', name: 'Bossk' },
          { image: 'zam', name: 'Zam', main: true  },
          { image: 'greef', name: 'Greef'},
          { image: 'mando', name: 'Mandalorian' },
          { image: 'bam', name: 'Beskar Mando' }
        ]
      },
      {
        id: 903,
        name: '2x',
        toons: [
          { image: 'cup', name: 'CUP' },
        ]
      },
    ]
  },
  {
    title: `Sector 5 - Mini Boss`,
    boss: 'bane',
    teams: [
      {
        id: 904,
        name: 'Veers',
        videoThumb: '4KDS7QQnH5A?t=81&si=fqDdZ1lqPuOA_ZYV',
        toons: [
          { image: 'veers', name: 'Veers' },
          { image: 'gideon', name: 'Gideon' },
          { image: 'range', name: 'Range' },
          { image: 'piett', name: 'Piett' },
          { image: 'malak', name: 'Malak' }
        ]
      },
      {
        id: 905,
        name: 'Light Side',
        videoThumb: '4KDS7QQnH5A?t=205&si=NazoM78Zb2fTDlbd',
        toons: [
          { image: 'gas', name: 'General Skywalker' },
          { image: 'arc', name: 'ARC Trooper' },
          { image: 'rex', name: 'Rex' },
          { image: 'echo', name: 'Echo' },
          { image: 'fives', name: 'Fives' }
        ]
      }
    ]
  },
  {
    title: 'Sector 5 - Progress',
    progress: [
      { name: 'Phoenix', skip: true },
      { name: 'Qui-Gons', skip: true },
      { name: 'Evasion Down', current: 40, target: 50 },
      { name: 'Bounty Hunters', current: 20, target: 35 },
    ]
  },


  
  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Jedi Cal', skip: true },
      { name: 'Dark Gideon', skip: true },    
      { name: 'Captain Rex', complete: true },
      { name: 'Challenge Path', current: 225, target: 250 },  
      { name: 'Gungans', current: 4, target: 15 },
      { name: 'Master & Padawn', current: 8, target: 15 },
    ]
  }
];

export {
  day9
}