const day1 = [
  {
    title: `Sector 1 - Battles`,
    teams: [
      {
        id: 101,
        name: '2x',
        videoThumb: 'skxEXfuDRPk?t=207&si=gXgUmiT_kQsIPRNR',
        feats: [
          { name: 'Mandalorians', qty: 5 },
          { name: 'Evasion Up', qty: 5 },
        ],
        global: [
          { name: 'Master & Padawan' },
          { name: 'Captain Rex' },
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'bam', name: 'Beskar Mandalorian', main: true },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
          { image: 'captain-rex', name: 'Captain Rex' },
        ]
      },
      {
        id: 102,
        name: '2x',
        videoThumb: 'skxEXfuDRPk?t=151&si=F4HEWc6A8Osv6XRD',
        feats: [
          { name: 'Evasion Up', qty: 10 },
        ],
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [          
          { image: 'hunter', name: 'Hunter', main: true },
          { image: 'bb-echo', name: 'Echo', main: true },
          { image: 'captain-rex', name: 'Captain Rex' },
          { image: 'wrecker', name: 'wrecker'},
          { image: 'tech', name: 'tech' }
        ]
      },
      {
        id: 103,
        name: '2x',
        videoThumb: 'skxEXfuDRPk?t=357&si=wx1bQgi1HdK9uGmv',
        feats: [
          { name: 'Ewoks', qty: 5 },
        ],
        global: [
          { name: 'Master & Padawan' },
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'paploo', name: 'Paploo', main: true },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
          { image: 'han', name: 'Han Solo'},
        ]
      },
      {
        id: 104,
        name: '4x',
        videoThumb: 'skxEXfuDRPk?t=407&si=etu7AWNp5VO5aA5_',
        feats: [
          { name: 'Resistance', qty: 1 },
        ],
        toons: [
          { image: 'finn', name: 'Finn' },
          { image: 'hero-finn', name: 'Hero Finn' },
          { image: 'hero-poe', name: 'Hero Poe' },
          { image: 'zorii', name: 'Zorii' },
          { image: 'poe', name: 'Poe' },
        ]
      },
      {
        id: 105,
        name: '2x',
        videoThumb: 'skxEXfuDRPk?t=427&si=lFzYzK3536IX2cTb',
        feats: [
          { name: 'Resistance', qty: 1 },
        ],
        toons: [
          { image: 'rjt', name: 'Rey (JT)' },
          { image: 'r2', name: 'R2' },
          { image: '3po', name: 'C-3PO' },
          { image: 'bb', name: 'BB-8' },
          { image: 'resistance-trooper', name: 'Trooper' },
        ]
      },
      {
        id: 106,
        name: '2x',
        videoThumb: 'skxEXfuDRPk?t=557&si=gaaMdR2WMOwzMokn',
        global: [
          { name: 'Gungans' },
        ],
        toons: [
          { image: 'dash', name: 'Dash' },
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx' },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
      {
        id: 107,
        name: '4x',
        videoThumb: 'skxEXfuDRPk?t=586&si=H6NKqMNq7kLdJw9B',
        global: [
          { name: 'Dark Gideon' },
        ],
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon' },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'stormtrooper', name: 'Stormtrooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'gideon', name: 'Gideon' }
        ]
      },
    ]
  },
  {
    title: `Sector 1 - Mini Boss`,
    boss: 'cere',
    teams: [
      {
        id: 110,
        name: `Scout Trooper`,
        videoThumb: '83bG36VxlZw?t=394&si=JozZMj4Fa6XAQ7Ao',
        toons: [
          { image: 'veers', name: 'Veers' },
          { image: 'scout-trooper', name: 'Scout Trooper', main: true },
          { image: 'piett', name: 'Piett' },
          { image: 'range', name: 'Range Trooper' },
          { image: 'starck', name: 'Starck' }
        ]
      },
      {
        id: 111,
        name: `Light Side`,
        videoThumb: '83bG36VxlZw?t=171&si=mqCm6EPwkgemfUl7',
        feats: [
          { name: 'Evasion Up', qty: 10 },
        ],
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [          
          { image: 'hunter', name: 'Hunter' },
          { image: 'bb-echo', name: 'Echo' },
          { image: 'captain-rex', name: 'Captain Rex' },
          { image: 'wrecker', name: 'wrecker'},
          { image: 'tech', name: 'tech' }
        ]
      }
    ]
  },
  {
    title: 'Sector 1 - Progress',
    progress: [
      { name: 'Evasion Up', current: 30, target: 50 },
      { name: 'Mandalorians', current: 10, target: 35 },
      { name: 'Ewoks', current: 10, target: 35 },
      { name: 'Resistance', current: 6, target: 10 }
    ]
  },

  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Jedi Cal', skip: true },
      { name: 'Challenge Path', current: 50, target: 250 },      
      { name: 'Captain Rex', current: 4, target: 15 },
      { name: 'Gungans', current: 2, target: 15 },
      { name: 'Dark Gideon', current: 8, target: 35 },
      { name: 'Master & Padawn', current: 4, target: 15 },
    ]
  }
];

export {
  day1
}