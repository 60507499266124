const day8 = [  
  {
    title: `Sector 4 - Battles`,
    teams: [
      {
        id: 801,
        name: '4x',
        videoThumb: 'WO73F8CRelM?t=178&si=HP5vkUHJNKILGTJj',
        feats: [
          { name: 'STAP', qty: 1 }
        ],
        toons: [
          { image: 'dash', name: 'Dash'  },
          { image: 'stap', name: 'STAP', main: true },
          { image: 'eighth-brother', name: '8th Bro'},
          { image: 'thrawn', name: 'Thrawn'},
          { image: 'gideon', name: 'Gideon' },
        ]
      },
      {
        id: 802,
        name: '2x',
        videoThumb: 'WO73F8CRelM?t=110&si=s65dxp23IKJivwkt',
        feats: [
          { name: 'Rebels', qty: 1 },
        ],
        global: [
          { name: 'Captain Rex' }
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'chewie', name: 'Chewie' },
          { image: 'han', name: 'Han' },
          { image: 'threepio', name: 'Threepio' },
          { image: 'captain-rex', name: 'Captain Rex' }
        ]
      },
      {
        id: 803,
        name: '2x',
        videoThumb: 'WO73F8CRelM?t=138&si=go_I6-W5pUiuwiDT',
        feats: [
          { name: 'Rebels', qty: 1 },
        ],
        global: [
          { name: 'Captain Rex' }
        ],
        toons: [
          { image: 'mothma', name: 'Mothma' },
          { image: 'biggs', name: 'Biggs' },
          { image: 'kyle', name: 'Kyle' },
          { image: 'pao', name: 'Pao' },
          { image: 'captain-rex', name: 'Captain Rex' }
        ]
      },
      {
        id: 804,
        name: '2x',
        videoThumb: 'WO73F8CRelM?t=74&si=TVpj8sb2ChzwU9Hl',
        feats: [
          { name: 'Rebels', qty: 1 },
        ],
        toons: [
          { image: 'admiralraddus', name: 'Raddus' },
          { image: 'jyn', name: 'Jyn' },
          { image: 'cassian', name: 'Cassian' },
          { image: 'pathfinder', name: 'Pathfinder' },
          { image: 'k2', name: 'K2' }
        ]
      },
    ]
  },
  {
    title: `Sector 4 - Final Boss`,
    boss: 'boss-nass',
    teams: [
      {
        id: 805,
        name: 'No GLs',
        videoThumb: 'vvx-6CZvwOw?t=373&si=uW8IoaoRoM-uAo_K',
        toons: [
          { image: 'padme', name: 'Padme'},
          { image: 'ahsoka', name: 'Ahsoka' },
          { image: 'gk', name: 'General Kenobi' },
          { image: 'jka', name: 'Jedi Ani'},
          { image: 'shaak-ti', name: 'Shaak Ti' }
        ]
      }
    ]
  },
  {
    title: 'Sector 4 - Progress',
    progress: [
      { name: 'Shield Up', skip: true },
      { name: 'Accuracy Down', skip: true },
      { name: 'STAP', current: 8, target: 10 },
      { name: 'Rebels', current: 10, target: 10 },
    ]
  },



  
  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Jedi Cal', skip: true },
      { name: 'Dark Gideon', skip: true },    
      { name: 'Captain Rex', complete: true },
      { name: 'Challenge Path', current: 200, target: 250 },  
      { name: 'Gungans', current: 4, target: 15 },
      { name: 'Master & Padawn', current: 8, target: 15 },
    ]
  }
];

export {
  day8
}