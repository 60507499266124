export const set15Bonus = [
  {
    id: 'ds-bulk',
    label: '15% Health & Protection',
    full: `At the start of the encounter Dark Side allies gain 15% Max Health and Max Protection for each other Dark Side ally.`
  },
  {
    id: 'ds-potency',
    label: '30% Potency',
    full: `Whenever Dark Side allies start their turn, for each enemy with fewer than 4 debuffs, that ally gains 30% Potency until the end of the turn.`
  },
  {
    id: 'ls-bulk',
    label: '15% Health & Protection',
    full: `At the start of the encounter Light Side allies gain 15% Max Health and Max Protection for each other Light Side ally.`
  },
  {
    id: 'firstorder-stacking',
    label: 'Stacking Offense',
    full: `Whenever a First Order ally scores a critical hit, the Leader gains 10% Offense (stacking, max 200%) until the end of encounter. If the Leader scores a critical hit, they gain an additional 10% Offense, and all other First Order allies recover 15% Health and Protection. Whenever the Leader uses an ability, remove 1% Turn Meter from all other First Order allies. Whenever a First Order ally loses Turn Meter, they gain 10% Critical Damage (stacking) for 2 turns. Whenever a First Order ally attacks a Stunned enemy, they deal 10% Max Health damage to that enemy. This damage can't defeat enemies. Whenever the Leader is not damaged during the enemy's turn, if there are no allies with Damage Immunity, a random First Order ally gains Damage Immunity for 1 turn.`
  },
  {
    id: 'firstorder-400pct-off',
    label: 'Gain 400% Offense',
    full: `At the start of their first turn, First Order allies gain Offense equal to 400% of their current Defense. Then they lose 50% Defense.`
  },
  {
    id: 'inquisitors-assist',
    label: 'Random Assist',
    full: `Whenever an Inquisitorius ally inflicts a stack of Purge, they gain 5% Defense, Offense, and Tenacity (stacking) for 1 turn. Whenever a stack of Purge is consumed or dispelled on an enemy Expose them for 1 turn. Whenever an Inquisitorius ally attacks an enemy inflicted with a stack of Purge, a random other Inquisitorius ally assists, dealing 5% bonus damage per stack of Purge and then they inflict a stack of Purge (max 6) on target enemy, which can't be evaded.`
  },
  {
    id: 'inquisitors-tm',
    label: '15% Turn Meter',
    full: `Whenever Inquisitorius allies start their turn with fewer than 2 buffs, all other allies gain 15% Turn Meter.`
  },
  {
    id: 'gungan-shield',
    label: 'Shield Generator',
    full: `Whenever a Gungan ally is damaged or the allied Shield Generator loses a stack of Plasma Shielding, remove 1 stack of Recharge from the allied Shield Generator. Whenever the allied Shield Generator uses Plasma Pulse, dispel all debuffs from all Gungan allies and inflict Plasma Residue on all enemies for 1 turn, which can't be resisted. If an enemy is already inflicted with Plasma Residue, remove 100% Turn Meter, Daze, Stagger, and Stun them for 1 turn. Whenever a Gungan ally deals damage to a debuffed enemy, they also deal 5% Max Health damage to that enemy. This damage can't defeat enemies.`
  },
  {
    id: 'gr-ignore-taunt',
    label: 'Ignore Taunt',
    full: `Whenever a Galactic Republic ally with Protection Up assists, if there are no enemies with Protection Disruption, inflict it for 1 turn on the target enemy, which can't be dispelled, evaded, or resisted. While Galactic Republic allies have Protection Up they have +50% Accuracy, counter chance, Critical Chance, Critical Damage, and they ignore Taunt.`
  },
  {
    id: 'gr-dmg-reduce',
    label: 'Reduce Received Damage',
    full: `At the start of each turn, if non-Galactic Legend Galactic Republic allies have 50% or more Turn Meter, damage they receive is reduced by 75% until the end of the turn.`
  },
  {
    id: 'unit-reva',
    label: 'Reva',
    full: `Whenever any character is defeated, Third Sister gains Hatred for the rest of the battle or until she is defeated. While Third Sister has Hatred, she also has +100% counter chance and +50% Critical Damage. At the start of Third Sister's turn, all Inquisitorius allies with Taunt dispel all debuffs on themselves, recover 20% Max Health and Max Protection, and gain 20 speed for 2 turns.`
  },
  {
    id: 'unit-fost',
    label: 'FO Stormtrooper',
    full: `At the start of encounter, First Order Storm Trooper gains 100% Max Health and Max Protection, and the allied Leader gains 50% Armor Penetaration and Offense. Whenever Stun expires or is dispelled from an enemy, First Order Storm Trooper gains 10% Defense and Tenacity (stacking), and the ally in the Leader slot gains 10% Critical Damage (stacking) until the end of encounter. Whenever another First Order ally is critically hit, First Order Storm Trooper gains Protection Up (25%, stacking) and Taunts for 2 turns. Whenever First Order Storm Trooper uses The Order Relentless, he also dispels all debuffs from all First Order allies and they recover 50% Health.`
  },
  {
    id: 'unit-slkr',
    label: 'SLKR',
    full: `Supreme Leader Kylo Ren deals 5% more damage per Relic Amplifier level on all allies present at the start of battle.`
  },
  {
    id: 'unit-ninth-sister',
    label: 'Ninth Sister',
    full: `At the start of encounter, all Inquisitorius allies have +200% Max Health and are immune to Ability Block. Ninth Sister deals 20% bonus True damage based on her Max Health with her Basic ability if the target enemy is inflicted with Purge. If any enemy has 6 stacks of Purge when Ninth Sister would be defeated for the first time, she consumes all stacks of Purge from a random enemy with 6 stacks of Purge, recovers 100% Max Health and Max Protection, and removes 50% Turn Meter from all enemies.`
  },
  {
    id: 'unit-fosftp',
    label: 'FOSF Tie Pilot',
    full: `First Order SF TIE Pilot has +100% Potency. While allied Supreme Leader Kylo Ren is active, First Order SF TIE Pilot can't be defeated. While First Order allies have Advantage, they ignore Taunt. Whenever the ally in the Leader slot or First Order SF TIE Pilot is damaged while they have Advantage, Stun that enemy for 1 turn, which can't be resisted. Whenever an enemy is Stunned, First Order SF TIE Pilot's abilities gain 5% bonus damage (stacking, max 200%), he and the ally in the Leader slot gain 10% Max Health until the end of the encounter, and if the ally in the Leader slot is Supreme Leader Kylo Ren, he gains 5% Ultimate Charge. Whenever a Stunned enemy is defeated, they can't be revived, the ally in the Leader slot gains a bonus turn, and the allied Leader and First Order SF TIE Pilot recover 100% Health and Protection.`
  },
  {
    id: 'unit-padme',
    label: 'Padme',
    full: `Whenever a Galactic Republic ally with Courage or Protection Up uses an ability, if there are no enemies with Provoke, a random enemy is inflicted with Provoke for 1 turn. Whenever another Galactic Republic ally recovers Health or Protection, Padmé recovers double that amount. Whenever an enemy targets a Non-Galactic Legend Galactic Republic ally with Protection Up while attacking out of turn, the damage they receive is reduced to 1 and the attacking enemy is Exposed for 1 turn. Whenever a Non-Galactic Legend Galactic Republic ally resists a debuff, they gain a stack of Courage, and whenever they are inflicted with a debuff, they gain Protection Up (10%, stacking) and Tenacity Up for 2 turns.`
  },
  {
    id: 'unit-boss-nass',
    label: 'Boss Nass',
    full: `Whenever Boss Nass dispels a debuff from himself, he and the weakest other Gungan ally gain 5% Max Protection (stacking, max 200%) until the end of encounter and he removes 1 stack of Recharge from the allied Shield Generator. Whenever the enemy is inflicted with Plasma Residue or Frustration, they are Blinded for 1 turn. The first time the allied Shield Generator's Plasma Shield reaches 0, it recovers 10 stacks of Plasma Shield.`
  },
  {
    id: 'unit-tech',
    label: 'Tech',
    full: `Whenever Tech uses an ability, they deal more damage (max 100%) equal to half their Potency percentage. This effect will only trigger if Tech has more than 0% Potency.`
  },
  {
    id: 'unit-luminara',
    label: 'Luminara Unduli',
    full: `The first time Luminara Unduli is defeated, they are revived with 100% Health, gain Protection Over Time (75%) for 2 turns, and take a bonus turn.`
  },
]