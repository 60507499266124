const list = [
    { name: '50R-T', value: '50rt', base_id: '50RT' },
    { name: 'Admiral Raddus', value: 'admiralraddus', base_id: 'ADMIRALRADDUS' },
    { name: 'Admiral Trench', value: 'trench', base_id: 'TRENCH' },
    { name: 'Asajj Ventress', value: 'asajj', base_id: 'ASAJVENTRESS' },
    { name: 'Bastila Shan', value: 'bastila', base_id: 'BASTILASHAN' },
    { name: 'Bo-Katan (Mand\'alor)', value: 'bo-mandalor', base_id: 'MANDALORBOKATAN' },
    { name: 'Boba Fett', value: 'boba', base_id: 'BOBAFETT' },
    { name: 'Boss Nass', value: 'boss-nass', base_id: 'BOSSNASS' },
    { name: 'Bossk', value: 'bossk', base_id: 'BOSSK' },
    { name: 'Captain Phasma', value: 'phasma', base_id: 'PHASMA' },
    { name: 'Carth Onasi', value: 'carth', base_id: 'CARTHONASI' },
    { name: 'Cere Junda', value: 'cere', base_id: 'CEREJUNDA' },
    { name: 'Chief Chirpa', value: 'chirpa', base_id: 'CHIEFCHIRPA' },
    { name: 'Commander Luke Skywalker', value: 'cls', base_id: 'COMMANDERLUKESKYWALKER' },
    { name: 'Count Dooku', value: 'dooku', base_id: 'COUNTDOOKU' },
    { name: 'CT-7567 "Rex"', value: 'rex', base_id: 'CT7567' },
    { name: 'Dark Trooper Moff Gideon', value: 'dt-gideon', base_id: 'MOFFGIDEONS3' },
    { name: 'Darth Maul', value: 'darth-maul', base_id: 'MAUL' },
    { name: 'Darth Malgus', value: 'malgus', base_id: 'DARTHMALGUS' },
    { name: 'Darth Revan', value: 'dr', base_id: 'DARTHREVAN' },
    { name: 'Darth Traya', value: 'traya', base_id: 'DARTHTRAYA' },
    { name: 'Darth Vader', value: 'vader', base_id: 'VADER' },
    { name: 'Dash Rendar', value: 'dash', base_id: 'DASHRENDAR' },
    { name: 'Dengar', value: 'dengar', base_id: 'DENGAR' },
    { name: 'Fifth Brother', value: 'fifth-brother', base_id: 'FIFTHBROTHER' },
    { name: 'Finn', value: 'finn', base_id: 'FINN' },
    { name: 'Emperor Palpatine', value: 'palpatine', base_id: 'EMPERORPALPATINE' },
    { name: 'General Grievous', value: 'gg', base_id: 'GRIEVOUS' },
    { name: 'General Hux', value: 'hux', base_id: 'GENERALHUX' },
    { name: 'General Kenobi', value: 'gk', base_id: 'GENERALKENOBI' },
    { name: 'General Skywalker', value: 'gas', base_id: 'GENERALSKYWALKER' },
    { name: 'General Veers', value: 'veers', base_id: 'VEERS' },
    { name: 'Geonosian Brood Alpha', value: 'geos', base_id: 'GEONOSIANBROODALPHA' },
    { name: 'Grand Inquisitor', value: 'gi', base_id: 'GRANDINQUISITOR' },
    { name: 'Hondo', value: 'hondo', base_id: 'HONDO' },
    { name: 'Hunter', value: 'hunter', base_id: 'BADBATCHHUNTER' },
    { name: 'Iden Versio', value: 'iden', base_id: 'IDENVERSIOEMPIRE' },
    { name: 'Jabba the Hutt', value: 'jabba', base_id: 'JABBATHEHUTT' },
    { name: 'Jango Fett', value: 'jango', base_id: 'JANGOFETT' },
    { name: 'Jedi Knight Revan', value: 'jkr', base_id: 'JEDIKNIGHTREVAN' },
    { name: 'Jedi Knight Luke', value: 'jkl', base_id: 'JEDIKNIGHTLUKE' },
    { name: 'Jedi Master Kenobi', value: 'jmk', base_id: 'JEDIMASTERKENOBI' },
    { name: 'Jedi Master Luke', value: 'jml', base_id: 'GRANDMASTERLUKE' },
    { name: 'Jyn Erso', value: 'jyn', base_id: 'JYNERSO' },
    { name: 'Kelleran Beg', value: 'kelleran', base_id: 'KELLERANBEQ' },
    { name: 'Kylo Ren (Unmasked)', value: 'kru', base_id: 'KYLORENUNMASKED' },
    { name: 'Leia Organa', value: 'leia-organa', base_id: 'GLLEIA' },
    { name: 'Lord Vader', value: 'lv', base_id: 'LORDVADER' },
    { name: 'Mandalorian (Beskar Armor)', value: 'bam', base_id: 'THEMANDALORIANBESKARARMOR' },
    { name: 'Maul', value: 'mando-maul', base_id: 'MAULS7' },
    { name: 'Moff Gideon', value: 'gideon', base_id: 'MOFFGIDEONS1' },
    { name: 'Mon Mothma', value: 'mothma', base_id: 'MONMOTHMA' },
    { name: 'Mother Talzin', value: 'talzin', base_id: 'MOTHERTALZIN' },
    { name: 'Ninth Sister', value: 'ninth-sister', base_id: 'NINTHSISTER' },
    { name: 'Nute Gunray', value: 'nute', base_id: 'NUTEGUNRAY' },
    { name: 'Old Daka', value: 'daka', base_id: 'DAKA' },
    { name: 'Padme Amidala', value: 'padme', base_id: 'PADMEAMIDALA' },
    { name: 'Qi\'ra', value: 'qira', base_id: 'QIRA' },
    { name: 'Qui-Gon Jinn', value: 'qgj', base_id: 'QUIGONJINN' },
    { name: 'Rey', value: 'rey', base_id: 'GLREY' },
    { name: 'Rey (Jedi Training)', value: 'rjt', base_id: 'REYJEDITRAINING' },
    { name: 'Sana Starros', value: 'sana', base_id: 'SANASTARROS' },
    { name: 'Savage Opress', value: 'savage', base_id: 'SAVAGEOPRESS' },
    { name: 'Saw Gerrera', value: 'saw', base_id: 'SAWGERRERA' },
    { name: 'Second Sister', value: 'second-sister', base_id: 'SECONDSISTER' },
    { name: 'Shaak Ti', value: 'shaak', base_id: 'SHAAKTI' },
    { name: 'Sith Eternal Emperor', value: 'see', base_id: 'SITHPALPATINE' },
    { name: 'Stormtrooper Han', value: 'sth', base_id: 'STORMTROOPERHAN' },
    { name: 'Supreme Leader Kylo Ren', value: 'slkr', base_id: 'SUPREMELEADERKYLOREN' },
    { name: 'Tarfful', value: 'tarfful', base_id: 'TARFFUL' },
    { name: 'Third Sister (Reva)', value: 'reva', base_id: 'THIRDSISTER' },
    { name: 'Tusken Chieftain', value: 'tusken-chief', base_id: 'TUSKENCHIEFTAIN' }, 
    { name: `URoRRuR'R'R`, value: 'uror', base_id: 'RORRURRR' }
];

export {
    list
}