const day5 = [  
  {
    title: `Sector 3 - Battles`,
    teams: [
      {
        id: 501,
        name: '4x',
        videoThumb: 'HAzLlLIAVUM?t=51&si=oSiPct3UBoXvfa1P',
        feats: [
          { name: 'Separatists', qty: 1 },
        ],
        toons: [
          { image: 'gg', name: 'Grievous' },
          { image: 'b1', name: 'B1' },
          { image: 'b2', name: 'B2' },
          { image: 'magna', name: 'Magna' },
          { image: 'stap', name: 'STAP' }
        ]
      },
      {
        id: 502,
        name: '4x',
        videoThumb: 'HAzLlLIAVUM?t=304&si=4xcKMbTGA1Z_fmYI',
        feats: [
          { name: 'Accuracy Up', qty: 5 },
        ],
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [
          { image: 'mothma', name: 'Mothma' },
          { image: 'pathfinder', name: 'Scarif Pathfinder', main: true },
          { image: 'biggs', name: 'Biggs' },
          { image: 'pao', name: 'Pao' },
          { image: 'captain-rex', name: 'Captain Rex' },
        ]
      },
      {
        id: 503,
        name: '2x',
        videoThumb: 'HAzLlLIAVUM?t=88&si=bFHQiowoEsT-1VQA',
        feats: [
          { name: 'Separatists', qty: 1 },
        ],
        toons: [
          { image: 'gba', name: 'Brood Alpha' },
          { image: 'poggle', name: 'Poggle' },
          { image: 'sun-fac', name: 'Sun Fac' },
          { image: 'geo-spy', name: 'Geo Spy' },
          { image: 'geo-soldier', name: 'Geo Soldier' }
        ]
      },
    ]
  },
  {
    title: `Sector 3 - Mini Boss`,
    boss: 'bo-mandalore',
    teams: [
      {
        id: 506,
        name: 'GR Jedi',
        videoThumb: 'zBrs1T_iqiw?t=85&si=auWnaDmH-PdBoOwA',
        toons: [
          { image: 'gk', name: 'General Kenobi' },
          { image: 'qgj', name: 'QGJ' },
          { image: 'jka', name: 'Jedi Ani' },
          { image: 'gas', name: 'General Skywalker' },
          { image: 'shaak-ti', name: 'Shaak Ti' }
        ]
      },
      {
        id: 507,
        name: 'Hokey Religions',
        videoThumb: 'zBrs1T_iqiw?t=229&si=It3XITd7FhFYkxdO',
        toons: [
          { image: 'iden', name: 'Iden' },
          { image: 'starck', name: 'Starck' },
          { image: 'range', name: 'Range Trooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'shoretrooper', name: 'Shore Trooper' }
        ]
      }
    ]
  },
  {
    title: 'Sector 3 - Progress',
    progress: [
      { name: 'Han', skip: true },
      { name: 'Adapt or Die', skip: true },
      { name: 'Separatists', current: 8, target: 10 },
      { name: 'Accuracy Up', current: 20, target: 50 },
    ]
  },



  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Jedi Cal', skip: true },
      { name: 'Dark Gideon', skip: true },
      { name: 'Challenge Path', current: 125, target: 250 },      
      { name: 'Captain Rex', current: 12, target: 15 },
      { name: 'Gungans', current: 4, target: 15 },
      { name: 'Master & Padawn', current: 8, target: 15 },
    ]
  }
];

export {
  day5
}