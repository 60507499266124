const day7 = [
  {
    title: `Sector 1 - Datacron Farming`,
    teams: [
      {
        id: 701,
        name: '2x',
        videoThumb: 'CgX6JpCIIdQ?t=43&si=v_Npsu-5rJflelBX',
        feats: [
          { name: 'Mandalorians', qty: 5 },
          { name: 'Evasion Up', qty: 5 },
        ],
        global: [
          { name: 'Master & Padawan' },
          { name: 'Captain Rex' },
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'bam', name: 'Beskar Mandalorian', main: true },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
          { image: 'captain-rex', name: 'Captain Rex' },
        ]
      },
      {
        id: 702,
        name: '2x',
        videoThumb: 'CgX6JpCIIdQ?t=106&si=R4LYfbs89X_aDOmj',
        feats: [
          { name: 'Ewoks', qty: 5 },
        ],
        global: [
          { name: 'Master & Padawan' },
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'paploo', name: 'Paploo', main: true },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
          { image: 'han', name: 'Han Solo' },
        ]
      },
      {
        id: 703,
        name: '2x',
        videoThumb: 'rlb_tlMtOlE?t=122&si=WrwunZKVuCqhIHnq',
        feats: [
          { name: 'Resistance', qty: 1 },
        ],
        toons: [
          { image: 'finn', name: 'Finn' },
          { image: 'hero-finn', name: 'Hero Finn' },
          { image: 'hero-poe', name: 'Hero Poe' },
          { image: 'zorii', name: 'Zorii' },
          { image: 'poe', name: 'Poe' },
        ]
      },
      {
        id: 706,
        name: '2x',
        videoThumb: 'rlb_tlMtOlE?t=801&si=Px4c8DFrt9cjCQvD',
        global: [
          { name: 'Gungans' },
        ],
        toons: [
          { image: 'dash', name: 'Dash' },
          { image: 'boss-nass', name: 'Boss Nass' },
          { image: 'phalanx', name: 'Phalanx' },
          { image: 'boomadier', name: 'Boomadier' },
          { image: 'tarpals', name: 'Tarpals' },
        ]
      },
    ]
  },
  {
    title: 'Sector 1 - Progress',
    progress: [
      { name: 'Evasion Up', complete: true },
      { name: 'Mandalorians', current: 30, target: 35 },
      { name: 'Ewoks', current: 20, target: 35 },
      { name: 'Resistance', current: 8, target: 10 }
    ]
  },


  {
    title: `Sector 2 - Datacron Farming`,
    teams: [
      {
        id: 705,
        name: '2x',
        videoThumb: 'CgX6JpCIIdQ?t=476&si=qlWFMFVuTNm32cpa',
        global: [
          { name: 'Dark Gideon' },
        ],
        feats: [
          { name: 'Imperial Troopers', qty: 5 },
        ],
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon' },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'stormtrooper', name: 'Stormtrooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'gideon', name: 'Gideon' }
        ]
      },
      {
        id: 707,
        name: '2x',
        videoThumb: 'rlb_tlMtOlE?t=366&si=To6xcxHlMCu7TBBg',
        feats: [
          { name: 'Nightsisters', qty: 1 },
        ],
        toons: [
          { image: 'talzin', name: 'Talzin' },
          { image: 'merrin', name: 'Merrin' },
          { image: 'daka', name: 'Daka' },
          { image: 'asajj', name: 'Asajj' },
          { image: 'zombie', name: 'Zombie' }
        ]
      },
    ]
  },
  {
    title: 'Sector 2 - Progress',
    progress: [
      { name: 'Potency Down', complete: true },
      { name: 'Rebel Fighters', complete: true },
      { name: 'Imperial Troopers', current: 30, target: 35 },
      { name: 'Nightsisters', current: 6, target: 10 },
    ]
  },




  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Jedi Cal', skip: true },
      { name: 'Master & Padawn', complete: true },  
      { name: 'Captain Rex', complete: true  },
      { name: 'Challenge Path', complete: true },    
      { name: 'Gungans', current: 12, target: 15 },
      { name: 'Dark Gideon', current: 18, target: 35 },
    ]
  }
];

export {
  day7
}