import React from 'react';
import classNames from 'classnames';

import Toon from '../toon';
import './_style.scss';

const Counter = props => {
  return (
    <div className={classNames('sw-counter', props.noDivider && 'sw-counter--no-divider')}>
      {props.name &&
        <div className="sw-counter__header">
          <div className="sw-counter__name">{props.name}</div>
        </div>
      }
      <div className="sw-counter__toons">
        {props.banners &&<div className="sw-counter__banners">
          <span className="badge badge-secondary rounded-0">
              <span className="sw-counter__banners__digit">{props.banners}</span> banners
            </span>
          </div>
        }
        <div className="sw-counter__teams">
          <div className="sw-counter__offense">
            {props.toons && props.toons.map((toon, i) =>
              <div key={i} className={classNames(`sw-counter__toon sw-counter__toon--${i + 1}`, props.performance && `sw-counter__toon--performance sw-counter__toon--performance--${i + 1}`)}>
                <Toon image={toon} />
              </div>
            )}
          </div>
          <div className="sw-counter__vs">vs</div>
          <div className="sw-counter__defense">
            {props.defense && props.defense.map((def, i) =>
              <div key={i} className={classNames(`sw-counter__toon sw-counter__toon--${i + 1}`, props.performance && `sw-counter__toon--performance sw-counter__toon--performance--${i + 1}`)}>
                <Toon image={def} />
              </div>
            )}
          </div>
        </div>
      </div>
      {(props.id || props.url) && <div className="sw-counter__play">
        <a
          href={props.id ? `https://youtu.be/${props.id}` : props.url} target="_blank"
          className="btn btn-warning" rel="noopener noreferrer"
        >
          <div className="d-flex align-items-center justify-content-center">
            <span className="icon-youtube"></span>
            <span className="ml-2">Video</span>
          </div>
        </a>
      </div>
      }
      {(props.home || props.home === 0) &&
        <div className="sw-counter__performance">
          <div className="sw-counter__score">Score</div>
          <div className="sw-counter__home">{props.home}</div>
          <div className="sw-counter__spacer">-</div>
          <div className="sw-counter__away">{props.away}</div>
        </div>
      }
    </div>
  );
}

export default Counter;