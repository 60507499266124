import React, { useState, useEffect } from 'react';

import Page from '../../components/page';
import Video from '../../components/video';
import Counter from '../../components/counter';
import SwTypeAhead from '../../components/typeahead';
import Toon from '../../components/toon';
import { list } from './data/counters';
import { videos, recent } from './data/videos';
import CountersMenu from './menu';

const FiveFive = (props) => {

  const [leaderName, selectName] = useState(null);
  const [leaderVideos, selectVideos] = useState([]);

  const selectLeader = (selected) => {
    const getName = selected[0].value;    
    props.history.push(`/counters/5v5/${getName ? `${getName}/` : ''}`);
  }
  
  const loadVideos = (selected) => {
    const getName = list.find(l => l.value === selected);
    const filterVideos = getName && videos.filter(c => c.counter === selected);
    selectName(getName ? getName.name : null);
    document.title = getName ? `${getName.name} - 5v5 Counters - SWGOH-4 Life` : '5v5 Counters - SWGOH-4 Life';
    selectVideos(getName ? filterVideos.sort((a, b) => parseFloat(b.banners) - parseFloat(a.banners)): null);
  }

  const renderOption = (option) => (
    <div>
      <Toon name={`${option.name} (${videos.filter(v => v.counter === option.value).length})`} image={option.value} listItem />
    </div>
  );

  useEffect(() => {
    loadVideos(props.match.params.id);
  }, [props.match.params.id]);

  const intro = <div className="row">
    <div className="col-12">
      <div className="mb-2">
        <strong>Select the leader you want to counter</strong>
      </div>
      <SwTypeAhead 
        onChange={selectLeader} placeholder="Search or select" emptyLabel="No counters found"
        options={list} labelKey="name" id="selectLeader" renderOption={renderOption}
      />
    </div>
  </div>;

  return (
    <Page menu={<CountersMenu />} title={leaderName ? `5v5 ${leaderName} Counters (${leaderVideos.length})` : `5v5 Counters (${videos.length})`} intro={intro}>
      {!leaderName && <h2 className="mb-5 mb-md-6 sw-heading">Most Recent 5v5 Video Guides</h2>}
      <div className="sw-feats">
        <div className="row justify-content-xxl-between">
          {leaderName && leaderVideos.length > 0 ? leaderVideos.map((video, i) =>
            <Counter 
              toons={video.squad} id={video.id} key={i}
              url={video.url} banners={video.banners} defense={video.defense}
            />)
          :
          recent.map((recent, i) => 
              <div className="col-12 col-sm-6 col-xl-4 pb-5" key={i}>
                <Video 
                  id={recent.id} name={recent.title} desc={recent.desc} 
                  url={recent.url} image={recent.image}
                />     
              </div>
            )
          }
        </div>
      </div>
    </Page>
  );
}

export default FiveFive;
