const day10 = [
  {
    title: `Sector 2 - Datacron Farming`,
    teams: [
      {
        id: 1001,
        name: '4x',
        videoThumb: 'CgX6JpCIIdQ?t=476&si=qlWFMFVuTNm32cpa',
        global: [
          { name: 'Dark Gideon' },
        ],
        feats: [
          { name: 'Imperial Troopers', qty: 5 },
        ],
        toons: [
          { image: 'dt-gideon', name: 'Dark Gideon' },
          { image: 'scout-trooper', name: 'Scout Trooper' },
          { image: 'stormtrooper', name: 'Stormtrooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'gideon', name: 'Gideon' }
        ]
      },
    ]
  },
  {
    title: 'Sector 2 - Progress',
    progress: [
      { name: 'Potency Down', complete: true },
      { name: 'Rebel Fighters', complete: true },
      { name: 'Imperial Troopers', complete: true },
      { name: 'Nightsisters', complete: true },
    ]
  },


  {
    title: `Sector 3 - Datacron Farming`,
    teams: [
      {
        id: 1002,
        name: '2x',
        videoThumb: 'CgX6JpCIIdQ?t=156&si=wE4vg9l7_4f2kG46',
        feats: [
          { name: 'Han', qty: 5 },
        ],
        global: [
          { name: 'Master & Padawan' },
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'capt-han', name: 'Captain Han', main: true },
          { image: 'wat', name: 'Wat' },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
        ]
      },
      {
        id: 1003,
        name: '4x',
        videoThumb: 'rlb_tlMtOlE?t=590&si=lwMRrBD7LWjixFa9',
        feats: [
          { name: 'Separatists', qty: 1 },
        ],
        toons: [
          { image: 'gg', name: 'Grievous' },
          { image: 'b1', name: 'B1' },
          { image: 'b2', name: 'B2' },
          { image: 'magna', name: 'Magna' },
          { image: 'stap', name: 'STAP' }
        ]
      },
      {
        id: 1004,
        name: '2x',
        videoThumb: 'rlb_tlMtOlE?t=413&si=ZZ3IUre0thbbSpIV',
        feats: [
          { name: 'Adapt or Die', qty: 5 },
        ],
        toons: [
          { image: 'iden', name: 'Iden', main: true },
          { image: 'starck', name: 'Starck' },
          { image: 'range', name: 'Range Trooper' },
          { image: 'death-trooper', name: 'Death Trooper' },
          { image: 'shoretrooper', name: 'Shore Trooper' }
        ]
      },
    ]
  },
  {
    title: 'Sector 3 - Progress',
    progress: [
      { name: 'Accuracy Up', complete: true },
      { name: 'Han', current: 35, target: 35 },
      { name: 'Separatists', current: 10, target: 10 },
      { name: 'Adapt or Die', current: 40, target: 50 },
    ]
  },




  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Jedi Cal', skip: true },
      { name: 'Master & Padawn', complete: true },
      { name: 'Captain Rex', complete: true },
      { name: 'Challenge Path', complete: true },
      { name: 'Gungans', complete: true },
      { name: 'Dark Gideon', current: 20, target: 35 },
    ]
  }
];

export {
  day10
}