const sector5 = [
  {
    title: 'Evasion Down',
    text: 'Attempt to inflict Evasion Down 50 times',
    keycards: 15,
    teams: [
      {
        videoThumb: '3Mn7qzQfLbo?t=59&si=ZyfW1xAJWR3bkuIA',
        feats: [
          { name: 'Phoenix', qty: 5 },
          { name: 'Evasion Down', qty: 10 },
        ],
        global: [
          { name: 'Captain Rex' }
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'chewie', name: 'Chewie' },
          { image: 'han', name: 'Han' },
          { image: 'threepio', name: 'Threepio', main: true  },
          { image: 'captain-rex', name: 'Captain Rex' }
        ]
      },
      {
        videoThumb: '3Mn7qzQfLbo?t=97&si=YL_StWg-WkJd3whf',
        feats: [
          { name: 'Bounty Hunters', qty: 5 },
          { name: 'Evasion Down', qty: 5 },
        ],
        toons: [
          { image: 'bossk', name: 'Bossk' },
          { image: 'zam', name: 'Zam', main: true  },
          { image: 'greef', name: 'Greef'},
          { image: 'mando', name: 'Mandalorian' },
          { image: 'bam', name: 'Beskar Mando' }
        ]
      },
    ]
  },
  {
    title: 'Phoenix',
    text: 'Defeat 35 enemies with Phoenix',
    keycards: 15,
    teams: [
      {
        videoThumb: '3Mn7qzQfLbo?t=194&si=NNeYF3cPXbXbuQlS',
        feats: [
          { name: 'Phoenix', qty: 5 },
          { name: 'Qui-Gons', qty: 1 },
        ],
        global: [
          { name: 'Captain Rex' },
          { name: 'Master & Padawn' }
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'qgj', name: 'QGJ' },
          { image: 'master-qgj', name: 'Master QGJ' },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
          { image: 'captain-rex', name: 'Captain Rex', main: true }
        ]
      },
      {
        videoThumb: '3Mn7qzQfLbo?t=59&si=ZyfW1xAJWR3bkuIA',
        feats: [
          { name: 'Phoenix', qty: 5 },
          { name: 'Evasion Down', qty: 10 },
        ],
        global: [
          { name: 'Captain Rex' }
        ],
        toons: [
          { image: 'cls', name: 'Commander Luke' },
          { image: 'chewie', name: 'Chewie' },
          { image: 'han', name: 'Han' },
          { image: 'threepio', name: 'Threepio' },
          { image: 'captain-rex', name: 'Captain Rex', main: true }
        ]
      },
      {
        videoThumb: '3Mn7qzQfLbo?t=261&si=d5TRjlztQQKNxI5f',
        feats: [
          { name: 'Phoenix', qty: 5 },
        ],
        global: [
          { name: 'Captain Rex' }
        ],
        toons: [
          { image: 'hera', name: 'Hera' },
          { image: 'chopper', name: 'Chopper' },
          { image: 'kanan', name: 'Kanan' },
          { image: 'sabine', name: 'Sabine' },
          { image: 'captain-rex', name: 'Captain Rex' }
        ]
      },
    ]
  },
  {
    title: 'Qui-Gons',
    text: 'Win 10 battles with both Qui-Gons surviving',
    keycards: 15,
    teams: [
      {
        videoThumb: '3Mn7qzQfLbo?t=194&si=9CSa8-zyQIqaLZDg',
        feats: [
          { name: 'Phoenix', qty: 5 },
          { name: 'Qui-Gons', qty: 1 },
        ],
        global: [
          { name: 'Captain Rex' },
          { name: 'Master & Padawn' }
        ],
        toons: [
          { image: 'jml', name: 'Master Luke' },
          { image: 'qgj', name: 'QGJ', main: true  },
          { image: 'master-qgj', name: 'Master QGJ', main: true  },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
          { image: 'captain-rex', name: 'Captain Rex'}
        ]
      },
      {
        videoThumb: '3Mn7qzQfLbo?t=290&si=mdClzSRZUOs0_XzU',
        feats: [
          { name: 'Qui-Gons', qty: 1 },
        ],
        global: [
          { name: 'Master & Padawn' },
          { name: 'Jedi Cal' }
        ],
        toons: [
          { image: 'jkr', name: 'Jedi Revan' },
          { image: 'qgj', name: 'QGJ', main: true  },
          { image: 'master-qgj', name: 'Master QGJ', main: true  },
          { image: 'padawan-obi', name: 'Padawan Obi-Wan' },
          { image: 'jedi-cal', name: 'Jedi Cal'}
        ]
      },
      {
        videoThumb: '',
        feats: [
          { name: 'Qui-Gons', qty: 1 },
        ],
        toons: [
          { image: 'padme', name: 'Padme' },
          { image: 'qgj', name: 'QGJ', main: true  },
          { image: 'master-qgj', name: 'Master QGJ', main: true  },
          { image: 'gk', name: 'General Kenobi' },
          { image: 'jka', name: 'Jedi Ani'}
        ]
      },
    ]
  },
  
  {
    title: 'Bounty Hunters',
    text: 'Defeat 35 enemies with Bounty Hunters',
    keycards: 15,
    teams: [
      {
        videoThumb: '3Mn7qzQfLbo?t=97&si=pVJ4kt9DbZFOe7tK',
        feats: [
          { name: 'Bounty Hunters', qty: 5 },
          { name: 'Evasion Down', qty: 5 },
        ],
        toons: [
          { image: 'bossk', name: 'Bossk' },
          { image: 'zam', name: 'Zam', main: true  },
          { image: 'greef', name: 'Greef'},
          { image: 'mando', name: 'Mandalorian' },
          { image: 'bam', name: 'Beskar Mando' }
        ]
      },
      {
        videoThumb: '3Mn7qzQfLbo?t=154&si=ckR_qRdsa0XOjH8I',
        feats: [
          { name: 'Bounty Hunters', qty: 5 },
        ],
        toons: [
          { image: 'jabba', name: 'Jabba' },
          { image: 'boushh-leia', name: 'Boushh Leia'  },
          { image: 'krrsantan', name: 'Krrsantan'},
          { image: 'boba', name: 'Boba' },
          { image: 'cad', name: 'Cad Bane' }
        ]
      },
    ]
  },
  {
    title: 'Mini Boss',
    text: 'Battle vs Darth Bane with General Veers and with Light Side only',
    keycards: 9,
    teams: [
      {
        name: 'Veers',
        videoThumb: '4KDS7QQnH5A?t=81&si=fqDdZ1lqPuOA_ZYV',
        toons: [
          { image: 'veers', name: 'Veers' },
          { image: 'gideon', name: 'Gideon' },
          { image: 'range', name: 'Range' },
          { image: 'piett', name: 'Piett' },
          { image: 'malak', name: 'Malak' }
        ]
      },
      {
        name: 'Light Side',
        videoThumb: '4KDS7QQnH5A?t=205&si=NazoM78Zb2fTDlbd',
        toons: [
          { image: 'gas', name: 'General Skywalker' },
          { image: 'arc', name: 'ARC Trooper' },
          { image: 'rex', name: 'Rex' },
          { image: 'echo', name: 'Echo' },
          { image: 'fives', name: 'Fives' }
        ]
      }
    ]
  },
  {
    title: 'Final Boss',
    text: 'Battle vs Saw with Darth Bane surviving and without Jedi, Sith or UFUs',
    keycards: 12,
    teams: [
      {
        name: 'Bane',
        videoThumb: '4KDS7QQnH5A?t=431&si=btSyKUnwwJGQOFUI',
        toons: [
          { image: 'bane', name: 'Bane'},
          { image: 'malak', name: 'Malak'},
        ]
      },
      {
        name: 'Hokey Religions',
        videoThumb: '4KDS7QQnH5A?t=365&si=NJSsCGqU-XrG2agj',
        feats: [
          { name: 'Phoenix', qty: 1 },
          { name: 'Evasion Down', qty: 5 },
        ],
        global: [
          { name: 'Captain Rex' }
        ],
        toons: [
          { image: 'dash', name: 'Dash' },
          { image: 'han', name: 'Han' },
          { image: 'chewie', name: 'Chewie' },
          { image: 'threepio', name: 'Threepio' },
          { image: 'captain-rex', name: 'Captain Rex'}
        ]
      }
    ]
  }
];

export {
  sector5
}