export const sectorTwo = {
  title: 'Sector 2',
  planets: [
    {
      title: 'Bracca',
      path: 'ls',
      loadouts: [
        {
          title: 'Cere',
          video: 'CmeL_UsIZzM?t=913&si=9ji-vyL0j51uHdOV',
          toons: [
            { image: 'cere', main: true },
            { image: 'jedi-cal', main: true },
          ],
          alternatives: [
            {
              videoThumb: 'CmeL_UsIZzM?t=445&si=qgdI0WEOOrh1JeMC',
              toons: [
                { image: 'cere', main: true },
                { image: 'cal', main: true }
              ]
            }
          ]
        },
        {
          title: 'Jedi',
          video: 'dNXwh41TdDI?t=93&si=9WMM4GbEbh7pgbxk',
          toons: [
            { image: 'jml' },
            { image: 'jkl' },
            { image: 'gas' },
            { image: 'gmy' },
            { image: 'shaak-ti' }
          ]
        },
        {
          title: 'LS',
          video: '-9s4bBqcMw0?t=773&si=jCZpLXY5n9YHvERi',
          toons: [
            { image: 'jmk', name: 'AUTO' },
            { image: 'padme' },
            { image: 'jka' },
            { image: 'cat' },
            { image: 'gk' }
          ],
          alternatives: [
            {
              videoThumb: 's34PRPeJKsQ?t=752&si=kYlPXOgWxxFb92h9',
              toons: [
                { image: 'rjt' },
                { image: 'r2' },
                { image: '3po' },
                { image: 'bb' },
                { image: 'scavenger' }
              ]
            }
          ]
        },
        {
          title: 'LS',
          video: '-9s4bBqcMw0?t=721&si=IebMcgzBYOA1uIfE',
          toons: [
            { image: 'gl-leia', name: 'AUTO' },
            { image: 'r2' },
            { image: 'cls' },
            { image: 'drogan' },
            { image: 'captain-rex' }
          ],
          alternatives: [
            {
              videoThumb: '',
              toons: [
                { image: 'rey' },
                { image: 'ben-solo' },
                { image: 'cls' },
                { image: 'cal' },
                { image: 'fulcrum' }
              ]
            }
          ]
        },
        {
          title: 'Fleet',
          video: '-9s4bBqcMw0?t=832&si=bxYsagrwX3_52_Sd',
          toons: [
            { image: 'profundity', name: 'AUTO' },
            { image: 'milf' },
            { image: 'rebel-ywing' },
            { image: 'outrider' },
            { image: 'phantom' }
          ]
        }
      ]
    },
    {
      title: 'Felucia',
      path: 'mix',
      loadouts: [
        {
          title: `Young Lando`,
          video: '-9s4bBqcMw0?t=237&si=nEOAoy6yRqHbMvDk',
          toons: [
            { image: 'slkr', name: 'AUTO' },
            { image: 'young-lando', main: true },
            { image: 'hux' },
            { image: 'kru' },
            { image: 'sith-trooper' }
          ],
          alternatives: [
            {
              videoThumb: 'D691KlENDrQ?t=659&si=7pfiipa7b-TAZ_Nt',
              toons: [
                { image: 'jmk' },
                { image: 'young-lando', main: true },
                { image: 'gk' },
                { image: 'cat' },
                { image: 'wat' }
              ]
            },
            {
              videoThumb: '1CFcPqwoYOE?t=287',
              toons: [
                { image: 'see' },
                { image: 'young-lando', main: true },
                { image: 'nihilus' },
                { image: 'malak' },
                { image: 'malgus' }
              ],
            }
          ]
        },
        {
          title: 'Hondo',
          video: '-9s4bBqcMw0?t=413&si=AYYjHAnxcxSBRiN7',
          toons: [
            { image: 'rey', name: 'AUTO' },
            { image: 'hondo', main: true },
            { image: 'ben-solo' },
            { image: 'cal' },
            { image: 'rjt' }
          ],
          alternatives: [
            {
              videoThumb: '1CFcPqwoYOE?t=28',
              toons: [
                { image: 'lv' },
                { image: 'hondo', main: true },
                { image: 'piett' },
                { image: 'rg' },
                { image: 'thrawn' }
              ]
            },
          ]
        },
        {
          title: 'Jabba',
          video: '-9s4bBqcMw0?t=557&si=fCHG9HyHI3SMXkoc',
          toons: [
            { image: 'jabba', name: 'AUTO', main: true },
            { image: 'boba' },
            { image: 'greedo' },
            { image: 'zam' },
            { image: 'cad' }
          ]
        },
        {
          title: 'Mix',
          video: '-9s4bBqcMw0?t=513&si=9ucwbllwCYFlCffB',
          toons: [
            { image: 'palpatine', name: 'AUTO' },
            { image: 'see' },
            { image: 'mara' },
            { image: 'malak' },
            { image: 'malgus' }
          ],
          alternatives: [
            {
              videoThumb: '4qAsN4u5Ejo?t=283&si=8uRuJyPuxop-iQ9f',
              toons: [
                { image: 'palpatine' },
                { image: 'thrawn' },
                { image: 'tarkin' },
                { image: 'piett' },
                { image: 'vader' }
              ]
            },
          ]
        },
        {
          title: 'Fleet',
          video: '-9s4bBqcMw0?t=604&si=6FOPvynDWo0sghsE',
          toons: [
            { image: 'executor', name: 'AUTO' },
            { image: 'houndstooth' },
            { image: 'rc' },
            { image: 'xanadu-blood' },
            { image: 'ig-2000' },
          ],
          alternatives: [
            {
              videoThumb: '4qAsN4u5Ejo?t=537&si=kntLpz0IdfCJPfPB',
              toons: [
                { image: 'malevolence' },
                { image: 'hyena' },
                { image: 'vulture' },
                { image: 'sf-starfighter' },
                { image: 'spy-starfighter' }
              ]
            },
            {
              videoThumb: '4qAsN4u5Ejo?t=464&si=aOiamBTM-V-xLwx0',
              toons: [
                { image: 'executor' },
                { image: 'houndstooth' },
                { image: 'ig-2000' },
                { image: 'xanadu-blood' },
                { image: 'slave' },
              ]
            }
          ]
        }
      ]
    },
    {
      title: 'Geonosis',
      path: 'ds',
      loadouts: [
        {
          title: `Reek`,
          video: '-9s4bBqcMw0?t=82&si=p7VKqlIg5Wy-uNfx',
          toons: [
            { image: 'trench', name: 'AUTO' },
            { image: 'wat' },
            { image: 'nute' },
            { image: 'jango' },
            { image: 'dooku' },
          ],
          alternatives: [
            {
              videoThumb: 'GWRtcATtOho?t=536&si=if6YNDd0_XNIyCI-',
              toons: [
                { image: 'gi' },
                { image: 'seventh-sister' },
                { image: 'ninth-sister' },
                { image: 'eighth-brother' },
                { image: 'fifth-brother' }
              ]
            },
            {
              videoThumb: 'GWRtcATtOho?t=662&si=qMUQB87UARNgmejp',
              toons: [
                { image: 'see' },
                { image: 'malgus' },
                { image: 'dooku' },
                { image: 'sid' },
                { image: 'marauder' }
              ]
            },
            {
              videoThumb: '53171nJlggY?t=2991',
              toons: [
                { image: 'lv' },
                { image: 'vader' },
                { image: 'wampa' },
                { image: 'piett' },
                { image: 'thrawn' }
              ]
            }
          ]
        },
        {
          title: 'Acklay',
          video: '-9s4bBqcMw0?si=b4duOf-UsjN2jE0s&t=146',
          toons: [
            { image: 'reva', name: 'AUTO' },
            { image: 'gi' },
            { image: 'seventh-sister' },
            { image: 'eighth-brother' },
            { image: 'fifth-brother' }
          ],
          alternatives: [
            {
              videoThumb: '3EUB7pnqIIQ?t=104',
              toons: [
                { image: 'gi' },
                { image: 'seventh-sister' },
                { image: 'ninth-sister' },
                { image: 'eighth-brother' },
                { image: 'fifth-brother' }
              ]
            },
            {
              videoThumb: '3EUB7pnqIIQ?t=39&si=o5JVcvgjMbBsXgFF',
              toons: [
                { image: 'slkr' },
                { image: 'phasma' },
                { image: 'hux' },
                { image: 'sith-trooper' },
                { image: 'kru' }
              ]
            },
            {
              videoThumb: 'GWRtcATtOho?t=414',
              toons: [
                { image: 'lv' },
                { image: 'vader' },
                { image: 'kru' },
                { image: 'piett' },
                { image: 'thrawn' }
              ]
            }
          ]
        },
        {
          title: 'Nexu',
          video: '-9s4bBqcMw0?t=162&si=325XYVx7ps8RgDxy',
          toons: [
            { image: 'lv', name: 'AUTO' },
            { image: 'vader' },
            { image: 'thrawn' },
            { image: 'piett' },
            { image: 'rg' }
          ],
          alternatives: [
            {
              videoThumb: 'GWRtcATtOho?t=295&si=174gGYDyR9QPX1r5',
              toons: [
                { image: 'slkr' },
                { image: 'gg' },
                { image: 'nute' },
                { image: 'jango' },
                { image: 'droideka' }
              ]
            }
          ]
        },
        {
          title: 'Geos',
          video: 'w754OsqH5vs?t=40&si=WBiXgwQf5Cm0wurB',
          toons: [
            { image: 'gba' },
            { image: 'sun-fac' },
            { image: 'poggle' },
            { image: 'geo-soldier' },
            { image: 'geo-spy' }
          ]
        },
        {
          title: 'Fleet',
          video: 'v5AJ8YN5E1o?t=231&si=1RsIpEq_P3YZYnaZ',
          toons: [
            { image: 'leviathan' },
            { image: 'fury' },
            { image: 'sith-fighter' },
            { image: 'b-28' },
            { image: 'mark-vi-interceptor' },
          ]
        }
      ]
    }
  ]
}