const day6 = [  
  {
    title: `Sector 3 - Battles`,
    teams: [
      {
        id: 601,
        name: '4x',
        videoThumb: 'HAzLlLIAVUM?t=51&si=oSiPct3UBoXvfa1P',
        feats: [
          { name: 'Separatists', qty: 1 },
        ],
        toons: [
          { image: 'gg', name: 'Grievous' },
          { image: 'b1', name: 'B1' },
          { image: 'b2', name: 'B2' },
          { image: 'magna', name: 'Magna' },
          { image: 'stap', name: 'STAP' }
        ]
      },
      {
        id: 602,
        name: '4x',
        videoThumb: 'HAzLlLIAVUM?t=304&si=4xcKMbTGA1Z_fmYI',
        feats: [
          { name: 'Accuracy Up', qty: 5 },
        ],
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [
          { image: 'mothma', name: 'Mothma' },
          { image: 'pathfinder', name: 'Scarif Pathfinder', main: true },
          { image: 'biggs', name: 'Biggs' },
          { image: 'pao', name: 'Pao' },
          { image: 'captain-rex', name: 'Captain Rex' },
        ]
      },
      {
        id: 603,
        name: '2x',
        videoThumb: 'HAzLlLIAVUM?t=88&si=bFHQiowoEsT-1VQA',
        feats: [
          { name: 'Separatists', qty: 1 },
        ],
        toons: [
          { image: 'gba', name: 'Brood Alpha' },
          { image: 'poggle', name: 'Poggle' },
          { image: 'sun-fac', name: 'Sun Fac' },
          { image: 'geo-spy', name: 'Geo Spy' },
          { image: 'geo-soldier', name: 'Geo Soldier' }
        ]
      },
    ]
  },
  {
    title: `Sector 3 - Final Boss`,
    boss: 'dark-gideon',
    teams: [
      {
        id: 606,
        name: 'Boss Nass',
        videoThumb: 'zBrs1T_iqiw?t=391&si=u0wE8Iwg2hFL2bVv',
        feats: [
          { name: 'Han', qty: 1 },
        ],
        global: [
          { name: 'Captain Rex' },
        ],
        toons: [
          { image: 'dash', name: 'Dash' },
          { image: 'wat', name: 'Wat' },
          { image: 'captain-rex', name: 'Captain Rex' },
          { image: 'boss-nass', name: 'Boss Nass', main: true },
          { image: 'capt-han', name: 'Captain Han' },
        ]
      },
      {
        id: 607,
        name: 'Dark Side',
        videoThumb: 'zBrs1T_iqiw?t=575&si=har82nfsD4WpR6v2',
        feats: [
          { name: 'Separatists', qty: 1 },
        ],
        toons: [
          { image: 'gg', name: 'Grievous' },
          { image: 'b1', name: 'B1' },
          { image: 'b2', name: 'B2' },
          { image: 'magna', name: 'Magna' },
          { image: 'wat', name: 'Wat' }
        ]
      }
    ]
  },
  {
    title: 'Sector 3 - Progress',
    progress: [
      { name: 'Han', skip: true },
      { name: 'Adapt or Die', skip: true },
      { name: 'Separatists', current: 10, target: 10 },
      { name: 'Accuracy Up', current: 50, target: 50 },
    ]
  },



  {
    title: 'Global Feats - Progress',
    progress: [
      { name: 'Jedi Cal', skip: true },
      { name: 'Dark Gideon', skip: true },
      { name: 'Challenge Path', current: 150, target: 250 },      
      { name: 'Captain Rex', current: 15, target: 15 },
      { name: 'Gungans', current: 4, target: 15 },
      { name: 'Master & Padawn', current: 8, target: 15 },
    ]
  }
];

export {
  day6
}