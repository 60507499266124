import React from 'react';
import classNames from 'classnames';
import { set15Bonus } from './data/set15-bonuses';
import { set16Bonus } from './data/set16-bonuses';

const getName = (id, level) => {
  const getType = id && id.split('-')
  let name = `/images/datacrons/${getType[0]}`

  if (level === 9) {
    const getName = getType.slice(1).join('-')
    name = `/images/toons/${getName}`
  }

  return name
}


const DatacronIcon = (props) => {
  const { id, level, important, activeTab, complete, onClick, onToggleTip } = props

  const getLabel = (id) => {
    const getList = activeTab === 0 ? set16Bonus : set15Bonus
    const findItem = getList.find(b => b.id === id)
    return findItem?.label
  }

  const getFull = (id) => {
    const getList = activeTab === 0 ? set16Bonus : set15Bonus
    const findItem = getList.find(b => b.id === id)
    return findItem?.full
  }

  const toggleTip = (e, toggle) => {
    e.preventDefault()
    onToggleTip(getFull(id), toggle)
  }

  return (
    <div className='sw-dc-icon'>
      <div className={classNames('sw-dc-icon__wrapper', complete && 'sw-dc-icon__wrapper--complete', important && 'sw-dc-icon__wrapper--important')} onClick={onClick}>
        <img className={classNames('sw-dc-icon__image', complete && 'sw-dc-icon__image--complete')} alt='' src={`${getName(id, level)}.png`} />
        {complete &&
          <svg width='24' height='24' fill='white' className='sw-dc-icon__check' viewBox='0 0 16 16'>
            <path d='M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0' />
          </svg>
        }
      </div>
      <div className='sw-dc-icon__label'>
        <a href='#tip' className='text-light' onMouseEnter={(e) => toggleTip(e, true)} onMouseLeave={(e) => toggleTip(e, false)}>{getLabel(id)}</a>
      </div>
    </div>
  );
}

export default DatacronIcon;